import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { PhoneInput } from 'src/common/primitives/PhoneInput';

export function PhoneNumberField({ disabled }: { disabled?: boolean }) {
  return (
    <FormFieldController
      name="phone_number"
      rules={{ required: 'Phone is required' }}
    >
      {({ field, fieldState }) => {
        return (
          <DefaultFormFieldLayout
            label="Phone Number *"
            error={fieldState.error?.message?.toString()}
            inputProps={{ id: 'phone', disabled }}
          >
            <PhoneInput {...field} dataTestId="phone" disabled={disabled} />
          </DefaultFormFieldLayout>
        );
      }}
    </FormFieldController>
  );
}
