import { useState } from 'react';

import { SectionHeading } from 'src/common/primitives/Section/SectionHeading';

import { BillingAddressForm } from './BillingAddressForm';
import { CreditCardCaptureForm } from './CreditCardCaptureForm';
import { BillingAddress, TilledPaymentType } from './types';

export type BillingDetails = BillingAddress & {
  billingName: string;
};

export interface PaymentMethodCaptureFormProps {
  type: TilledPaymentType;
  billingName?: string;
  onChange?: (details: BillingDetails) => void;
}

export function PaymentMethodCaptureForm({
  type,
  billingName,
  onChange,
}: PaymentMethodCaptureFormProps) {
  const [billingDetails, setBillingDetails] = useState<BillingDetails>();

  return (
    <div>
      {type === 'card' ? (
        <>
          <SectionHeading heading="Credit Card" className="mt-2" />
          <CreditCardCaptureForm
            billingName={billingName}
            onChangeBillingName={(billingName) => {
              const update = {
                ...billingDetails,
                billingName,
              } as BillingDetails;
              setBillingDetails(update);
              onChange?.(update);
            }}
          />
        </>
      ) : null}
      <SectionHeading heading="Billing Address" className="mt-2" />
      <BillingAddressForm
        onChange={(address) => {
          const update = { ...billingDetails, ...address } as BillingDetails;
          setBillingDetails(update);
          onChange?.(update);
        }}
      />
    </div>
  );
}

// Validates and returns a resolved billing address, little janky but it may
// return a partial billing address which is acceptable by Tilled
export function validateBillingAddress(
  billingAddress?: Partial<BillingAddress>
): BillingAddress | false {
  if (!billingAddress?.zip) {
    return false;
  }

  const resolvedAddress = {} as BillingAddress;
  Object.keys(billingAddress).forEach((key) => {
    const typedKey = key as keyof BillingAddress;
    if (billingAddress[typedKey]) {
      resolvedAddress[typedKey] = billingAddress[typedKey] as string;
    }
  });

  return resolvedAddress as BillingAddress;
}
