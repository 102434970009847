import { Switch as HeadlessSwitch } from '@headlessui/react';

export default function Switch({
  label,
  inputProps,
}: {
  label: string | React.ReactNode;
  inputProps: any;
}) {
  const { value, checked } = inputProps;
  const isChecked = value || checked;
  return (
    <HeadlessSwitch
      className={`${isChecked ? 'bg-primary-700' : 'bg-slate-300'}
          relative inline-flex h-[20px] w-[36px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 disabled:opacity-50`}
      {...inputProps}
      aria-checked={isChecked}
      data-testid={inputProps?.id}
    >
      <span className="sr-only">{label}</span>
      <span
        aria-hidden="true"
        className={`${isChecked ? 'translate-x-4' : 'translate-x-0.5'}
            pointer-events-none inline-block h-[16px] w-[16px] -translate-y-0 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </HeadlessSwitch>
  );
}
