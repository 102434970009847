import React from 'react';

import { parseAddress, ParsedAddress } from 'vladdress';

import { formatHereMapsAddress } from './HereMapsAddressLink';
import { removeUnitedStatesFromAddress } from './util/removeUnitedStatesFromAddress';

export function formatAddress(
  address: string,
  containerClassName?: string,
  truncate = true
): React.ReactNode {
  try {
    const addressParts = parseAddress(removeUnitedStatesFromAddress(address));
    return renderAddress(addressParts, containerClassName);
  } catch {
    if (truncate) {
      return formatHereMapsAddress(address);
    } else {
      return address;
    }
  }
}

export function renderAddress(
  addressParts: Partial<ParsedAddress>,
  containerClassName?: string
) {
  return (
    <div className={`flex flex-col ${containerClassName}`}>
      <div>{addressParts.addressLine1}</div>
      {addressParts.addressLine2 ? (
        <div>{addressParts.addressLine2}</div>
      ) : null}
      <div>
        {addressParts.placeName}, {addressParts.stateAbbreviation}
      </div>
      <div>{addressParts.zipCodePlusFour ?? addressParts.zipCode}</div>
    </div>
  );
}
