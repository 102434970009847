import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import { isStartTimeBeforeEndTime } from 'src/common/date/isStartTimeBeforeEndTime';
import { setDateTime } from 'src/common/date/setDateTime';
import {
  useAssignmentsBreaksCreateMutation,
  useAssignmentsBreaksPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { dismissDispatchModal } from 'src/features/dispatch/dispatcher.slice';

import { useDispatchRepository } from '../../common/useDispatchRepository';
import { assignDefaultFormValues } from './assignDefaultFormValues';

export default function AssignmentBreakModal() {
  const dispatch = useDispatch();
  const { refetch } = useDispatchRepository();
  const selectedDate = useAppSelector((state) => state.dispatcher.selectedDate);
  const assignmentId = useAppSelector(
    (state) => state.dispatcher.modals.assignmentId
  );
  const assignmentBreak = useAppSelector(
    (state) => state.dispatcher.modals.assignmentBreak
  );
  const modalToShow = useAppSelector(
    (state) => state.dispatcher.modals.modalToShow
  );

  const showAssignmentBreakModal = modalToShow === 'assignmentBreak';

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [createBreak] = useAssignmentsBreaksCreateMutation();
  const [updateBreak] = useAssignmentsBreaksPartialUpdateMutation();

  const defaultFormValues = assignDefaultFormValues(assignmentBreak);
  const methods = useForm({
    defaultValues: defaultFormValues,
  });

  const closeModal = () => {
    setLoading(false);
    setError(null);
    dispatch(dismissDispatchModal());
    methods.reset();
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    setError(null);

    const startDateTime = setDateTime(selectedDate, data.startTime);
    const endDateTime = setDateTime(selectedDate, data.endTime);

    try {
      if (assignmentBreak?.id) {
        await updateBreak({
          id: assignmentBreak.assignment_id,
          breakId: assignmentBreak.id,
          patchedAssignmentBreak: {
            start: startDateTime,
            end: endDateTime,
            break_notes: data.notes,
          },
        }).unwrap();
      } else {
        if (!assignmentId) {
          throw new Error('Missing assignment ID');
        }

        await createBreak({
          id: assignmentId,
          assignmentBreak: {
            start: startDateTime,
            end: endDateTime,
            break_notes: data.notes,
          },
        }).unwrap();
      }

      refetch();
      closeModal();
    } catch (error) {
      const message = formatServerError(error);
      setError(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showAssignmentBreakModal) {
      methods.reset(defaultFormValues);
    }
    // TODO: Not sure why defaultValues don't get set properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAssignmentBreakModal]);

  return (
    <Modal
      open={showAssignmentBreakModal}
      setOpen={closeModal}
      contentClassnames="max-w-2xl md:min-w-[400px]"
    >
      <h1 className="mb-4 text-2xl">
        {assignmentBreak?.id ? 'Edit' : 'Add'} Assignment Break
      </h1>
      {error && (
        <div className="mb-4 max-w-[550px]">
          <FormErrorMessage>{error}</FormErrorMessage>
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="py-2">
            <FormFieldController
              name="startTime"
              rules={{
                validate: (value: any) => {
                  if (!value) {
                    return 'Start Time is required';
                  }

                  const { endTime } = methods.getValues();
                  return isStartTimeBeforeEndTime(value, endTime);
                },
              }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Start Time *"
                    error={fieldState.error?.message?.toString()}
                    inputProps={{
                      id: `startTime`,
                      ...field,
                      onChange: field.onChange,
                    }}
                    {...field}
                    type="time"
                  />
                );
              }}
            </FormFieldController>
          </div>
          <div className="py-2">
            <FormFieldController
              name="endTime"
              rules={{ required: 'End Time is required' }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="End Time *"
                    error={fieldState.error?.message?.toString()}
                    inputProps={{
                      id: `endTime`,
                      ...field,
                      onChange: field.onChange,
                    }}
                    {...field}
                    type="time"
                  />
                );
              }}
            </FormFieldController>
          </div>
          <div className="py-2">
            <FormFieldController name="notes">
              {({ field }) => {
                return (
                  <FormField
                    label="Notes"
                    inputProps={{
                      id: `notes`,
                      ...field,
                      onChange: field.onChange,
                    }}
                    {...field}
                    type="textarea"
                  />
                );
              }}
            </FormFieldController>
          </div>
          <div className="grid grid-cols-2 gap-4 pt-6">
            <Button disabled={loading} onClick={() => closeModal()}>
              Cancel
            </Button>
            <Button disabled={loading} variant="primary" type="submit">
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}
