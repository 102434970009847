import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';

export function getTripPickupAddress(
  trip:
    | DispatchTripRead
    | RunBambiRunTripRead
    | RunBambiRunReassignedTripRead
    | TripRead
): string {
  if ('pickup_address' in trip) {
    return trip.pickup_address;
  }

  if (!('pickup' in trip)) {
    throw new Error('Got an unexpected trip type');
  }

  return trip.pickup.address;
}
