import { useEffect, useMemo } from 'react';

import { useAppSelector } from 'src/app/store';
import { DispatchTripRead } from 'src/common/external/bambi-api/bambiApi';
import { useClientSideSearch } from 'src/common/util/useClientSideSearch';

export function useSearchedUnassignedTripsFromDispatchResponse(): DispatchTripRead[] {
  const unassignedTripsSearchTerm = useAppSelector(
    (state) => state.dispatcher.unassignedTripsSearchTerm
  );
  const unassignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.unassigned_trips
  );

  const initialData = useMemo(() => unassignedTrips || [], [unassignedTrips]);

  const { data: searchedUnassignedTrips, handleSearchTermChange } =
    useClientSideSearch<DispatchTripRead>(initialData);

  useEffect(() => {
    handleSearchTermChange(unassignedTripsSearchTerm);
  }, [unassignedTripsSearchTerm, handleSearchTermChange]);

  return searchedUnassignedTrips;
}
