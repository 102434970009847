import { useEffect, useState } from 'react';

import { debounce, uniqBy } from 'lodash-es';

import { useAppSelector } from 'src/app/store';
import {
  usePassengersRecentPayersListQuery,
  usePayersListQuery,
} from 'src/common/external/bambi-api/bambiApi';

export function usePayers({
  selectedPassengerId,
  refetchOnMountOrArgChange,
}: {
  selectedPassengerId?: string;
  refetchOnMountOrArgChange?: boolean;
} = {}) {
  const { selectedTrip } = useAppSelector((state) => state.addATrip);
  const { selectedTripSubscription } = useAppSelector(
    (state) => state.tripSubscription
  );

  const [passengerSearchTerm, setPassengerSearchTerm] = useState('');

  const [payerOptions, setPayerOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const { data: payers, isLoading: isPayersLoading } = usePayersListQuery(
    { search: passengerSearchTerm, isActive: true },
    { refetchOnMountOrArgChange }
  );

  const { data: recentPayers, isLoading: isRecentPayersLoading } =
    usePassengersRecentPayersListQuery(
      { id: selectedPassengerId || '' },
      { skip: !selectedPassengerId }
    );

  const handleQueryChange = (query: string) => {
    setPassengerSearchTerm(query);
  };

  const debounceSearch = debounce(handleQueryChange, 300);

  useEffect(() => {
    const allOptions =
      payers?.results.map((payer) => ({
        label: payer.payer_name
          ? payer.is_active
            ? payer.payer_name
            : `${payer.payer_name} (Inactive)`
          : '',
        value: payer.id,
        disabled: !payer.is_active,
      })) || [];

    // the request only returns top 100 results, so we need to add
    // the selected payer to the list incase its not in the top 100
    // any duplicate payers will be removed by uniqBy
    if (selectedTrip?.payer) {
      allOptions.push({
        label: `${selectedTrip.payer.payer_name}`,
        value: selectedTrip.payer.id,
        disabled: !selectedTrip.payer.is_active,
      });
    }

    // same as above, but for the selected trip subscription payer
    if (selectedTripSubscription?.payer) {
      allOptions.push({
        label: `${selectedTripSubscription.payer.payer_name}`,
        value: selectedTripSubscription.payer.id,
        disabled: !selectedTripSubscription.payer.is_active,
      });
    }

    const recentOptions =
      recentPayers?.results
        .filter((payer) => payer.payer_organization.is_active === true)
        .map((payer) => ({
          label: `${payer.payer_organization.payer_name} (Recent)`,
          value: payer.payer_organization.id,
        })) || [];

    setPayerOptions(uniqBy([...recentOptions, ...allOptions], 'value'));
  }, [
    payers,
    recentPayers,
    selectedTrip?.payer,
    selectedTripSubscription?.payer,
  ]);

  return {
    payers,
    recentPayers,
    payerOptions,
    debounceSearch,
    setPassengerSearchTerm,
    isLoading: isPayersLoading || isRecentPayersLoading,
  };
}
