import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import { formatAddress } from 'src/common/formatAddress';
import { HereMapsAddressLink } from 'src/common/HereMapsAddressLink';
import { removeUnitedStatesFromAddress } from 'src/common/util/removeUnitedStatesFromAddress';
import { serviceTypeOptionsMap } from 'src/features/add-trip/ServiceDetails/serviceTypeOptions';
import { transportTypeOptionsMap } from 'src/features/add-trip/ServiceDetails/transportTypeOptions';
import { TripStatusBadge } from 'src/features/trip/management/TripStatusBadge';

import { formatInvoiceDate } from './utils';

interface TripDisplayCellProps {
  trip: TripRead;
}

export function TripDisplayCell({ trip }: TripDisplayCellProps) {
  return (
    <div className="grid grid-cols-4 gap-y-2">
      <div>
        <div className="text-xs text-gray-500">Passenger</div>
        <div
          className="whitespace-normal text-gray-800"
          data-testid="passenger"
        >
          {trip.passenger.full_name ?? 'Unknown Passenger'}
        </div>
      </div>
      <div className="pr-2">
        <div className="text-xs text-gray-500">Scheduled Date of Trip</div>
        <div className="whitespace-normal text-gray-800">
          {formatInvoiceDate(trip.scheduled_pickup_at)}
        </div>
      </div>
      <div>
        <div className="text-xs text-gray-500">From</div>
        <HereMapsAddressLink
          className="print:whitespace-normal print:text-gray-800"
          addresses={[
            {
              latitude: trip.pickup.latitude,
              longitude: trip.pickup.longitude,
            },
            {
              latitude: trip.dropoff.latitude,
              longitude: trip.dropoff.longitude,
            },
          ]}
          data-testid="pickup_address"
          data-raw-address={removeUnitedStatesFromAddress(trip.pickup.address)}
        >
          {formatAddress(trip.pickup.address, '', false)}
        </HereMapsAddressLink>
      </div>
      <div>
        <div className="text-xs text-gray-500">To</div>
        <HereMapsAddressLink
          addresses={[
            {
              latitude: trip.pickup.latitude,
              longitude: trip.pickup.longitude,
            },
            {
              latitude: trip.dropoff.latitude,
              longitude: trip.dropoff.longitude,
            },
          ]}
          className="print:whitespace-normal print:text-gray-800"
          data-testid="dropoff_address"
          data-raw-address={removeUnitedStatesFromAddress(trip.dropoff.address)}
        >
          {formatAddress(trip.dropoff.address, '', false)}
        </HereMapsAddressLink>
      </div>
      <div>
        <div className="text-xs text-gray-500">Miles</div>
        <div className="text-gray-800">
          {trip.estimated_distance_miles?.toFixed(2)}
        </div>
      </div>
      <div>
        <div className="text-xs text-gray-500">Code</div>
        <div className="whitespace-normal text-gray-800">
          {trip.confirmation_code}
        </div>
      </div>
      <div>
        <div className="text-xs text-gray-500">Payer</div>
        <div className="whitespace-normal text-gray-800">
          {trip.payer.display_name}
        </div>
      </div>
      {trip.payer_passenger?.external_id ? (
        <div>
          <div className="text-xs text-gray-500">Payer Member ID</div>
          <div
            className="whitespace-normal text-gray-800"
            data-testid="passenger_dob"
          >
            {trip.payer_passenger.external_id}
          </div>
        </div>
      ) : null}
      <div>
        <div className="text-xs text-gray-500">External Trip ID</div>
        <div
          className="whitespace-normal text-gray-800"
          data-testid="external_trip_id"
        >
          {trip.external_trip_id ? trip.external_trip_id : <>&mdash;</>}
        </div>
      </div>
      <div>
        <div className="text-xs text-gray-500">Passenger DOB</div>
        <div
          className="whitespace-normal text-gray-800"
          data-testid="passenger_dob"
        >
          {trip.passenger.dob ? trip.passenger.dob : <>&mdash;</>}
        </div>
      </div>
      <div>
        <div className="text-xs text-gray-500">Space Type</div>
        <div
          className="whitespace-normal text-gray-800"
          data-testid="space_type"
        >
          {transportTypeOptionsMap[trip.space_type]}
        </div>
      </div>
      <div>
        <div className="text-xs text-gray-500">Service Type</div>
        <div
          className="whitespace-normal text-gray-800"
          data-testid="transport_type"
        >
          {serviceTypeOptionsMap[trip.service_type]}
        </div>
      </div>
      <div>
        <div className="text-xs text-gray-500">Status</div>
        <div className="whitespace-normal text-gray-800" data-testid="status">
          <TripStatusBadge status={trip.status} />
        </div>
      </div>
      <div>
        <div className="text-xs text-gray-500">Completion Time</div>
        <div
          className="whitespace-normal text-gray-800"
          data-testid="completion_time"
        >
          {trip.status === 'completed' && trip.completion_time ? (
            formatInvoiceDate(trip.completion_time)
          ) : (
            <>&mdash;</>
          )}
        </div>
      </div>
    </div>
  );
}
