import { useFormContext } from 'react-hook-form';

import {
  BlankEnum,
  NoShowFeeTypeEnum,
} from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import {
  FormFieldController,
  FormFieldControllerRulesProps,
} from 'src/common/FormField/FormFieldController';
import { SelectOption } from 'src/common/primitives/Select';

import { defaultValues } from '../PricingWizardSchemeConfigurationStep';

export function NoShowFeeTypeField({
  fieldPath,
  rules,
  disabled,
}: {
  fieldPath: string;
  rules?: FormFieldControllerRulesProps;
  disabled?: boolean;
}) {
  const stairFeeTypes: NoShowFeeTypeEnum[] = ['Flat', 'Percentage'];
  const blankOption: BlankEnum = '';
  const stairFeeTypeOptions: SelectOption[] = ['None', ...stairFeeTypes].map(
    (type) => ({
      label: type,
      value: type,
    })
  );

  const { clearErrors, setValue } = useFormContext();

  return (
    <FormFieldController name={fieldPath}>
      {({ field }) => {
        const newValue = {
          label: field.value,
          value: field.value === blankOption ? 'None' : field.value,
        };
        return (
          <FormField
            label={'No Show Fee Type' + (rules?.required ? ' *' : '')}
            type="select"
            inputProps={{
              ...field,
              id: fieldPath,
            }}
            onChange={(option) => {
              const newValue =
                option.value === 'None' ? blankOption : option.value;
              field.onChange(newValue);
              clearErrors('no_show_fee_cents');
              setValue('no_show_fee_cents', defaultValues.no_show_fee_cents);
              clearErrors('no_show_fee_percentage');
              setValue(
                'no_show_fee_percentage',
                defaultValues.no_show_fee_percentage
              );
            }}
            options={stairFeeTypeOptions}
            value={newValue}
            inline
            disabled={disabled}
            helperText="Choose the type of no show fee, whether it is a flat fee or a percentage of the trip."
          />
        );
      }}
    </FormFieldController>
  );
}
