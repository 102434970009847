import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { FormFieldSwitch } from 'src/common/FormField/FormFieldSwitch';

export function WheelchairRequiredField() {
  return (
    <FormFieldController name="must_provide_wheelchair">
      {({ field, fieldState }) => {
        return (
          <FormFieldSwitch
            label="Provide Wheelchair?"
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: 'must_provide_wheelchair',
              ...field,
              value: '',
              checked: field.value,
            }}
            helperText={{
              text: 'Does the passenger need the driver to provide a wheelchair?',
              presentation: {
                type: 'tooltip',
              },
            }}
          />
        );
      }}
    </FormFieldController>
  );
}
