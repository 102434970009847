import { InputHTMLAttributes, useEffect } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { states } from 'src/common/data/states';
import { FormField } from 'src/common/FormField';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldContainer } from 'src/common/FormField/FormFieldContainer';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { FormFieldSet } from 'src/common/FormField/FormFieldSet';
import { FormSubmitButton } from 'src/common/FormSubmitButton';
import { Button } from 'src/common/primitives/Button';
import { Select, SelectOption } from 'src/common/primitives/Select';

import { assignDefaultFormValues } from './assignDefaultFormValues';
import { DeleteAction } from './DeleteAction';
import { FormValues } from './FormValues';
import { resolver } from './resolver';

const vehicleTypeOptions = [
  { value: 'wheelchair', label: 'Wheelchair' },
  { value: 'ambulatory', label: 'Ambulatory' },
  { value: 'stretcher', label: 'Stretcher' },
];

interface VehicleFormStep1Props {
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  onDelete: () => void;
  onFormDirtyChange: (isDirty: boolean) => void;
}

export function VehicleFormStep1({
  onSubmit,
  onCancel,
  onDelete,
  onFormDirtyChange,
}: VehicleFormStep1Props) {
  const { selectedVehicle } = useSelector((state: RootState) => state.fleet);

  const methods = useForm({
    resolver,
    defaultValues: assignDefaultFormValues(selectedVehicle),
  });

  useEffect(() => {
    onFormDirtyChange(methods.formState.isDirty);
  }, [methods.formState.isDirty, onFormDirtyChange]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    clearErrors,
  } = methods;
  return (
    // TODO: Refactor to use FormProvider throughout
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((val) => {
          onSubmit(getValues());
        })}
        data-testid="vehicle-form-step-1"
      >
        <div className="flex max-h-[65vh] min-w-[80vw] flex-col gap-4 overflow-y-scroll sm:min-w-full">
          <FormFieldSet
            Fields={
              <>
                <Controller
                  name="category"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <FormField
                        label="Vehicle Type"
                        type="select"
                        placeholder="Select Vehicle Type"
                        inputProps={{
                          id: 'category',
                          ...register('category'),
                        }}
                        onChange={(option) => {
                          onChange(option.value);
                        }}
                        value={
                          value
                            ? vehicleTypeOptions.find((x) => x.value === value)
                            : ''
                        }
                        options={vehicleTypeOptions}
                      />
                    );
                  }}
                />
                <FormField type="gap" inputProps={{}} />
                <Controller
                  name="can_be_scheduled"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <FormField
                        label="Is this vehicle active?"
                        error={fieldState.error?.message?.toString()}
                        type="switch"
                        value={field.value}
                        onChange={(val) => field.onChange(val)}
                        inputProps={
                          {
                            id: 'can_be_scheduled',
                            ...field,
                            // Weird type issue here. This was copied from src/features/passenger/PassengerForm/AddEditModal.tsx
                            // which doesn't have the error
                          } as unknown as InputHTMLAttributes<HTMLInputElement>
                        }
                      />
                    );
                  }}
                />
                <FormField type="gap" inputProps={{}} />
                <FormField
                  label="Name"
                  type="text"
                  inputProps={{
                    id: 'nickname',
                    ...register('nickname'),
                  }}
                  error={errors.nickname?.message}
                />

                <Controller
                  name="storage_location"
                  control={control}
                  render={({ field: { value } }) => {
                    return (
                      <FormField
                        label="Overnight storage location"
                        type="location-typeahead"
                        inputProps={{
                          id: 'storage-location',
                        }}
                        value={
                          value
                            ? {
                                label: value.label,
                                value: value.place_id,
                              }
                            : undefined
                        }
                        onChange={(option: SelectOption) => {
                          // setValue doesn't trigger revalidation for some reason
                          clearErrors('storage_location');
                          setValue('storage_location', {
                            place_id: option.value,
                            label: option.label,
                          });
                        }}
                        options={
                          selectedVehicle.storage_location.place_id
                            ? [
                                {
                                  label: selectedVehicle.storage_location.label,
                                  value:
                                    selectedVehicle.storage_location.place_id,
                                },
                              ]
                            : []
                        }
                        error={
                          errors.storage_location?.place_id?.message &&
                          !value?.place_id
                            ? 'Please select a valid location'
                            : undefined
                        }
                      />
                    );
                  }}
                />
              </>
            }
          />
          <FormFieldSet
            legend="Vehicle Details"
            Fields={
              <>
                <FormField
                  label="Make"
                  type="text"
                  colSpan={3}
                  inputProps={{
                    id: 'make',
                    placeholder: 'e.g. Honda',
                    ...register('make'),
                  }}
                />
                <FormField
                  label="Model"
                  type="text"
                  colSpan={3}
                  inputProps={{
                    id: 'model',
                    placeholder: 'e.g. CR-V',
                    ...register('model'),
                  }}
                />
                <FormField
                  label="Color"
                  type="text"
                  colSpan={3}
                  inputProps={{
                    id: 'color',
                    placeholder: 'e.g. White',
                    ...register('color'),
                  }}
                />
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormField
                        label="Year"
                        type="number"
                        colSpan={3}
                        inputProps={{
                          id: 'year',
                          placeholder: 'e.g. 2021',
                          ...register('year', {
                            valueAsNumber: true,
                          }),
                        }}
                        value={field.value}
                        error={errors.year?.message}
                      />
                    );
                  }}
                />
                <FormField
                  label="VIN"
                  type="text"
                  helperText="This is 17 characters (digits and capital letters)"
                  inputProps={{
                    id: 'vin',
                    placeholder: 'e.g. KM8SM4HF1JU275542',
                    ...register('vin'),
                  }}
                  error={errors.vin?.message}
                />
                <FormField
                  label="License Number"
                  type="text"
                  colSpan={3}
                  inputProps={{
                    id: 'licenseNumber',
                    placeholder: 'e.g. 123 455 678',
                    ...register('license_plate'),
                  }}
                />
                <FormFieldController name="license_plate_state">
                  {({ field, fieldState }) => {
                    return (
                      <FormFieldContainer colSpan={3}>
                        <DefaultFormFieldLayout
                          label="License Plate State"
                          error={fieldState.error?.message?.toString()}
                          inputProps={{ id: 'license-plate-state' }}
                        >
                          <Select
                            placeholder="Select State"
                            options={states}
                            {...field}
                            onChange={(value?: SelectOption) => {
                              field.onChange(value?.value);
                            }}
                            error={fieldState.error?.message?.toString()}
                          />
                        </DefaultFormFieldLayout>
                      </FormFieldContainer>
                    );
                  }}
                </FormFieldController>
                <FormField
                  label="Additional Notes"
                  type="textarea"
                  colSpan={12}
                  inputProps={{ id: 'notes', ...register('notes') }}
                />
              </>
            }
          />
        </div>
        {selectedVehicle.id && <DeleteAction onDelete={onDelete} />}
        <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <Button
            className="sm:col-span-3"
            onClick={() => {
              onCancel();
              reset();
            }}
          >
            Cancel
          </Button>
          <FormSubmitButton className="sm:col-span-3" />
        </div>
      </form>
    </FormProvider>
  );
}
