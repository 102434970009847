import { useCallback, useEffect, useState } from 'react';

import { PencilIcon } from '@heroicons/react/24/outline';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import {
  PassengerRead,
  usePassengersDestroyMutation,
  useTilledPaymentMethodCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { usePaymentMethodCaptureContext } from 'src/common/forms/PaymentMethodCaptureForm/PaymentMethodCaptureContext';
import { validateBillingAddress } from 'src/common/forms/PaymentMethodCaptureForm/PaymentMethodCaptureForm';
import { useTilled } from 'src/common/forms/PaymentMethodCaptureForm/useTilled';
import { useConfirmClose } from 'src/common/modals/RequiresConfirmationModal/RequiresConfirmationModal';
import { Button } from 'src/common/primitives/Button';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { usePassengerRepository } from 'src/features/add-trip/useAddATripOnSubmit/usePassengerRepository';
import { useIsRideOrderingPortalEnabledForOrganization } from 'src/features/rideOrderingPortal/useIsRideOrderingPortalEnabledForOrganization';

import { AddEditModal } from './AddEditModal';
import { assignDefaultValues } from './assignDefaultValues';
import { DeleteModal } from './DeleteModal';
import { FormValues } from './FormValues';
import { generateDeleteSuccessToast } from './generateDeleteSuccessToast';
import { generateEditSuccessToast } from './generateEditSuccessToast';
import { resolver } from './resolver';

export function PassengerForm({ passenger }: { passenger?: PassengerRead }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {passenger ? (
        <PencilIcon
          data-testid="edit-passenger"
          data-passengerid={passenger.id}
          className="cursor-pointer hover:text-mint"
          onClick={() => setOpen(true)}
        />
      ) : (
        <Button onClick={() => setOpen(true)} variant="primary">
          Add a Passenger
        </Button>
      )}
      {open ? (
        <PassengerFormModal
          open={open}
          setOpen={setOpen}
          passenger={passenger}
        />
      ) : null}
    </>
  );
}

interface PassengerFormModalProps {
  passenger?: PassengerRead;
  open: boolean;
  setOpen: (open: boolean) => void;
  handlePassengerAddEdit?: (passenger: PassengerRead) => void;
}

export function PassengerFormModal({
  passenger,
  open,
  setOpen: setOpenInternal,
  handlePassengerAddEdit,
}: PassengerFormModalProps) {
  const defaultValues = assignDefaultValues(passenger);
  const methods = useForm<FormValues>({
    resolver,
    defaultValues,
  });
  const { handleSubmit, reset, setValue, formState } = methods;
  const shouldConfirmClose = useCallback(
    () => formState.isDirty,
    [formState.isDirty]
  );
  const [setOpen, onClose] = useConfirmClose({
    setOpen: setOpenInternal,
    onClose: reset,
    shouldConfirmClose,
  });

  const isRideOrderingPortalEnabledForOrganization =
    useIsRideOrderingPortalEnabledForOrganization();
  // This is some shenanigans to reuse this for the trip form
  // Not sure why the defaultValues don't load it up
  useEffect(() => {
    if (passenger) {
      setValue('first_name', passenger.first_name);
      setValue('last_name', passenger.last_name);
      setValue('dob', passenger.dob || '');
      setValue('gender', passenger.gender || '');
      setValue('weight', passenger.weight || 0);
      setValue(
        'needs_bariatric_transport',
        passenger.needs_bariatric_transport || false
      );
      setValue('phone_number', passenger.phone_number || '');
      setValue('email', passenger.email || '');
      setValue('notes', passenger.notes);
      setValue('dispatcher_notes', passenger.dispatcher_notes);
      setValue('billing_details', {
        city: '',
        country: '',
        zip: '',
        state: '',
        street: '',
        street2: '',
        billingName: passenger.full_name || '',
      });
      setValue('payer_ids', passenger.payer_ids || []);
      setValue('allow_sms_message', passenger.allow_sms_message ?? false);
    } else {
      reset();
    }
  }, [passenger, setValue, reset]);

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);
  const nextToastId = useSelector((state: RootState) => state.toast.nextId);
  const dispatch = useDispatch();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [deletePassenger] = usePassengersDestroyMutation();
  const { createPassenger, updatePassenger } = usePassengerRepository();
  const { savePaymentMethod, validateForm: validatePaymentForm } =
    useTilled('card');
  const [savePaymentMethodCreate] = useTilledPaymentMethodCreateMutation();
  const { areFieldsEmpty } = usePaymentMethodCaptureContext();

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    try {
      setServerError(null);

      // Do not send these values that are null or empty strings
      if (!data.dob) delete data.dob;
      if (!data.gender) delete data.gender;
      if (!isRideOrderingPortalEnabledForOrganization) delete data.payer_ids;

      // Currently, we only add passengers through the trip form, so this is only needed when editing
      // There's some backend work on the /passengers/ POST endpoint to accept payer_ids. Hiding this
      // lets us punt that til we want to add passenger creation to the passenger list page
      if (!passenger?.id) {
        delete data.payer_ids;
      }

      // Drop billing details from pax update request
      const billingDetails = data.billing_details;
      const billingName = data.billing_details?.billingName;
      const billingAddress = {
        city: billingDetails?.city,
        country: billingDetails?.country,
        zip: billingDetails?.zip,
        state: billingDetails?.state,
        street: billingDetails?.street,
        street2: billingDetails?.street2,
      };
      delete data.billing_details;

      // TODO: Fix up the types of FormValues
      const upsertedPassengerResult = passenger?.id
        ? await updatePassenger({
            id: passenger.id,
            ...data,
            phone_number:
              typeof data.phone_number === 'undefined' ? '' : data.phone_number,
          } as PassengerRead)
        : await createPassenger(data as PassengerRead);
      if ('data' in upsertedPassengerResult) {
        const upsertedPassenger = upsertedPassengerResult.data;
        // TODO: this is not great. This whole method needs to be moved to the parent
        // but in the meantime, this is a quick fix to get the passenger to update
        handlePassengerAddEdit && handlePassengerAddEdit(upsertedPassenger);
        const validPaymentForm = await validatePaymentForm();
        const validatedBillingAddress = validateBillingAddress(billingAddress);

        dispatch(show(generateEditSuccessToast(nextToastId, data.first_name)));

        // Skip saving payment method
        if (areFieldsEmpty()) {
          setOpen(false, true);
        }
        // Payment method form is invalid
        else if (!validPaymentForm) {
          dispatch(
            show({
              type: 'error',
              title: 'Unable to save payment method',
              description: 'Payment details are invalid, or missing',
            })
          );
        }
        // Payment method form is valid, attempt to save
        else {
          try {
            // Persist payment method in tilled
            const savedPaymentMethod = await savePaymentMethod({
              type: 'card',
              billing_details: {
                name: billingName ?? `${data.first_name} ${data.last_name}`,
                address: validatedBillingAddress
                  ? {
                      ...validatedBillingAddress,
                      zip: validatedBillingAddress.zip,
                    }
                  : undefined,
              },
            });

            // Attach payment method to pax
            await savePaymentMethodCreate({
              tilledPaymentMethodInput: {
                payer_id: upsertedPassenger.id,
                payer_type: 'passenger',
                payment_method_id: savedPaymentMethod.id,
              },
            });
            dispatch(
              show({
                title: 'Saved payment method',
                type: 'success',
              })
            );
            setOpen(false, true);
          } catch (e) {
            dispatch(
              show({
                title: 'Failed to save payment method',
                description: formatServerError(e),
                type: 'error',
              })
            );
          }
        }
      } else {
        const formattedErr = formatServerError(upsertedPassengerResult.error);
        setServerError(formattedErr);
      }
    } catch (error) {
      const formattedErr = formatServerError(error);
      setServerError(formattedErr);
    }
    setLoading(false);
  };
  return (
    <FormProvider {...methods}>
      <AddEditModal
        open={open}
        setOpen={setOpen}
        onCancel={() => {
          setOpen(false);
          setServerError(null);
        }}
        onDelete={() => {
          setIsConfirmingDelete(true);
          setOpen(false);
        }}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
        passenger={passenger}
        setValue={setValue}
        loading={loading}
        serverErrors={serverError}
      />
      <DeleteModal
        open={isConfirmingDelete}
        setOpen={setIsConfirmingDelete}
        onCancel={() => {
          setOpen(true);
          setIsConfirmingDelete(false);
        }}
        onConfirm={() => {
          deletePassenger({
            id: passenger?.id || '',
          });
          setIsConfirmingDelete(false);
          reset();
          dispatch(show(generateDeleteSuccessToast(nextToastId)));
        }}
      />
    </FormProvider>
  );
}
