import { IconTooltip } from '../primitives/Tooltip';

/**
 * Inherit from this base interface and switch on `type`
 * to prevent conflicting options
 */
interface BasePresentationOptions {
  position?: string;
}

export interface InlinePresentationOptions extends BasePresentationOptions {
  type: 'inline';
}

export interface TooltipPresentationOptions extends BasePresentationOptions {
  type: 'tooltip';
  isStatic?: boolean;
  iconPosition?: PopoverIconPosition;
}

export type HelperTextConfig = {
  text: string;

  // Defaults to inline
  presentation?: InlinePresentationOptions | TooltipPresentationOptions;
};

/**
 * The caller can pass a string or a HelperTextConfig to form fields.
 * If it's a string, it's displayed inline, with no custom positioning.
 */
export type HelperText = string | HelperTextConfig;

export type PopoverIconPosition = 'label-right' | 'input-right';

export interface HelperTextPopoverProps {
  text: string;

  // Is icon position static
  iconPosition?: PopoverIconPosition;
  isStatic?: boolean;
  textPosition?: string;
}

export function HelperTextPopover({
  text,
  isStatic,
  textPosition,
}: HelperTextPopoverProps) {
  return (
    <div className={isStatic ? '' : 'absolute top-2 right-2'}>
      <IconTooltip textPosition={textPosition}>{text}</IconTooltip>
    </div>
  );
}

/**
 * Inspects the helperText for its type and properties
 * and returns props for either an inline string
 * or the HelperTextPopover as appropriate.
 */
interface HelperTextResult {
  popoverProps?: HelperTextPopoverProps;
  inlineHelperText?: string;
}
export const getHelperTextProps = (
  helperText?: HelperText
): HelperTextResult => {
  if (!helperText) {
    return {};
  }

  if (typeof helperText === 'string') {
    return {
      inlineHelperText: helperText,
    };
  }

  const { presentation, text } = helperText;
  if (isTooltipPresentation(presentation)) {
    return {
      popoverProps: {
        text,
        iconPosition: presentation.iconPosition ?? 'label-right',
        isStatic: presentation.isStatic ?? true,
        textPosition: presentation.position,
      },
    };
  }
  return {
    inlineHelperText: text,
  };
};

export const isTooltipPresentation = (
  presentation: HelperTextConfig['presentation']
): presentation is TooltipPresentationOptions => {
  return (
    typeof presentation === 'object' &&
    'type' in presentation &&
    presentation.type === 'tooltip'
  );
};
