import { Passenger } from 'src/common/external/bambi-api/bambiApi';

import { FormValues } from './FormValues';

export function assignDefaultValues(passenger?: Passenger): FormValues {
  return {
    first_name: passenger?.first_name || '',
    last_name: passenger?.last_name || '',
    email: passenger?.email || '',
    dob: passenger?.dob || '',
    needs_bariatric_transport: passenger?.needs_bariatric_transport || false,
    gender: passenger?.gender || '',
    weight: passenger?.weight || 0,
    phone_number: passenger?.phone_number || '',
    notes: passenger?.notes || '',
    dispatcher_notes: passenger?.dispatcher_notes || '',
    billing_details: {
      billingName: `${passenger?.first_name ?? ''} ${
        passenger?.last_name ?? ''
      }`.trim(),
      city: '',
      country: '',
      zip: '',
      state: '',
      street: '',
      street2: '',
    },
    payer_ids: passenger?.payer_ids || [],
    allow_sms_message: passenger?.allow_sms_message ?? false,
  };
}
