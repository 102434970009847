import Switch from 'src/common/primitives/Switch';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';

type FormFieldSwitchProps<T> = Pick<
  FormFieldProps<T>,
  'inputProps' | 'label' | 'helperText' | 'error'
>;

export function FormFieldSwitch<T>({
  inputProps,
  label = '',
  helperText = '',
  error,
}: FormFieldSwitchProps<T>) {
  return (
    <DefaultFormFieldLayout
      label={label}
      inputProps={inputProps}
      helperText={helperText}
      error={error}
    >
      <Switch
        data-testid={inputProps.id}
        label={label}
        inputProps={inputProps}
      />
    </DefaultFormFieldLayout>
  );
}
