import {
  BlankEnum,
  StairFeeTypeEnum,
} from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import {
  FormFieldController,
  FormFieldControllerRulesProps,
} from 'src/common/FormField/FormFieldController';
import { SelectOption } from 'src/common/primitives/Select';

export function StairFeeTypeField({
  fieldPath,
  rules,
  disabled,
}: {
  fieldPath: string;
  rules?: FormFieldControllerRulesProps;
  disabled?: boolean;
}) {
  const stairFeeTypes: StairFeeTypeEnum[] = ['Flat', 'Rate'];
  const blankOption: BlankEnum = '';
  const stairFeeTypeOptions: SelectOption[] = [
    blankOption,
    ...stairFeeTypes,
  ].map((type) => ({
    label: type || 'None',
    value: type,
  }));

  return (
    <FormFieldController name={fieldPath}>
      {({ field }) => {
        return (
          <FormField
            label={'Stair Fee Type' + (rules?.required ? ' *' : '')}
            type="select"
            inputProps={{
              ...field,
              id: fieldPath,
            }}
            onChange={(option) => {
              field.onChange(option.value);
            }}
            options={stairFeeTypeOptions}
            value={
              field.value
                ? {
                    label: field.value,
                    value: field.value,
                  }
                : blankOption
            }
            inline
            disabled={disabled}
            helperText="Choose the type of stair fee, whether it is a flat fee or a fee per step."
          />
        );
      }}
    </FormFieldController>
  );
}
