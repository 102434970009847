import { useFeature } from 'src/app/FeatureToggle';
import {
  ContextFormFieldCheckbox,
  ContextFormFieldNumber,
} from 'src/common/FormField/v2/context/components';

import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function PricingSettings() {
  const { isEnabled: isPricingV1Enabled, isLoading: isLoadingPricingV1Flag } =
    useFeature('pricing version 1');

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Pricing Settings"
        description="Organization defaults for pricing."
      />

      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <ContextFormFieldNumber
            label="Late cancellation if canceled within these hours of pickup"
            fieldPath="cancellation_fee_window_hours"
          />
        </div>
        {!isLoadingPricingV1Flag && isPricingV1Enabled && (
          <div className="flex items-center align-middle">
            <ContextFormFieldCheckbox
              label="Use Latest Pricing"
              fieldPath="is_pricing_v1_enabled"
            />
          </div>
        )}
      </div>
    </div>
  );
}
