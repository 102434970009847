import { ContextFormFieldCheckbox } from 'src/common/FormField/v2/context/components';

import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function SignatureSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Signature Settings"
        description="Organization defaults for signatures."
      />

      <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <ContextFormFieldCheckbox
            label="Driver on Pickup"
            fieldPath="requires_signature_pickup_driver"
          />
        </div>
        <div>
          <ContextFormFieldCheckbox
            label="Driver on Dropoff"
            fieldPath="requires_signature_dropoff_driver"
          />
        </div>
        <div>
          <ContextFormFieldCheckbox
            label="Passenger on Pickup"
            fieldPath="requires_signature_pickup_passenger"
          />
        </div>
        <div>
          <ContextFormFieldCheckbox
            label="Passenger on Dropoff"
            fieldPath="requires_signature_dropoff_passenger"
          />
        </div>
      </div>
    </div>
  );
}
