import {
  SimpleContextFormField,
  SimpleContextFormFieldProps,
} from '../../SimpleContextFormField';

// TODO: Create a new component for each of these using
// shared base props as understanding of the boundaries and types
// becomes clearer. Once done, remove the SimpleContextFormField.

export const ContextFormFieldText = (props: SimpleContextFormFieldProps) => {
  return <SimpleContextFormField {...props} type="text" />;
};

export const ContextFormFieldTextArea = (
  props: SimpleContextFormFieldProps
) => {
  return <SimpleContextFormField {...props} type="textarea" />;
};

export const ContextFormFieldNumber = (props: SimpleContextFormFieldProps) => {
  return <SimpleContextFormField {...props} type="number" />;
};

export const ContextFormFieldLocationTypeahead = (
  props: SimpleContextFormFieldProps
) => {
  return <SimpleContextFormField {...props} type="location-typeahead" />;
};

export const ContextFormFieldSelect = (props: SimpleContextFormFieldProps) => {
  return <SimpleContextFormField {...props} type="select" />;
};

export const ContextFormFieldSwitch = (props: SimpleContextFormFieldProps) => {
  return <SimpleContextFormField {...props} type="switch" />;
};

export const ContextFormFieldCheckbox = (
  props: SimpleContextFormFieldProps
) => {
  return <SimpleContextFormField {...props} type="checkbox" />;
};

export const ContextFormFieldBorderedCheckbox = (
  props: SimpleContextFormFieldProps
) => {
  return <SimpleContextFormField {...props} type="bordered-checkbox" />;
};

export const ContextFormFieldTime = (props: SimpleContextFormFieldProps) => {
  return <SimpleContextFormField {...props} type="time" />;
};

export const ContextFormFieldDate = (props: SimpleContextFormFieldProps) => {
  return <SimpleContextFormField {...props} type="date" />;
};

export const ContextFormFieldEmail = (props: SimpleContextFormFieldProps) => {
  return <SimpleContextFormField {...props} type="email" />;
};

export const ContextFormFieldPassword = (
  props: SimpleContextFormFieldProps
) => {
  return <SimpleContextFormField {...props} type="password" />;
};

export const ContextFormFieldColor = (props: SimpleContextFormFieldProps) => {
  return <SimpleContextFormField {...props} type="color" />;
};

export const ContextFormFieldGap = (props: SimpleContextFormFieldProps) => {
  return <SimpleContextFormField {...props} type="gap" />;
};
