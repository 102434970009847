import { DateTime } from 'luxon';

import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { Skeleton } from 'src/common/Skeleton';

import { isDispatchTrip } from '../isDispatchTrip';

interface TripDetailsEstimatedPickupProps {
  isTripDataLoading: boolean;
  trip:
    | DispatchTripRead
    | RunBambiRunTripRead
    | RunBambiRunReassignedTripRead
    | TripRead;
}

export function TripDetailsEstimatedPickup({
  isTripDataLoading,
  trip,
}: TripDetailsEstimatedPickupProps) {
  return (
    <div>
      <h4 className="py-2 text-sm font-bold">Estimated Pickup</h4>
      {isTripDataLoading ? (
        <Skeleton />
      ) : isDispatchTrip(trip) && trip.timing?.estimated_pickup_arrival_time ? (
        <div className="text-sm">
          <p className="text-sm font-medium text-gray-500">
            {DateTime.fromISO(
              trip.timing.estimated_pickup_arrival_time
            ).toLocaleString(DateTime.DATETIME_MED)}
          </p>
        </div>
      ) : null}
    </div>
  );
}
