import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import {
  usePayersListQuery,
  usePayersPassengersRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { useAuth } from 'src/features/auth/useAuth';

import { pricingNameRoot } from './pricingNameRoot';

export function BrokerPayerFields() {
  const auth = useAuth();
  const { setValue, watch } = useFormContext();

  const selectedPassenger = useSelector(
    (state: RootState) => state.addATrip.selectedPassenger
  );

  const payer = watch(`${pricingNameRoot}.payer`);

  const { data: payers } = usePayersListQuery({
    'Organization-ID': auth.currentOrganizationId || '',
  });

  const payerCategory = payers?.results?.find(
    (result) => result.id === payer
  )?.payer_type;

  const { data: payerPassenger } = usePayersPassengersRetrieveQuery(
    {
      'Organization-ID': auth.currentOrganizationId || '',
      passengerId: selectedPassenger?.id || '',
      payerOrganizationId: payer,
    },
    { skip: !selectedPassenger?.id || !payer || payerCategory !== 'broker' }
  );

  useEffect(() => {
    if (payerPassenger) {
      setValue(
        `${pricingNameRoot}.payer_passenger_external_id`,
        payerPassenger.external_id
      );
    }
  }, [payerPassenger, setValue]);

  return (
    <>
      <div className="grow" data-testid="broker-payer-fields">
        <FormFieldController
          name={`${pricingNameRoot}.payer_passenger_external_id`}
        >
          {({ field, fieldState }) => {
            return (
              <FormField
                label="Member ID"
                error={fieldState.error?.message?.toString()}
                type="text"
                inputProps={{
                  id: `${pricingNameRoot}.payer_passenger_external_id`,
                  ...field,
                }}
              />
            );
          }}
        </FormFieldController>
      </div>
    </>
  );
}
