import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
  usePassengersRetrieveQuery,
  useTripsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Skeleton } from 'src/common/Skeleton';
import { TRIP_STATUSES } from 'src/common/tripStatuses';
import TripTagDisplay from 'src/features/dispatch/trips/TripTagDisplay';
import { TripSpaceTypeBadge } from 'src/features/trip/management/TripSpaceTypeBadge';

import { RunBambiRunCalendarEventType } from '../../common/RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES';
import { EventMenu } from './EventMenu';
import { FieldDisplay } from './FieldDisplay';
import { isDispatchTrip } from './isDispatchTrip';
import { PhoneNumberLink } from './PhoneNumberLink';
import { TripDetailsDropoff } from './popover/TripDetailsDropoff';
import { TripDetailsEstimatedPickup } from './popover/TripDetailsEstimatedPickup';
import { TripDetailsPayer } from './popover/TripDetailsPayer';
import { TripDetailsPickup } from './popover/TripDetailsPickup';
import { TripDetailsStatus } from './popover/TripDetailsStatus';

type TripDetailsProps = {
  trip:
    | DispatchTripRead
    | RunBambiRunTripRead
    | RunBambiRunReassignedTripRead
    | TripRead;
  type?: RunBambiRunCalendarEventType;
};

export function TripDetails({ trip, type }: TripDetailsProps) {
  const { data: passenger, isLoading: isPassengerLoading } =
    usePassengersRetrieveQuery({
      // TripRead passenger ids can be undefined because it generates a PatchedTripPassengerRead
      id: trip.passenger.id || '',
    });
  const { data: tripData, isLoading: isTripDataLoading } =
    useTripsRetrieveQuery({
      id: trip.id,
    });
  return (
    <div className="flex max-w-[35em] flex-col gap-2">
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {trip.passenger.full_name}
            </h3>

            {'assignment_id' in trip && (
              <div>
                <TripSpaceTypeBadge spaceType={trip.space_type} />
              </div>
            )}
            {isDispatchTrip(trip) && trip.tags?.length ? (
              <div className="ml-2">
                <TripTagDisplay tags={trip.tags} />
              </div>
            ) : null}
          </div>
          {isDispatchTrip(trip) && trip.missing_any_required_signature && (
            <FormErrorMessage>
              <div className="flex gap-2">
                Driver missed collecting signature and needs to be alerted
              </div>
            </FormErrorMessage>
          )}
          {isPassengerLoading ? (
            <Skeleton />
          ) : (
            passenger?.phone_number && (
              <div>
                <h4 className="text-sm font-medium text-gray-500">Phone</h4>
                <p className="text-sm text-gray-500">
                  <PhoneNumberLink phoneNumber={passenger.phone_number} />
                </p>
              </div>
            )
          )}
          {isPassengerLoading ? (
            <Skeleton />
          ) : (
            <div>
              <FieldDisplay value={passenger?.notes} label="Passenger Notes" />
            </div>
          )}
          {isTripDataLoading ? (
            <Skeleton />
          ) : (
            <div>
              <FieldDisplay
                value={tripData?.dispatcher_notes}
                label="Dispatcher Notes"
              />
            </div>
          )}
        </div>
        {/* TODO: Move EventMenu into a popup on click and make it work with all trips by not using useLazyTripsRetrieveQuery */}
        {!type && 'assignment_id' in trip && (
          <div>
            <EventMenu tripId={trip.id} />
          </div>
        )}
      </div>
      {/* 
          Breakpoints to keep action menu on screen without adding a ton of complexity 
      */}
      <style>
        {`
          .trip-detail__additional {
            max-height: 15vh;
          }

          @media (min-height: 650px) {
            .trip-detail__additional {
              max-height: 25vh;
            }
          }

          @media (min-height: 850px) {
            .trip-detail__additional {
              max-height: 30vh;
            }
          }

          @media (min-height: 1200px) {
            .trip-detail__additional {
              max-height: 50vh;
            }
          }
        `}
      </style>
      <div className="trip-detail__additional overflow-y-auto border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="flex flex-col gap-1 sm:divide-y sm:divide-gray-200">
          <TripDetailsStatus
            isTripDataLoading={isTripDataLoading}
            tripData={tripData}
            trip={trip}
          />
          <TripDetailsPayer
            isTripDataLoading={isTripDataLoading}
            tripData={tripData}
          />
          <TripDetailsPickup
            isTripDataLoading={isTripDataLoading}
            trip={trip}
            tripData={tripData}
          />
          <TripDetailsDropoff
            isTripDataLoading={isTripDataLoading}
            trip={trip}
            tripData={tripData}
          />
          {trip.status === TRIP_STATUSES.EN_ROUTE && (
            <TripDetailsEstimatedPickup
              isTripDataLoading={isTripDataLoading}
              trip={trip}
            />
          )}
        </div>
      </div>
    </div>
  );
}
