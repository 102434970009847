import { LatLong } from 'src/common/external/aws/LatLong';
import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';

export function getTripDropoffLatLng(
  trip:
    | DispatchTripRead
    | RunBambiRunTripRead
    | RunBambiRunReassignedTripRead
    | TripRead
): LatLong {
  if ('dropoff_latitude' in trip) {
    return {
      lat: trip.dropoff_latitude,
      long: trip.dropoff_longitude,
    };
  }

  if (!('dropoff' in trip)) {
    throw new Error('Got an unexpected trip type');
  }

  return {
    lat: trip.dropoff.latitude,
    long: trip.dropoff.longitude,
  };
}
