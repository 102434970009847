import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { ContextFormFieldText } from 'src/common/FormField/v2/context/components';
import { UploadLogoFormField } from 'src/common/primitives/Uploader/UploadLogoFormField';

import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function ExportSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Export Settings"
        description="Organization defaults for exporting data."
      />

      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2 md:col-span-3">
        <div>
          <ContextFormFieldText
            label="Tax ID"
            fieldPath="tax_id"
            placeholder="Tax ID for export"
          />
        </div>
        <FormFieldController name="logo">
          {({ field, fieldState }) => {
            return (
              <UploadLogoFormField
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
        </FormFieldController>
      </div>
    </div>
  );
}
