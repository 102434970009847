import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';

import { pricingNameRoot } from './pricingNameRoot';

export function InsurancePayerFields() {
  return (
    <>
      <div className="grow" data-testid="insurance-payer-fields">
        <FormFieldController name={`${pricingNameRoot}.authorization_id`}>
          {({ field, fieldState }) => {
            return (
              <FormField
                label="Authorization ID/Number"
                error={fieldState.error?.message?.toString()}
                type="text"
                inputProps={{
                  id: `${pricingNameRoot}.authorization_id`,
                  ...field,
                }}
              />
            );
          }}
        </FormFieldController>
      </div>
      <div className="grow">
        <FormFieldController name={`${pricingNameRoot}.insurance_policy_id`}>
          {({ field, fieldState }) => {
            return (
              <FormField
                label="Insurance Policy ID"
                error={fieldState.error?.message?.toString()}
                type="text"
                inputProps={{
                  id: `${pricingNameRoot}.insurance_policy_id`,
                  ...field,
                }}
              />
            );
          }}
        </FormFieldController>
      </div>
    </>
  );
}
