import { useAppDispatch, useAppSelector } from 'src/app/store';

import { setFilterPrice } from '../../trip.slice';
import { PriceFilter } from './PriceFilter';

interface PriceColumnFilterProps {
  open: boolean;
  onClose: () => void;
}

export function PriceColumnFilter({ open, onClose }: PriceColumnFilterProps) {
  const dispatch = useAppDispatch();
  const priceRange = useAppSelector((state) => state.trip.filterPrice);

  return (
    <PriceFilter
      open={open}
      triggerElement={<div />}
      value={priceRange ?? undefined}
      onChange={(range) => {
        dispatch(setFilterPrice(range));
      }}
      onClose={onClose}
    />
  );
}
