import { useState } from 'react';

import { TextInput } from '../../TextInput';
import { SelectOption } from '../SelectOption/SelectOption';

export function NewOptionInput({
  id,
  selected,
  placeholder,
  options,
  onChange,
}: {
  id?: string;
  selected: SelectOption[];
  placeholder?: string;
  options: SelectOption[];
  onChange?: (value: SelectOption[]) => void;
}) {
  const [newOptionText, setNewOptionText] = useState('');
  const [newOptionError, setNewOptionError] = useState('');
  return (
    <div className="flex flex-grow items-center gap-1">
      <TextInput
        id={id}
        className="w-full bg-slate-100 p-1"
        placeholder={placeholder || 'Add new option'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        value={newOptionText}
        onChange={(e) => setNewOptionText(e.target.value)}
        onKeyDown={(e) => {
          setNewOptionError('');
          e.stopPropagation();
          if (e.key === 'Enter') {
            if (!newOptionText.trim()) {
              setNewOptionError('Option cannot be empty');
              return;
            }
            const trimmedNewOptionValues = newOptionText
              .split(',')
              .map((o) => o.trim())
              .filter((o) => o);

            const areAnyDuplicate = [...options, ...selected].some((o) =>
              trimmedNewOptionValues.includes(o.value)
            );

            if (areAnyDuplicate) {
              setNewOptionError('Option already exists');
              return;
            }
            onChange?.([
              ...selected,
              ...trimmedNewOptionValues.map((value) => ({
                value,
                label: value,
              })),
            ]);
            setNewOptionText('');
          }
        }}
        data-testid="multi-select-add-new-option"
      />
      {newOptionError && (
        <span className="text-xs text-red-500">{newOptionError}</span>
      )}
    </div>
  );
}
