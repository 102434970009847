import { MutableRefObject, useCallback, useEffect } from 'react';

import { createSelector } from '@reduxjs/toolkit';

import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';

import {
  setEventCalendarScrollable,
  setShowToggleCalendarScrollButton,
} from '../dispatcher.slice';

const EXPANDED_ASSIGNMENT_COLUMN_WIDTH = 200;

export const css = `
  .dispatch-calendar-root .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header, .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-week-days {
    background-color: white;
  }
  .dispatch-calendar-root .mbsc-ios.mbsc-eventcalendar-schedule .mbsc-calendar-day, .mbsc-ios.mbsc-schedule-wrapper {
    background-color: white;
  }
  .dispatch-calendar-root .mbsc-ios.mbsc-schedule-date-header {
    display: none;
  }

  .dispatch-calendar-root .mbsc-schedule-resource-title {
    padding: 0;
  }
  .dispatch-calendar-root .mbsc-schedule-resource-group.mbsc-ltr,
  .dispatch-calendar-root .mbsc-ios.mbsc-schedule-resource.mbsc-ltr {
    border-left: none;
  }

  .dispatch-calendar-root .mbsc-ios.mbsc-schedule-time-indicator {
    /* Volcano, apparently */
      border-color: #FF7A45;
  }

  .dispatch-calendar-root .mbsc-ios.mbsc-schedule-cursor-time, .mbsc-ios.mbsc-schedule-time-indicator-time {
    color: #FF7A45;
  }

  .dispatch-calendar-root .mbsc-ios.mbsc-schedule-invalid {
    background-color: #E5E7EB;
  }

  .dispatch-calendar-root .mbsc-schedule-header-item {
    display: none;
  }

  /* min-width needs to be set for column expansion to work */
  /* 
    200px as min-width breaks what users call "fit to screen" for assignments 
    so this reverts the behavior but maintains the column expansion animation 
    Once this is out I'll start playing around with a better solution to
    compromise
  */
  .dispatch-calendar-root .mbsc-schedule-col-width {
    min-width: 1px;
  }
  /* Scrollable */
  /* Based on forum post: https://forum.mobiscroll.com/t/event-calendar-resource-fixed-width-or-scroll/1645/3 */
  .dispatch-calendar-root.scrollable .mbsc-flex-col.mbsc-flex-1-1.mbsc-schedule-grid-scroll.mbsc-ios::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: .5em
  }

  .dispatch-calendar-root.scrollable .mbsc-flex-col.mbsc-flex-1-1.mbsc-schedule-grid-scroll.mbsc-ios::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .dispatch-calendar-root.scrollable .mbsc-schedule-col-width {
    width: ${EXPANDED_ASSIGNMENT_COLUMN_WIDTH}px;
  }
`;

const selectAssignments = (state: RootState) =>
  state.dispatcher.dispatchResponse?.assignments;

const selectAssignmentWidth = createSelector(
  [selectAssignments],
  (assignments) => {
    const assignmentsWidth = assignments?.length
      ? assignments.length * EXPANDED_ASSIGNMENT_COLUMN_WIDTH
      : 0;

    return assignmentsWidth;
  }
);

const useDynamicCss = (calendar: MutableRefObject<any>) => {
  const dispatch = useAppDispatch();
  const assignmentsWidth = useAppSelector(selectAssignmentWidth);
  const isDispatchSidebarOpen = useAppSelector(
    (state) => state.dispatcher.isDispatchSidebarOpen
  );
  const calculateShouldShowToggle = useCallback(() => {
    if (calendar.current?.nativeElement instanceof HTMLElement) {
      const calendarBodyWidth = calendar.current.nativeElement.querySelector(
        '.mbsc-schedule-grid-wrapper'
      ).clientWidth;

      return calendarBodyWidth - assignmentsWidth < 0;
    }
  }, [calendar, assignmentsWidth]);

  useEffect(() => {
    const updateStateFromWidth = () => {
      const shouldShowToggle = calculateShouldShowToggle();
      dispatch(setShowToggleCalendarScrollButton(shouldShowToggle));
      if (!shouldShowToggle) {
        dispatch(setEventCalendarScrollable(false));
      }
    };

    window.addEventListener('resize', updateStateFromWidth);
    updateStateFromWidth();

    return () => window.removeEventListener('resize', updateStateFromWidth);
  }, [
    dispatch,
    assignmentsWidth,
    calculateShouldShowToggle,
    isDispatchSidebarOpen,
  ]);
};

export default useDynamicCss;
