import {
  IntermediaryEnum,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { BADGE_COLOR_VARIANTS, Badge } from 'src/common/primitives/Badge';
import { Popover } from 'src/common/primitives/Popover';
type TripImportedBadgeProps = {
  trip: TripRead;
};

export const TripImportedBadge: React.FC<TripImportedBadgeProps> = ({
  trip,
}) => {
  const {
    one_schema_template_id,
    one_schema_template_multi_id,
    external_trip_id,
    intermediary_id,
  } = trip;
  if (
    one_schema_template_id ||
    one_schema_template_multi_id ||
    intermediary_id
  ) {
    const source = getSource(trip);

    return (
      <Popover
        trigger={
          <div className="cursor-pointer">
            <Badge label="Imported" color={BADGE_COLOR_VARIANTS.GREEN} />
          </div>
        }
        content={
          <div className="m-[-10px] text-sm">
            <div>
              <span className="font-bold">Source:</span> {source}
            </div>
            <div>
              <span className="font-bold">External Trip ID:</span>{' '}
              {external_trip_id || intermediary_id}
            </div>
          </div>
        }
      ></Popover>
    );
  }

  return null;
};

const getSource = (trip: TripRead) => {
  if (trip.one_schema_template_id || trip.one_schema_template_multi_id) {
    return 'CSV';
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const intermediary = trip.intermediary ?? '';
  if (intermediary !== '') {
    return intermediaryDisplayName(intermediary);
  }
  return 'Kinetik';
};

const intermediaryDisplayName = (intermediary: IntermediaryEnum) => {
  switch (intermediary) {
    case 'alivi':
      return 'Alivi';
    case 'call_the_car':
      return 'Call The Car';
    case 'kinetik':
      return 'Kinetik';
    case 'momentm':
      return 'Momentm';
    case 'mtm':
      return 'MTM';
  }
};
