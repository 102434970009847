import { useMemo } from 'react';

import { Select } from 'src/common/primitives/Select';
import { transportTypeOptions } from 'src/features/add-trip/ServiceDetails/transportTypeOptions';

import { DefaultFormFieldLayout } from './FormField/DefaultFormFieldLayout';
import {
  FormFieldController,
  FormFieldControllerRulesProps,
} from './FormField/FormFieldController';
import { HelperText } from './FormField/FormFieldHelperText';
import { MultiSelectContextField } from './FormField/MultiSelectContextField';

export function SpaceTypeField({ fieldName }: { fieldName: string }) {
  return (
    <FormFieldController name={fieldName}>
      {({ field }) => {
        return (
          <DefaultFormFieldLayout
            label="Transport Type"
            inputProps={{ id: fieldName }}
          >
            <Select
              value={field.value}
              options={transportTypeOptions}
              testIdSuffix={fieldName}
              onChange={(selected) => {
                field.onChange(selected?.value);
              }}
              placeholder="Select a transport type"
            />
          </DefaultFormFieldLayout>
        );
      }}
    </FormFieldController>
  );
}

export function MultiSelectSpaceTypeField({
  fieldPath,
  rules,
  inline,
  disabled,
  helperText,
}: {
  fieldPath: string;
  rules?: FormFieldControllerRulesProps;
  inline?: boolean;
  disabled?: boolean;
  helperText?: HelperText;
}) {
  const label = useMemo(() => {
    return rules?.required ? 'Space Types *' : 'Space Types';
  }, [rules?.required]);

  return (
    <div>
      <MultiSelectContextField
        options={transportTypeOptions}
        fieldPath={fieldPath}
        label={label}
        disabled={disabled}
        rules={rules}
        inline={inline}
        helperText={helperText}
      />
    </div>
  );
}
