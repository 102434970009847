import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { FormFieldSwitch } from 'src/common/FormField/FormFieldSwitch';

import { serviceDetailsNameRoot } from './ServiceDetailsFormBody';

export default function HasInfectiousDiseaseField() {
  const fieldName = `${serviceDetailsNameRoot}.has_infectious_disease`;
  return (
    <FormFieldController name={fieldName}>
      {({ field, fieldState }) => {
        return (
          <FormFieldSwitch
            label="Infectious Disease?"
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: fieldName,
              ...field,
            }}
          />
        );
      }}
    </FormFieldController>
  );
}
