import { Select, SelectOption } from 'src/common/primitives/Select';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';
import { getHelperTextProps, HelperTextPopover } from './FormFieldHelperText';

export function FormFieldSelect<T>({
  inputProps,
  label,
  error,
  value,
  options,
  placeholder,
  disabled,
  onChange,
  inline,
  helperText,
}: FormFieldProps<T>) {
  if (!options) {
    throw new Error('options is required for select type');
  }

  const { popoverProps } = getHelperTextProps(helperText);

  return (
    <DefaultFormFieldLayout
      inline={inline}
      label={label}
      error={error}
      inputProps={inputProps}
    >
      <div className="flex items-center gap-2" data-testid={inputProps.id}>
        <Select
          selected={value as SelectOption}
          options={options}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(newOption?: SelectOption) =>
            typeof onChange === 'function' && onChange(newOption)
          }
          error={error}
        />
        {popoverProps && <HelperTextPopover {...popoverProps} />}
      </div>
    </DefaultFormFieldLayout>
  );
}
