import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';

export function FirstNameField({ disabled }: { disabled?: boolean }) {
  return (
    <FormFieldController
      name="first_name"
      rules={{ required: 'First name is required' }}
    >
      {({ field, fieldState }) => {
        return (
          <FormField
            label="First Name *"
            error={fieldState.error?.message?.toString()}
            type="text"
            inputProps={{
              id: 'first-name',
              placeholder: 'First Name',
              ...field,
              disabled,
            }}
            disabled={disabled}
          />
        );
      }}
    </FormFieldController>
  );
}
