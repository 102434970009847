import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { formatFullName } from 'src/common/util/formatFullName';

export function getTripDriverName(
  trip:
    | DispatchTripRead
    | RunBambiRunTripRead
    | RunBambiRunReassignedTripRead
    | TripRead
): string | undefined {
  if (
    'assignment' in trip &&
    trip.assignment &&
    'driver' in trip.assignment &&
    // Assignment can indeed not exist here
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    trip.assignment.driver
  ) {
    return formatFullName(trip.assignment.driver);
  }

  if ('driver_name' in trip) {
    return trip.driver_name;
  }
}
