import { createSelector } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';

import {
  dispatcherSlice,
  selectSelectedUnassignedTripIds,
  selectUnassignedTrips,
} from '../../dispatcher.slice';

const selectUnassignedSelectedRequestedTrips = createSelector(
  [selectSelectedUnassignedTripIds, selectUnassignedTrips],
  (selectedUnassignedTripIds, unassignedTrips) => {
    return unassignedTrips?.filter(
      (trip) =>
        trip.status === 'requested' &&
        selectedUnassignedTripIds.includes(trip.id)
    );
  }
);

export function RejectRequestedTripsButton() {
  const dispatch = useAppDispatch();

  const selectedUnassignedRequestedTrips = useAppSelector(
    selectUnassignedSelectedRequestedTrips
  );

  return (
    <Button
      disabled={
        !selectedUnassignedRequestedTrips ||
        selectedUnassignedRequestedTrips.length === 0
      }
      variant="ghost-danger"
      data-testid="reject-selected-unassigned-trip-requests-button"
      onClick={() => {
        dispatch(
          dispatcherSlice.actions.onRejectUnassignedRequestedTrips({
            tripIdsToReject:
              selectedUnassignedRequestedTrips?.map((trip) => trip.id) || [],
          })
        );
      }}
    >
      <span className="text-xs">Reject Trips</span>
    </Button>
  );
}
