import { useMemo } from 'react';

import { ClockIcon } from '@heroicons/react/24/outline';
import { get } from 'lodash-es';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { Combobox } from 'src/common/primitives/ComboBox';
import { StyledOption } from 'src/common/primitives/ComboBox/StyledOption';
import { useFormValues } from 'src/common/useFormValues';
import { useUserRoles } from 'src/features/auth/useUserRoles';
import { useIsRideOrderingPortalEnabledForOrganization } from 'src/features/rideOrderingPortal/useIsRideOrderingPortalEnabledForOrganization';

import { addATrip } from '../addATrip.slice';
import { useIsEditingTrip } from '../useIsEditingTrip';
import { BrokerPayerFields } from './BrokerPayerFields';
import { InsurancePayerFields } from './InsurancePayerFields';
import { PassengerPayerLinkSwitch } from './PassengerPayerLinkSwitch';
import { pricingNameRoot } from './pricingNameRoot';
import { usePayers } from './usePayers';

export const payerCategories = {
  broker: 'broker',
  private: 'private',
  insurance: 'insurance',
};

export function PricingFormBody({
  showPayerLinkSwitch = true,
}: {
  showPayerLinkSwitch?: boolean;
}) {
  const isRideOrderingPortalEnabledForOrganization =
    useIsRideOrderingPortalEnabledForOrganization();
  const { isRideOrderingPortalUser } = useUserRoles();
  const shouldShowPayerLinkSwitch =
    isRideOrderingPortalEnabledForOrganization &&
    !isRideOrderingPortalUser &&
    showPayerLinkSwitch;

  const dispatch = useAppDispatch();
  const { selectedPassenger } = useAppSelector((state) => state.addATrip);
  const isEditingTrip = useIsEditingTrip();
  const {
    payerOptions,
    setPassengerSearchTerm,
    debounceSearch,
    payers,
    recentPayers,
    isLoading,
  } = usePayers({
    selectedPassengerId: selectedPassenger?.id,
    refetchOnMountOrArgChange: true,
  });

  const currentFormValues = useFormValues();

  const payerCategory = useMemo(() => {
    const payer = get(currentFormValues, `${pricingNameRoot}.payer`);
    return payers?.results.find((result) => result.id === payer)?.payer_type;
  }, [payers, currentFormValues]);

  return (
    <div className="flex flex-wrap items-center justify-between gap-3">
      <div className="grow">
        <FormFieldController
          name={`${pricingNameRoot}.payer`}
          rules={{ required: 'Please choose a payer' }}
        >
          {({ field, fieldState }) => {
            return (
              <DefaultFormFieldLayout
                label={`Payer * ${isLoading ? '(Loading...)' : ''}`}
                error={fieldState.error?.message?.toString()}
                inputProps={{ id: `${pricingNameRoot}.payer` }}
              >
                <Combobox
                  value={
                    field.value
                      ? payerOptions.find(
                          (option) => option.value === field.value
                        )
                      : undefined
                  }
                  placeholder="Select a payer"
                  options={payerOptions}
                  onChange={(selected) => {
                    const selectedPayer = payers?.results.find(
                      (x) => x.id === selected.value
                    );
                    dispatch(addATrip.actions.selectPayer(selectedPayer));
                    field.onChange(selected.value);
                  }}
                  dataTestId="payer-combobox"
                  onQueryChange={debounceSearch}
                  onButtonClick={() => setPassengerSearchTerm('')}
                  renderOption={(option, selected, active) => {
                    const recentAddress = recentPayers?.results.find(
                      (payer) => payer.payer_organization.id === option.value
                    );

                    return (
                      <StyledOption
                        key={option.value}
                        option={option}
                        selected={selected}
                        active={active}
                        startAddornment={
                          !!recentAddress && (
                            <span>
                              <ClockIcon className="h-4 w-4" />{' '}
                            </span>
                          )
                        }
                      />
                    );
                  }}
                  error={fieldState.error?.message?.toString()}
                  disabled={isLoading}
                />
              </DefaultFormFieldLayout>
            );
          }}
        </FormFieldController>
      </div>
      {payerCategory === 'broker' ? <BrokerPayerFields /> : null}
      {payerCategory === 'insurance' ? <InsurancePayerFields /> : null}
      {shouldShowPayerLinkSwitch && (
        <PassengerPayerLinkSwitch disabled={isEditingTrip} />
      )}
    </div>
  );
}
