import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch } from 'src/app/store';

import VehicleFilter from './VehicleFilter';

interface VehicleColumnFilterProps {
  open: boolean;
  onClose: () => void;
  selected: string[];
  setSelectedAction: ActionCreatorWithPayload<string[], string>;
}

export function VehicleColumnFilter({
  open,
  onClose,
  selected,
  setSelectedAction,
}: VehicleColumnFilterProps) {
  const dispatch = useAppDispatch();

  return (
    <VehicleFilter
      open={open}
      selected={selected}
      triggerElement={<div />}
      onApply={(vehicles) => {
        dispatch(setSelectedAction(vehicles));
      }}
      onClose={onClose}
    />
  );
}
