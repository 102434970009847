import { CheckedState } from '@radix-ui/react-checkbox';

import { CheckBox } from '../primitives/CheckBox';
import { FormFieldProps } from './FormField';
import { getHelperTextProps, HelperTextPopover } from './FormFieldHelperText';

export function FormFieldCheckbox<T>({
  inputProps,
  label,
  helperText,
  value,
  onChange = (checked: CheckedState) => {},
  className = '',
  disabled = false,
}: FormFieldProps<T>) {
  const { popoverProps } = getHelperTextProps(helperText);
  return (
    <>
      <div className={'flex items-center gap-2 ' + className}>
        <div>
          <CheckBox
            id={inputProps.id}
            checked={value as CheckedState}
            onCheckedChange={onChange}
            disabled={disabled}
          />
        </div>
        <label
          htmlFor={inputProps.id}
          className="text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        {popoverProps && <HelperTextPopover {...popoverProps} />}
      </div>
    </>
  );
}
