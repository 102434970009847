import { PropsWithChildren } from 'react';

import { useFeature } from 'src/app/FeatureToggle';
import { useAppSelector } from 'src/app/store';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { DropdownSubmenu } from 'src/common/DropdownMenu/DropdownSubmenu';
import {
  DispatchTripRead,
  useAssignmentsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { usePayers } from 'src/features/add-trip/Pricing/usePayers';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { useTripMenuActions } from '../DispatchCalendar/useTripMenuActions';
import AddEditDispatchNote from './AddEditDispatchNote';
import { AddEditNote } from './AddEditNote';
import { getPotentialMultiloadTripIds } from './DriverAssignmentMenu/getPotentialMultiloadTripIds';
import { CancellationMenu } from './TripDropdownCancellationMenu';

interface MenuContentProps extends PropsWithChildren {
  trip: DispatchTripRead;
}

/**
 * Any custom menu items for a trip dropdown menu should be passed as children
 */
export function TripDropdownSharedMenu({ children, trip }: MenuContentProps) {
  const actions = useTripMenuActions();
  const { isEnabled: isMultiLoadingEnabled } = useFeature('multi-loading');
  const { isRideOrderingPortalUser } = useUserRoles();
  return (
    <div>
      <ViewEditItem actions={actions} trip={trip} />
      <CancellationMenu trip={trip} />
      {trip.is_non_activated_will_call && (
        <ActivateWillCallItem trip={trip} actions={actions} />
      )}
      {children}
      <DispatcherNotesItem trip={trip} />
      <DriverNotesItem trip={trip} actions={actions} />
      <TripSummaryItem trip={trip} actions={actions} />
      {trip.status === 'requested' && (
        <RequestedTripMenuItems trip={trip} actions={actions} />
      )}
      {isMultiLoadingEnabled && trip.assignment_id && !trip.multiload_id && (
        <CreateMultiLoadedTripMenuItem trip={trip} actions={actions} />
      )}
      {isMultiLoadingEnabled && trip.assignment_id && trip.multiload_id && (
        <MultiLoadedTripMenuItems trip={trip} actions={actions} />
      )}
      {!isRideOrderingPortalUser && (
        <DropdownMenuItem
          onClick={() => {
            actions.addEditTags(trip.id);
          }}
        >
          Add Tags
        </DropdownMenuItem>
      )}
    </div>
  );
}

interface ItemProps {
  trip: DispatchTripRead;
  actions: ReturnType<typeof useTripMenuActions>;
}

function ViewEditItem({ actions, trip }: ItemProps) {
  return (
    <DropdownMenuItem onClick={() => actions.viewEdit(trip.id)}>
      View/Edit
    </DropdownMenuItem>
  );
}

function ActivateWillCallItem({ actions, trip }: ItemProps) {
  return (
    <DropdownMenuItem
      onClick={() => actions.activateWillCall({ tripId: trip.id })}
    >
      Activate will call
    </DropdownMenuItem>
  );
}

function DispatcherNotesItem({ trip }: { trip: DispatchTripRead }) {
  return (
    <DropdownSubmenu triggerText="Add/Edit Dispatcher Notes">
      <AddEditDispatchNote trip={trip} />
    </DropdownSubmenu>
  );
}

function DriverNotesItem({ trip, actions }: ItemProps) {
  return (
    <DropdownSubmenu triggerText="Add/Edit Driver Notes">
      <DropdownSubmenu triggerText="Pick Up Notes">
        <AddEditNote
          label="Pick Up Notes"
          initialNote={trip.pickup_driver_notes}
          onSave={(note) => actions.updateDriverNotes(trip.id, note, 'pickup')}
        />
      </DropdownSubmenu>
      <DropdownSubmenu triggerText="Drop Off Notes">
        <AddEditNote
          label="Drop Off Notes"
          initialNote={trip.dropoff_driver_notes}
          onSave={(note) => actions.updateDriverNotes(trip.id, note, 'dropoff')}
        />
      </DropdownSubmenu>
    </DropdownSubmenu>
  );
}

function TripSummaryItem({ trip, actions }: ItemProps) {
  return (
    <DropdownMenuItem onClick={() => actions.goToTripSummary(trip.id)}>
      View Summary
    </DropdownMenuItem>
  );
}

function RequestedTripMenuItems({ trip, actions }: ItemProps) {
  return (
    <>
      <DropdownMenuItem
        onClick={() => actions.acceptMarketplaceRequest(trip.id)}
      >
        Accept
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => actions.rejectMarketplaceRequest(trip.id)}
      >
        Reject
      </DropdownMenuItem>
    </>
  );
}

function MultiLoadedTripMenuItems({ trip, actions }: ItemProps) {
  return (
    <DropdownSubmenu triggerText="Multi-load">
      <DropdownMenuItem
        onClick={() => {
          if (typeof trip.multiload_id === 'string' && trip.multiload_id) {
            actions.editMultiLoad(trip, trip.assignment_id);
          }
        }}
      >
        Edit multi-load
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          if (typeof trip.multiload_id === 'string' && trip.multiload_id) {
            actions.removeTripFromMultiLoad(trip.id, trip.multiload_id);
          }
        }}
      >
        Unmulti-load this trip
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          if (typeof trip.multiload_id === 'string' && trip.multiload_id) {
            actions.removeMultiLoad(trip.multiload_id);
          }
        }}
      >
        Remove multi-load for all trips
      </DropdownMenuItem>
    </DropdownSubmenu>
  );
}

function CreateMultiLoadedTripMenuItem({ trip, actions }: ItemProps) {
  const assignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.assigned_trips
  );
  const { payers } = usePayers();
  const { data: assignmentData } = useAssignmentsRetrieveQuery({
    id: trip.assignment_id,
  });

  if (!assignedTrips || !assignmentData) return null;
  const potentialMultiloadTrips = getPotentialMultiloadTripIds(
    trip,
    [...assignedTrips.filter((t) => t.assignment_id === trip.assignment_id)],
    assignmentData,
    payers?.results || []
  );
  if (potentialMultiloadTrips.length === 0) return null;
  return (
    <DropdownMenuItem
      onClick={() => {
        actions.createAssignedTripMultiLoad(trip, potentialMultiloadTrips);
      }}
    >
      Create Multi-load
    </DropdownMenuItem>
  );
}
