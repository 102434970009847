import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';

import { serviceDetailsNameRoot } from './ServiceDetailsFormBody';

export function OxygenRequiredField() {
  const fieldName = `${serviceDetailsNameRoot}.is_oxygen_required`;
  return (
    <FormFieldController name={fieldName}>
      {({ field, fieldState }) => {
        return (
          <FormField
            label="Oxygen required?"
            error={fieldState.error?.message?.toString()}
            type="switch"
            inputProps={{
              id: fieldName,
              ...field,
            }}
          />
        );
      }}
    </FormFieldController>
  );
}
