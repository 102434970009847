import { useState } from 'react';

import {
  useVehiclesListQuery,
  VehicleRead,
} from 'src/common/external/bambi-api/bambiApi';
import { FilterPopoverProps } from 'src/common/primitives/FilterPopover/FilterPopover';
import { FilterPopoverTrigger } from 'src/common/primitives/FilterPopover/FilterPopoverTrigger';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

// Extracts ID string from list
function unwrapVehicleList(list: VehicleRead[]): string[] {
  return list.map((p) => p.id);
}

function createIdentifiedList(list: string[]): VehicleRead[] {
  return list.map((s) => ({
    id: s,
  })) as VehicleRead[]; // Not a true conversion, but not needed
}

function renderName(vehicle: VehicleRead) {
  if (vehicle.nickname) {
    return vehicle.nickname;
  }

  if (vehicle.make && vehicle.model) {
    return `${vehicle.make} ${vehicle.model}`;
  }

  return vehicle.vin;
}

export default function VehicleFilter({
  selected,
  open,
  triggerElement,
  onApply,
  onClose,
}: {
  selected: string[];
  open?: boolean;
  triggerElement?: FilterPopoverProps['triggerElement'];
  onApply: (payers: string[]) => void;
  onClose?: () => void;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const { data: vehicleList, isLoading: payersLoading } = useVehiclesListQuery({
    search: searchTerm,
  });

  return (
    <MultiSelectFilterPopover
      open={open}
      value={createIdentifiedList(selected)}
      searchPlaceholder="Search vehicles"
      filters={vehicleList?.results ?? []}
      renderLabel={(vehicle) => renderName(vehicle)}
      triggerElement={
        triggerElement ?? (
          <FilterPopoverTrigger
            count={selected.length}
            label={
              payersLoading ? <LoadingIndicator variant="small" /> : 'Payer'
            }
          />
        )
      }
      onChange={(value) => {
        onApply(unwrapVehicleList(value));
        onClose?.();
      }}
      onSearch={(term) => {
        setSearchTerm(term);
      }}
      onCancel={onClose}
    />
  );
}
