export function RunBambiRunLoadingIndicator({ message }: { message: string }) {
  return (
    <div data-testid="loading-indicator">
      <div className="flex flex-col items-center justify-center">
        <img
          className="mx-auto max-w-2xl p-4"
          src={process.env.PUBLIC_URL + '/map-icon-loader.gif'}
          alt="Run Bambi Run loading indicator"
        />
        <p className="text-xl text-gray-900">{message}</p>
      </div>
    </div>
  );
}
