import { Controller, useForm } from 'react-hook-form';

import {
  TilledBillingDetails,
  TilledPaymentMethodResponse,
} from 'src/common/external/bambi-api/bambiApi';
import { FormFieldDefault } from 'src/common/FormField/FormFieldDefault';
import { BillingAddressForm } from 'src/common/forms/PaymentMethodCaptureForm/BillingAddressForm';
import { BillingAddress } from 'src/common/forms/PaymentMethodCaptureForm/types';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';

export type EditPaymentMethodModalProps = {
  paymentMethod: TilledPaymentMethodResponse;
  open: boolean;
  loading: boolean;
  setOpen: (open: boolean) => void;
  onCancel: () => void;
  onConfirm: (update: TilledBillingDetails) => void;
};

export function EditPaymentMethodModal({
  paymentMethod,
  open,
  loading,
  setOpen,
  onCancel,
  onConfirm,
}: EditPaymentMethodModalProps) {
  const addressValue = paymentMethod.billing_details?.address ?? {};

  const { control, handleSubmit } = useForm<TilledBillingDetails>({
    defaultValues: {
      ...paymentMethod.billing_details,
      address: addressValue,
    },
  });

  return (
    <Modal open={open} setOpen={setOpen} contentClassnames="w-[25rem]">
      <div className="flex flex-col gap-3">
        <h4 className="text-center text-lg font-medium text-gray-900">
          Edit Billing Details
        </h4>
        <div className="flex flex-col gap-1">
          <Controller
            control={control}
            name="name"
            render={({ field }) => {
              return (
                <FormFieldDefault
                  type="text"
                  inputProps={{
                    value: field.value,
                    onChange: field.onChange,
                  }}
                  label="Billing Name"
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="address"
            render={({ field }) => {
              const addressLookupPlaceholder = generateAddressPlaceholder(
                field.value as BillingAddress
              );
              return (
                <BillingAddressForm
                  addressLookupPlaceholder={addressLookupPlaceholder}
                  value={field.value as BillingAddress}
                  onChange={(addy) => {
                    field.onChange(addy);
                  }}
                />
              );
            }}
          />
        </div>

        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <Button
            dataTestId="cancelBtn"
            className="sm:col-span-3"
            disabled={loading}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className="sm:col-span-3"
            type="submit"
            variant="primary"
            onClick={handleSubmit(onConfirm)}
            disabled={loading}
            dataTestId="confirmBtn"
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function generateAddressPlaceholder(address: BillingAddress) {
  const street = [address.street, address.street2].filter((n) => !!n).join(' ');
  const placeholder = `${street ? street + ', ' : ''}${address.city}, ${
    address.state
  } ${address.zip}`;

  return placeholder;
}
