import { PatchedOrganizationSettings } from 'src/common/external/bambi-api/bambiApi';

export const defaultFormValues: PatchedOrganizationSettings = {
  headquarters_location: undefined,
  dispatch_phone_number: undefined,
  dispatch_text_number: undefined,
  tax_id: '',
  should_avoid_toll_roads: false,
  should_avoid_ferries: false,
  ambulatory_load_seconds: 0,
  ambulatory_unload_seconds: 0,
  wheelchair_load_seconds: 0,
  wheelchair_unload_seconds: 0,
  wheelchair_xl_load_seconds: 0,
  wheelchair_xl_unload_seconds: 0,
  broda_chair_load_seconds: 0,
  broda_chair_unload_seconds: 0,
  geri_chair_load_seconds: 0,
  geri_chair_unload_seconds: 0,
  stretcher_load_seconds: 0,
  stretcher_unload_seconds: 0,
  pickup_default_early_seconds: 0,
  pickup_default_late_seconds: 0,
  pickup_appointment_early_seconds: 0,
  pickup_appointment_late_seconds: 0,
  pickup_will_call_early_seconds: 0,
  pickup_will_call_late_seconds: 0,
  appointment_early_arrival_window_seconds: 0,
  standard_transport_time_start: '',
  standard_transport_time_end: '',
  requires_inspection_after_assignment_start: false,
  requires_inspection_before_assignment_end: false,
  requires_signature_pickup_passenger: false,
  requires_signature_dropoff_passenger: false,
  requires_signature_pickup_driver: false,
  requires_signature_dropoff_driver: false,
  facility_trip_request_lead_time_seconds: 0,
  cancellation_fee_window_hours: 0,
  is_pricing_v1_enabled: false,
  can_associate_cancel_trips: false,
  can_associate_activate_will_call_trips: false,
  minimum_upcoming_trips_returned: 0,
};
