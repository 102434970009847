import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  CredentialTypeRead,
  PatchedCredentialType,
  useCredentialsTypesCreateMutation,
  useCredentialsTypesPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import {
  setSelectedCredentialType,
  setSelectedCredentialTypeOption,
  setShowCredentialModal,
  setShowCredentialTypeModal,
} from '../credential.slice';
import { CredentialCategory } from '../types';
import { assignDefaultFormValues } from './assignDefaultFormValues';
import { generateCreateSuccessToast } from './generateCreateSuccessToast';
import { generateUpdateSuccessToast } from './generateUpdateSuccessToast';

export default function CredentialTypeForm({
  category,
}: {
  category: CredentialCategory;
}) {
  const dispatch = useAppDispatch();
  const {
    showCredentialTypeModal,
    selectedCredentialType,
    newCredentialTypeName,
  } = useAppSelector((state) => state.credential);
  const nextToastId = useAppSelector((state) => state.toast.nextId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const [createCredentialType] = useCredentialsTypesCreateMutation();
  const [updateCredentialType] = useCredentialsTypesPartialUpdateMutation();

  const defaultFormValues = assignDefaultFormValues(selectedCredentialType);

  const methods = useForm({
    defaultValues: defaultFormValues,
  });

  const onClose = () => {
    dispatch(setShowCredentialTypeModal(false));
    dispatch(setShowCredentialModal(true));
    dispatch(setSelectedCredentialType(undefined));
    methods.reset();
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const values = methods.getValues();

      if (!values.id) {
        const newCredentialType = await createCredentialType({
          credentialType: { ...values, category } as CredentialTypeRead,
        }).unwrap();

        dispatch(
          setSelectedCredentialTypeOption({
            label: newCredentialType.name,
            value: newCredentialType.id,
          })
        );

        dispatch(show(generateCreateSuccessToast(nextToastId)));
      } else {
        const updatedCredential = await updateCredentialType({
          id: values.id,
          patchedCredentialType: {
            ...values,
            category,
          } as PatchedCredentialType,
        }).unwrap();

        dispatch(
          setSelectedCredentialTypeOption({
            label: updatedCredential.name,
            value: updatedCredential.id,
          })
        );

        dispatch(show(generateUpdateSuccessToast(nextToastId)));
      }

      onClose();
    } catch (error) {
      const msg = formatServerError(error);
      setError(msg);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (newCredentialTypeName) {
      methods.setValue('name', newCredentialTypeName);
    }

    if (selectedCredentialType) {
      methods.reset(selectedCredentialType);
    }

    return () => {
      setError('');
    };
  }, [methods, newCredentialTypeName, selectedCredentialType]);

  return (
    <Modal
      open={showCredentialTypeModal}
      setOpen={(val) => dispatch(setShowCredentialTypeModal(val))}
      onClose={() => onClose()}
      data-testid="credential-type-modal"
    >
      <div className="w-full md:min-w-[350px]">
        <h3 className="text-[24px] font-medium leading-6 text-gray-900">
          {selectedCredentialType?.id ? 'Edit' : 'Add a New'} Credential Type
        </h3>
        {!selectedCredentialType?.id && (
          <p className="mt-1 text-xs text-gray-500">
            Create a for the new credential for{' '}
            {category === 'Team' ? 'team members' : 'vehicles'}.
          </p>
        )}
        {error && (
          <div className="my-2">
            <FormErrorMessage>{error}</FormErrorMessage>
          </div>
        )}
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="my-4 flex">
            <FormFieldController
              name="name"
              rules={{ required: 'Name is required' }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Name *"
                    error={fieldState.error?.message?.toString()}
                    type="text"
                    inputProps={{
                      id: 'credential_type_name',
                      ...field,
                      'data-testid': 'credential_type_name',
                    }}
                    value={field.value}
                  />
                );
              }}
            </FormFieldController>
          </div>
          <div className="my-4 flex">
            <FormFieldController name="description">
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Description"
                    error={fieldState.error?.message?.toString()}
                    type="textarea"
                    inputProps={{
                      id: 'credential_type_description',
                      ...field,
                      'data-testid': 'credential_type_description',
                    }}
                    value={field.value}
                  />
                );
              }}
            </FormFieldController>
          </div>
          <div className="mt-8 grid grid-cols-2 gap-4">
            <Button
              onClick={() => {
                onClose();
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="w-full flex-grow"
              variant="primary"
              disabled={isSubmitting}
              dataTestId="save-credential-type-button"
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}
