import { useAppSelector } from 'src/app/store';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { setUnassignedTripsSearchTerm } from '../dispatcher.slice';

export function UnassignedTripSearch() {
  const unassignedTripsSearchTerm = useAppSelector(
    (state) => state.dispatcher.unassignedTripsSearchTerm
  );

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setUnassignedTripsSearchTerm,
    unassignedTripsSearchTerm
  );

  return (
    <>
      <SearchTypeahead
        placeholder="Search unassigned trips"
        onChange={handleInputChange}
        value={inputValue}
      />
    </>
  );
}
