import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { Badge } from 'src/common/primitives/Badge';

export const EstimatedRevenueBadge = ({ revenue }: { revenue: number }) => {
  return (
    <div className="ml-1" data-testid="assignment-estimated-revenue">
      <Badge label={defaultCurrencyFormat(revenue, false)} color="green" />
    </div>
  );
};
