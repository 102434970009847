import { CheckboxProps } from '@radix-ui/react-checkbox';

import {
  DispatchTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { CheckBox } from 'src/common/primitives/CheckBox';

import { TripCard } from './TripCard/TripCard';

type TripListItemProps = {
  trip: DispatchTripRead | RunBambiRunTripRead | TripRead;
  hideMenu?: boolean;
} & CheckboxProps;

export function TripListItem({
  trip,
  checked,
  onCheckedChange,
  disabled,
  hideMenu = false,
}: TripListItemProps) {
  return (
    <div
      className="flex items-center justify-between gap-x-3"
      data-testid={`trip-list-item-${trip.id}`}
    >
      <div>
        <CheckBox
          id={`trip-selection-checkbox-${trip.id}`}
          checked={checked}
          onCheckedChange={(val) => onCheckedChange?.(val)}
          disabled={disabled}
        />
      </div>
      <TripCard trip={trip} hideMenu={hideMenu} />
    </div>
  );
}
