import { useEffect } from 'react';

import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

import { convertISODateTimePartsToUTCISO } from 'src/common/date/convertISODateTimePartsToUTCISO';
import {
  useOrganizationSettingsRetrieveQuery,
  useTripsSuggestedPickupAtCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { useIsSelectedTripInThePast } from 'src/features/add-trip/useIsSelectedTripInThePast';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { validatePickupTimeField } from './validatePickupTimeField';

export function PickupTimeContextField({
  fieldPath,
  index,
}: {
  fieldPath: string;
  index: number;
}) {
  const form = useFormContext();
  const [calculatePickupTime] = useTripsSuggestedPickupAtCreateMutation();
  const isSelectedTripInThePast = useIsSelectedTripInThePast();
  const { isRideOrderingPortalUser } = useUserRoles();
  const { data: orgSettings } = useOrganizationSettingsRetrieveQuery(
    {},
    {
      skip: !isRideOrderingPortalUser,
    }
  );

  const date = form.watch(`${fieldPath}.date`);
  const appointmentTime = form.watch(`${fieldPath}.appointment_time`);
  const pickup = form.watch(`trips.${index}.pickupLocation.address`);
  const dropoff = form.watch(`trips.${index}.dropoffLocation.address`);
  const spaceTypeEnum =
    form.watch(`service_details.space_type`) || 'wheelchair';
  const isWillCall = form.watch(`trips.${index}.timing.is_will_call`);
  const pickupTime = form.watch(`${fieldPath}.pickup_time`);
  const loadTime = form.watch(`trips.${index}.load_time_seconds`);
  const unloadTime = form.watch(`trips.${index}.unload_time_seconds`);

  useEffect(() => {
    if (isSelectedTripInThePast) return;

    async function fetchSuggestedPickupTime() {
      if (
        date &&
        appointmentTime &&
        pickup.value &&
        dropoff.value &&
        // we were explicitly told not to overwrite the pickup time if someone enters an appointment time afterwards
        !pickupTime
      ) {
        const appointmentDateTime = convertISODateTimePartsToUTCISO({
          date,
          time: appointmentTime,
        });

        return await calculatePickupTime({
          tripSuggestedPickupTime: {
            appointment_at: appointmentDateTime,
            pickup_aws_place_id: pickup.value,
            dropoff_aws_place_id: dropoff.value,
            space_type: spaceTypeEnum,
            // Don't send these values if 0, null, or undefined
            load_time_seconds: loadTime || undefined,
            unload_time_seconds: unloadTime || undefined,
          },
        }).unwrap();
      }
    }
    fetchSuggestedPickupTime()
      .then((res) => {
        if (res && res.pickup_at) {
          const time = DateTime.fromISO(res.pickup_at)
            .toLocal()
            .toFormat('HH:mm');

          form.setValue(`${fieldPath}.pickup_time`, time);
          form.clearErrors(`${fieldPath}.pickup_time`);
        }
      })
      .catch((err) => {
        //TODO: handle error or fail silently?
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appointmentTime,
    pickup,
    dropoff,
    spaceTypeEnum,
    calculatePickupTime,
    date,
    loadTime,
    unloadTime,
  ]);

  return (
    <FormFieldController
      name={`${fieldPath}.pickup_time`}
      rules={{
        validate: (value: any) => {
          return validatePickupTimeField(
            value,
            !!isRideOrderingPortalUser,
            date,
            appointmentTime,
            orgSettings?.facility_trip_request_lead_time_seconds
          );
        },
      }}
    >
      {({ field, fieldState }) => {
        const label = `${isWillCall ? 'Estimated ' : ''}Pickup Time *`;
        return (
          <FormField
            label={label}
            error={fieldState.error?.message?.toString()}
            disabled={isSelectedTripInThePast}
            inputProps={{
              id: `${fieldPath}.pickup_time`,
              ...field,
              onChange: field.onChange,
              required: true,
            }}
            {...field}
            type="time"
          />
        );
      }}
    </FormFieldController>
  );
}
