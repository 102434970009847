import { DateTime } from 'luxon';

import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { useIsSelectedTripInThePast } from 'src/features/add-trip/useIsSelectedTripInThePast';

import { validateTripDate } from './validateTripDate';
import { validateTripDateOnEditingTripInThePast } from './validateTripDateOnEditingTripInThePast';

export function TripDateContextField({ fieldPath }: { fieldPath: string }) {
  const isSelectedTripInThePast = useIsSelectedTripInThePast();
  return (
    <FormFieldController
      name={`${fieldPath}.date`}
      rules={{
        validate: isSelectedTripInThePast
          ? validateTripDateOnEditingTripInThePast
          : validateTripDate,
      }}
    >
      {({ field, fieldState }) => {
        return (
          <FormField
            label="Trip Date *"
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: `${fieldPath}.date`,
              ...field,
              onChange: field.onChange,
              min: DateTime.local().toISODate(),
              disabled: isSelectedTripInThePast,
            }}
            {...field}
            type="date"
          />
        );
      }}
    </FormFieldController>
  );
}
