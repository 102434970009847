import { forwardRef } from 'react';

import { TextInput } from 'src/common/primitives/TextInput';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';

export const FormFieldDefault = forwardRef<
  HTMLInputElement,
  FormFieldProps<any>
>(function FormFieldDefault<T>(
  {
    inputProps,
    label,
    error,
    type,
    helperText,
    className,
    inline,
  }: FormFieldProps<T>,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <DefaultFormFieldLayout
      label={label}
      error={error}
      inputProps={inputProps}
      className={className}
      inline={inline}
      helperText={helperText}
    >
      <div className="relative flex items-center">
        <TextInput
          ref={ref}
          className={`block w-full rounded-md shadow-sm disabled:cursor-not-allowed disabled:text-gray-400 sm:text-sm ${
            error
              ? 'border-red-300 focus:border-red-300 focus:ring-red-500'
              : 'focus:border-indigo-500 focus:ring-indigo-500 border-gray-300'
          }`}
          type={type}
          {...inputProps}
          data-testid={inputProps.id}
        />
      </div>
    </DefaultFormFieldLayout>
  );
});
