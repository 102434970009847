import * as z from 'zod';

export const schema = z.object({
  first_name: z.string().min(1, { message: 'Invalid first name' }),
  last_name: z.string().min(1, { message: 'Invalid last name' }),
  email: z
    .string()
    .email({ message: 'Invalid email' })
    .optional()
    .or(z.literal('')),
  phone_number: z.string().optional().or(z.literal('')),
  notes: z.string().optional().or(z.literal('')),
  dispatcher_notes: z.string().optional().or(z.literal('')),
  dob: z.string().optional().or(z.literal('')),
  needs_bariatric_transport: z.boolean(),
  gender: z.string().optional().or(z.literal('')),
  weight: z.number(),
  billing_details: z
    .object({
      city: z.string().optional().or(z.literal('')),
      country: z.string().optional().or(z.literal('')),
      zip: z.string().optional().or(z.literal('')),
      state: z.string().optional().or(z.literal('')),
      street: z.string().optional().or(z.literal('')),
      street2: z.string().optional().or(z.literal('')),
      billingName: z.string().optional(),
    })
    .optional(),
  payer_ids: z.array(z.string()).optional(),
  allow_sms_message: z.boolean(),
});
