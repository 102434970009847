import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { useRunBambiRunRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';

import { RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES } from '../common/RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES';
import { useDispatchRepository } from '../common/useDispatchRepository';
import { DispatchCalendar } from '../DispatchCalendar';
import { createEventsFromRunBambiRunResult } from '../DispatchCalendar/createEventsFromRunBambiRunResult';
import { createInvalidsFromAssignments } from '../DispatchCalendar/createInvalidsFromAssignments';
import { createResourcesFromAssignments } from '../DispatchCalendar/createResourcesFromAssignments';
import { dispatcherSlice } from '../dispatcher.slice';
import { DispatchToolbar } from '../DispatchToolbar';
import { RunBambiRunLoadingIndicator } from './RunBambiRunLoadingIndicator';
import { RunBambiRunReview } from './RunBambiRunReview';

const ONE_SECOND_IN_MS = 1000;

export function RunBambiRunRoute() {
  const { rbrJobId } = useParams();
  const dispatch = useAppDispatch();
  const isRunBambiRunInProgress = useAppSelector(
    (state) => state.dispatcher.isRunBambiRunInProgress
  );
  const runBambiRunResult = useAppSelector(
    (state) => state.dispatcher.runBambiRunResult
  );
  const dispatchResponse = useAppSelector(
    (state) => state.dispatcher.dispatchResponse
  );
  const isRunBambiRunApplyInProgress = useAppSelector(
    (state) => state.dispatcher.isRunBambiRunApplyInProgress
  );
  const tripAssignmentsToApplyIds = useAppSelector(
    (state) => state.dispatcher.tripAssignmentsToApplyIds
  );
  const isDispatchSidebarOpen = useAppSelector(
    (state) => state.dispatcher.isDispatchSidebarOpen
  );
  const [shouldNotPoll, setShouldNotPoll] = useState(true);

  const { isLoading: dispatchLoading } = useDispatchRepository({
    skip: !!dispatchResponse,
  });

  // Probably the configuration here, but isSuccess is never true
  // The state just goes back to uninitialized
  // Which kinda makes sense in that it's polling?
  // Rather than spend time figuring it out I'm just using
  // dispatcher slice state, which is more what we're interested in anyway
  const { data: rbrRequest } = useRunBambiRunRetrieveQuery(
    {
      id: rbrJobId || '',
    },
    {
      skip: shouldNotPoll,
      pollingInterval: ONE_SECOND_IN_MS,
    }
  );

  useEffect(() => {
    if (runBambiRunResult) {
      setShouldNotPoll(true);
    }
  }, [runBambiRunResult]);

  useEffect(() => {
    setShouldNotPoll(false);
    dispatch(dispatcherSlice.actions.runBambiRunRouteInit());
  }, [dispatch]);

  const dispatchAssignments = dispatchResponse?.assignments || [];
  const resources = createResourcesFromAssignments(dispatchAssignments);
  const invalids = createInvalidsFromAssignments(dispatchAssignments);

  const events = (
    runBambiRunResult
      ? createEventsFromRunBambiRunResult(runBambiRunResult)
      : []
  ).filter((event) => {
    return (
      event.type === RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.EXISTING ||
      event.type === RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.WAIT_TIME ||
      !!tripAssignmentsToApplyIds[event.trip.id]
    );
  });

  return (
    <>
      {isRunBambiRunInProgress ||
      isRunBambiRunApplyInProgress ||
      dispatchLoading ? (
        <div className="isolate flex h-[85vh] max-h-full max-w-full items-center justify-center">
          <RunBambiRunLoadingIndicator
            message={rbrRequest?.message || 'Loading'}
          />
        </div>
      ) : (
        <div className="grid sm:grid-cols-12">
          {isDispatchSidebarOpen && (
            <div className="col-span-3">
              <DispatchToolbar>
                <RunBambiRunReview />
              </DispatchToolbar>
            </div>
          )}
          <div
            className={isDispatchSidebarOpen ? 'col-span-9 ' : 'col-span-12'}
          >
            <DispatchCalendar
              resources={resources}
              invalids={invalids}
              events={events}
            />
          </div>
        </div>
      )}
    </>
  );
}
