import { LatLong } from 'src/common/external/aws/LatLong';
import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';

export function getTripPickupLatLng(
  trip:
    | DispatchTripRead
    | RunBambiRunTripRead
    | RunBambiRunReassignedTripRead
    | TripRead
): LatLong {
  if ('pickup_latitude' in trip) {
    return {
      lat: trip.pickup_latitude,
      long: trip.pickup_longitude,
    };
  }

  if (!('pickup' in trip)) {
    throw new Error('Got an unexpected trip type');
  }

  return {
    lat: trip.pickup.latitude,
    long: trip.pickup.longitude,
  };
}
