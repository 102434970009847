import { PropsWithChildren } from 'react';

import {
  DispatchAssignmentRead,
  DriverCallRequestRead,
} from 'src/common/external/bambi-api/bambiApi';
import { formatFullName } from 'src/common/util/formatFullName';

import AssignmentAttendantsToolTip from '../AssignmentAttendantsToolTip';
import { AssignmentStatusBadge } from '../AssignmentStatusBadge';
import { EstimatedRevenueBadge } from '../EstimatedRevenueBadge';
import { AssignmentHeaderMenu } from './AssignmentHeaderMenu';

type AssignmentHeaderProps = {
  assignment: DispatchAssignmentRead;
  numAttendants?: number;
  showCondensed: boolean;
};

export const AssignmentHeader = ({
  assignment,
  showCondensed: isWidthSmall,
}: AssignmentHeaderProps) => {
  const pendingCallRequest = findPendingCallRequest(assignment);

  return (
    <HeaderBackground callAttention={!!pendingCallRequest}>
      <div
        className="relative flex w-full flex-row items-center justify-between"
        data-test-id={`assignment-header-${assignment.id}`}
        id={`assignment-header-${assignment.id}`}
      >
        <div
          className={`text-light text-primary relative flex items-center gap-1 truncate pl-[1px] text-left ${
            isWidthSmall && 'text-xs'
          }`}
          title={formatFullName(assignment.driver)}
        >
          {isWidthSmall ? (
            <div className="flex max-w-full flex-col gap-1">
              <div>
                <div className="truncate">{assignment.driver.first_name}</div>
                <div className="truncate">{assignment.driver.last_name}</div>
              </div>
              <div className="flex items-center gap-1">
                <AssignmentStatusBadge status={assignment.status} />
                {!!assignment.attendants.length && (
                  <AssignmentAttendantsToolTip assignment={assignment} />
                )}
                <EstimatedRevenueBadge revenue={assignment.estimated_revenue} />
              </div>
            </div>
          ) : (
            <div className="flex max-w-full items-center gap-1">
              <div className="flex-grow">
                <AssignmentStatusBadge status={assignment.status} />
              </div>
              <div className="flex-shrink truncate">
                {formatFullName(assignment.driver)}
              </div>
              {!!assignment.attendants.length && (
                <AssignmentAttendantsToolTip assignment={assignment} />
              )}
              <EstimatedRevenueBadge revenue={assignment.estimated_revenue} />
            </div>
          )}
        </div>

        <div className="flex">
          <AssignmentHeaderMenu
            assignmentId={assignment.id}
            pendingCallRequest={pendingCallRequest}
          />
        </div>
      </div>
    </HeaderBackground>
  );
};

export const findPendingCallRequest = (
  assignment: DispatchAssignmentRead
): DriverCallRequestRead | undefined => {
  return assignment.driver_call_requests.find(
    (request) => !request.resolved_at && !request.canceled_at
  );
};

interface HeaderBackgroundProps extends PropsWithChildren {
  callAttention: boolean;
}
export const HeaderBackground = ({
  children,
  callAttention,
}: HeaderBackgroundProps) => {
  return (
    <div
      className={`border-l border-r border-t border-gray-200 ${
        callAttention ? 'animate-pulse bg-red-200' : 'bg-gray-50'
      }  py-2 px-1`}
    >
      {children}
    </div>
  );
};
