import { lazy, Suspense } from 'react';

import { RouteObject } from 'react-router-dom';

import { DefaultErrorElement } from 'src/app/DefaultErrorElement';
import { NotFoundChildRoute } from 'src/app/NotFoundChildRoute';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

const ReportsOverviewRoute = lazy(() =>
  import('./ReportsOverviewRoute/ReportsOverviewRoute').then((module) => ({
    default: module.ReportsOverviewRoute,
  }))
);

const DriverReportsRoute = lazy(() =>
  import('./DriverReportsRoute/DriverReportsRoute').then((module) => ({
    default: module.DriverReportsRoute,
  }))
);

const PayerReportsRoute = lazy(() =>
  import('./PayerReportsRoute/PayerReportsRoute').then((module) => ({
    default: module.PayerReportsRoute,
  }))
);

export const reportsRoutes: RouteObject[] = [
  {
    index: true,
    element: (
      <Suspense fallback={<LoadingIndicator />}>
        <ReportsOverviewRoute />
      </Suspense>
    ),
    errorElement: <DefaultErrorElement />,
  },
  {
    path: 'drivers',
    element: (
      <Suspense fallback={<LoadingIndicator />}>
        <DriverReportsRoute />
      </Suspense>
    ),
  },
  {
    path: 'payers',
    element: (
      <Suspense fallback={<LoadingIndicator />}>
        <PayerReportsRoute />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <NotFoundChildRoute routePath="/reports" linkText="Go back to reports" />
    ),
  },
];
