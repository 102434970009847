import { truncate } from 'lodash-es';

import { useOrganizationSettingsRetrieveQuery } from './external/bambi-api/bambiApi';
import { removeUnitedStatesFromAddress } from './util/removeUnitedStatesFromAddress';

const BASE_URL = 'https://share.here.com/';

function getHereMapsLatLongParams(latitude: number, longitude: number) {
  return latitude + ',' + longitude;
}

type PATH_TYPE = 'location' | 'route';

const pathTypeMap: Record<PATH_TYPE, string> = {
  location: 'l',
  route: 'r',
};

function createMapsHref(
  type: PATH_TYPE,
  addresses: Location[],
  shouldAvoidTollRoads: boolean,
  shouldAvoidFerries: boolean
): string {
  const base = `${BASE_URL}${pathTypeMap[type]}/`;
  const locationParams = addresses
    .map(({ latitude, longitude }) =>
      getHereMapsLatLongParams(latitude, longitude)
    )
    .join('/');

  const avoidParams = [
    shouldAvoidTollRoads && 'tollroad',
    shouldAvoidFerries && 'boatFerry',
  ]
    .filter(Boolean)
    .join(',');

  const queryString = avoidParams ? `?a=${avoidParams}` : '';

  return `${base}${locationParams}${queryString}`;
}

export function formatHereMapsAddress(address: string) {
  const formattedAddress = removeUnitedStatesFromAddress(address);

  return truncate(formattedAddress, {
    length: 50,
    omission: '...',
  });
}

type Location = {
  latitude: number;
  longitude: number;
};

type HereMapsAddressLinkProps = {
  addresses: Location[];
  className?: string;
  children: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export function HereMapsAddressLink({
  addresses,
  className,
  children,
  ...props
}: HereMapsAddressLinkProps) {
  const { data: orgSettings, isLoading: isOrgSettingsLoading } =
    useOrganizationSettingsRetrieveQuery({});

  const linkClass = className
    ? className
    : 'font-medium text-gray-500 hover:underline dark:text-gray-400';

  const href = createMapsHref(
    addresses.length === 1 ? 'location' : 'route',
    addresses,
    orgSettings?.should_avoid_toll_roads ?? false,
    orgSettings?.should_avoid_ferries ?? false
  );

  if (isOrgSettingsLoading) {
    return <div className="linkClass">Loading...</div>;
  }

  return (
    <a
      {...props}
      href={href}
      className={linkClass}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}
