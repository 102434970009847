import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import {
  PatchedOrganizationSettings,
  useOrganizationSettingsPartialUpdateMutation,
  useOrganizationSettingsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { assignDefaultFormValues } from './assignDefaultFormValues';
import BufferTimeSettings from './BufferTimeSettings';
import ExportSettings from './ExportSettings';
import { formValuesToPatchedOrganization } from './formValuesToPatchedOrganization';
import { generateErrorToast } from './generateErrorToast';
import { generateSuccessToast } from './generateSuccessToast';
import LoadUnloadSettings from './LoadUnloadSettings';
import LocationSettings from './LocationSettings';
import { MobileAppSettings } from './MobileAppSettings';
import PermissionSettings from './PermissionSettings';
import PortalSettings from './PortalSettings';
import PricingSettings from './PricingSettings';
import SignatureSettings from './SignatureSettings';
import TransportationSettings from './TransportationSettings';
import { TripSettings } from './TripSettings';
import VehicleInspections from './VehicleInspections';

export default function OrganizationSettingsForm() {
  const dispatch = useDispatch();
  const nextToastId = useSelector((state: RootState) => state.toast.nextId);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: organizationSettings,
    isLoading: isLoadingOrganizationSettings,
  } = useOrganizationSettingsRetrieveQuery({});

  const [saveOrganizationSettings] =
    useOrganizationSettingsPartialUpdateMutation();

  const methods = useForm();

  const submit = async () => {
    setIsSubmitting(true);
    const values = methods.getValues();

    // Save logo separately because we need to construct a form data
    try {
      // Check for null or file
      if ('logo' in values && (values.logo === null || !!values.logo)) {
        const formData = new FormData();
        formData.append('logo', values.logo === null ? '' : values.logo);

        await saveOrganizationSettings({
          patchedOrganizationSettings:
            formData as unknown as PatchedOrganizationSettings,
        }).unwrap();
        dispatch(
          show({
            title: `Success`,
            description: 'The logo has been updated.',
            type: 'success',
          })
        );
      }
    } catch (e) {
      dispatch(
        show({
          title: `Failure`,
          description: `Unable to upload logo: ${formatServerError(e)}`,
          type: 'error',
        })
      );
    }

    // Save remaining data
    try {
      const patchedOrganizationSettings =
        formValuesToPatchedOrganization(values);

      await saveOrganizationSettings({
        patchedOrganizationSettings,
      }).unwrap();
      dispatch(show(generateSuccessToast(nextToastId)));
    } catch {
      dispatch(show(generateErrorToast(nextToastId)));
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    methods.reset(assignDefaultFormValues(organizationSettings));
  }, [methods, organizationSettings]);

  return (
    <>
      {isLoadingOrganizationSettings ? (
        <div className="flex h-[80vh] flex-col items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <FormProvider {...methods}>
          <div className="my-4 space-y-4">
            <form onSubmit={methods.handleSubmit(submit)}>
              <LocationSettings />
              <ExportSettings />
              <TransportationSettings />
              <SignatureSettings />
              <VehicleInspections />
              <PermissionSettings />
              <LoadUnloadSettings />
              <BufferTimeSettings />
              <TripSettings />
              <PortalSettings />
              <PricingSettings />
              <MobileAppSettings />
              <div className="flex justify-end pt-4">
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting
                    ? 'Saving Organization Settings...'
                    : 'Save Organization Settings'}
                </Button>
              </div>
            </form>
          </div>
        </FormProvider>
      )}
    </>
  );
}
