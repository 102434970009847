import { upperFirst } from 'lodash-es';

import {
  TilledBillingDetails,
  TilledCard,
} from 'src/common/external/bambi-api/bambiApi';
import { renderAddress } from 'src/common/formatAddress';
import { SnakeToCamelCaseNested } from 'src/common/util/types';

import {
  CreditCard,
  CreditCardBrand,
} from '../icon/CreditCardBrand/CreditCardBrand';

interface CreditCardPaymentMethodProps
  extends SnakeToCamelCaseNested<TilledCard> {
  id: string;
  brand: CreditCardBrand;
  billingDetails?: TilledBillingDetails | null;
}

export function CreditCardPaymentMethod({
  brand,
  last4,
  expMonth,
  expYear,
  holderName,
  id,
  billingDetails,
}: CreditCardPaymentMethodProps) {
  // BillingName can become different than card holder name if its updated
  // after creation.
  const billingName = billingDetails?.name;
  const nameOnCard = holderName;
  const holderNameToUse =
    billingName && billingName !== nameOnCard ? billingName : nameOnCard;

  return (
    <div
      className="flex items-start gap-2"
      data-testid={`payment-method-id-${id}`}
    >
      <CreditCard brand={brand} />
      {holderName === REMOVED_CARD ? (
        <div className="text-sm">
          <strong>{holderName}</strong>
        </div>
      ) : (
        <div className="text-sm">
          <div>
            <strong>{holderNameToUse}</strong>
            <span>
              &nbsp;({upperFirst(brand)} ending in {last4})
            </span>
          </div>
          <div data-testid={`payment-expiration-for-id-${id}`}>
            Expiry {expMonth}/{expYear}
          </div>
          {
            <div data-testid={`billing-details-${id}`}>
              {renderAddress({
                addressLine1: billingDetails?.address?.street ?? '',
                addressLine2: billingDetails?.address?.street2 ?? '',
                placeName: billingDetails?.address?.city ?? '',
                stateAbbreviation: billingDetails?.address?.state ?? '',
                zipCode: billingDetails?.address?.zip ?? '',
              })}
            </div>
          }
        </div>
      )}
    </div>
  );
}

export const REMOVED_CARD = 'Removed Payment Method';
