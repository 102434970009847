import { TripLocationDetailRead } from 'src/common/external/bambi-api/bambiApi';

import { PhoneNumberLink } from '../PhoneNumberLink';

interface LocationContactProps {
  tripLocation?: TripLocationDetailRead;
}

/**
 * This component displays the name and/or phone number.
 * If no data, it returns null.
 */
export const LocationContact = ({ tripLocation }: LocationContactProps) => {
  if (!tripLocation) {
    return null;
  }
  const props = getLocationContactProps(tripLocation);
  if (!props) {
    return null;
  }
  return (
    <div className="text-sm">
      <h5 className="text-sm font-medium text-gray-500">Contact</h5>
      <p>
        {props.name}
        <PhoneNumberLink phoneNumber={props.phoneNumber} />
      </p>
    </div>
  );
};

const getLocationContactProps = ({
  contact_name,
  contact_phone_number,
}: TripLocationDetailRead) => {
  if (!contact_name && !contact_phone_number) {
    return null;
  }

  return {
    name: contact_name,
    phoneNumber: contact_phone_number,
  };
};
