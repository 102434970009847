import { Duration } from 'luxon';

import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { getTripDropoffLatLng } from 'src/common/external/bambi-api/wrappers/trip/getTripDropoffLatLng';
import { getTripPickupAddress } from 'src/common/external/bambi-api/wrappers/trip/getTripPickupAddress';
import { getTripPickupLatLng } from 'src/common/external/bambi-api/wrappers/trip/getTripPickupLatLng';
import {
  formatHereMapsAddress,
  HereMapsAddressLink,
} from 'src/common/HereMapsAddressLink';
import { Badge } from 'src/common/primitives/Badge';
import { Skeleton } from 'src/common/Skeleton';

import { formatSimpleTime } from '../formatSimpleTime';
import { LocationContact } from './LocationContact';

interface TripDetailsPickupProps {
  isTripDataLoading: boolean;
  tripData?: TripRead;
  trip:
    | DispatchTripRead
    | RunBambiRunTripRead
    | RunBambiRunReassignedTripRead
    | TripRead;
}

export function TripDetailsPickup({
  isTripDataLoading,
  trip,
  tripData,
}: TripDetailsPickupProps) {
  const tripPickupLatLng = getTripPickupLatLng(trip);
  const tripDropoffLatLng = getTripDropoffLatLng(trip);
  return (
    <div className="flex flex-col gap-1 py-2">
      <h4 className="text-sm font-bold">
        Pickup - {formatSimpleTime(trip.scheduled_pickup_at)}
        {tripData?.pickup_wait_time_seconds &&
          tripData.pickup_wait_time_seconds > 0 && (
            <Badge
              color="yellow"
              label={Duration.fromObject({
                seconds: tripData.pickup_wait_time_seconds,
              }).toFormat("m'm'")}
            />
          )}
      </h4>
      <div>
        <HereMapsAddressLink
          className="text-sm font-medium text-gray-500 hover:underline"
          addresses={[
            {
              latitude: tripPickupLatLng.lat,
              longitude: tripPickupLatLng.long,
            },
            {
              latitude: tripDropoffLatLng.lat,
              longitude: tripDropoffLatLng.long,
            },
          ]}
        >
          {formatHereMapsAddress(getTripPickupAddress(trip))}
        </HereMapsAddressLink>
      </div>
      {isTripDataLoading ? (
        <Skeleton />
      ) : (
        <LocationContact tripLocation={tripData?.pickup} />
      )}
    </div>
  );
}
