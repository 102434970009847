import { useEffect, useMemo } from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { RootState, useAppDispatch, useAppSelector } from 'src/app/store';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { AssignmentManagementForm } from 'src/features/team/AssignmentManagementForm/AssignmentManagementForm';
import { BulkCancelTripModal } from 'src/features/trip/management/cancel/BulkCancelTripModal/BulkCancelTripModal';
import CancelSuggestionsModal from 'src/features/trip/management/cancel/CancelSuggestionsModal/CancelSuggestionsModal';
import CancelTripModal from 'src/features/trip/management/cancel/CancelTripModal/CancelTripModal';
import TripTagsModal from 'src/features/trip/management/TripTagModal';

import { useDispatchRepository } from '../common/useDispatchRepository';
import { DispatchCalendar } from '../DispatchCalendar';
import { useDispatchEventResources } from '../DispatchCalendar/useDispatchEventResources';
import { dispatcherSlice } from '../dispatcher.slice';
import { DispatchToolbar } from '../DispatchToolbar';
import AssignmentBreakModal from '../modals/AssignmentBreakModal/AssignmentBreakModal';
import { CallRequestModal } from '../modals/CallRequestModal';
import { ConfirmWillCallActivationModal } from '../modals/ConfirmWillCallActivationModal';
import { DeleteBreakModal } from '../modals/DeleteBreakModal';
import { TripAcceptRequestModal } from '../modals/requestedTrips/accept/TripAcceptRequestModal';
import { useAcceptTripRequestConfirmModalProps } from '../modals/requestedTrips/accept/useAcceptTripRequestConfirmModalProps';
import { TripRejectRequestModal } from '../modals/requestedTrips/reject/TripRejectRequestModal';
import { useRejectTripRequestConfirmModalProps } from '../modals/requestedTrips/reject/useRejectTripRequestConfirmModalProps';
import { RunBambiRunAssignmentSelectionStep } from '../modals/RunBambiRunAssignmentSelectionStep';
import { UnassignConfirmationModal } from '../modals/UnassignConfirmationModal';
import { MultiloadAssignmentFlow } from '../multi-loading/MultiloadAssignmentFlow';
import { AssignTripFlow } from './AssignTripFlow';
import { UnassignedTrips } from './UnassignedTrips/UnassignedTrips';

const ONE_MINUTE_IN_MS = 60000;

export function DispatchRoute() {
  const { selectedDate } = useParams();

  const dispatch = useAppDispatch();

  const {
    dispatchResponse,
    isDispatchSidebarOpen,
    isMenuActionInProgress,
    isRunBambiRunInProgress,
    previewEvents,
    runBambiRunJobId,
    selectedDate: selectedDateFromState,
    selectedTripId,
  } = useAppSelector((state: RootState) => state.dispatcher);

  const { resources, invalids, events } =
    useDispatchEventResources(dispatchResponse);

  const allEvents = useMemo(
    () => [...events, ...(previewEvents || [])],
    [events, previewEvents]
  );

  const tripAcceptRequestModalProps = useAcceptTripRequestConfirmModalProps();
  const tripRejectRequestModalProps = useRejectTripRequestConfirmModalProps();

  const { isLoading: dispatchLoading } = useDispatchRepository({
    refetchOnMountOrArgChange: true,
    pollingInterval: ONE_MINUTE_IN_MS,
    // Mostly for assignment/reassignment when we're previewing the assignment
    // We want to complete the action before we refetch
    // I don't think we have to worry about refetching while previewing a change
    // because that's a layer on top of this data although I guess there's a case
    // where someone else makes the exact assignment that another user is making...
    // Given the polling interval and amount of concurrent dispatchers (usually 1)
    // I think we're ok
    skip: isMenuActionInProgress || !selectedDate || !!selectedTripId,
  });

  useEffect(() => {
    dispatch(dispatcherSlice.actions.dispatchRouteInit());

    if (selectedDate) {
      dispatch(dispatcherSlice.actions.setSelectedDate(selectedDate));
    }
  }, [dispatch, selectedDate]);

  if (!selectedDate) {
    // use the date from state when one is not in the url
    // Keeps the users selected date on page navigation
    return <Navigate to={`/dispatch/${selectedDateFromState}`} replace />;
  }

  if (isRunBambiRunInProgress && runBambiRunJobId) {
    return <Navigate to={`/dispatch/${selectedDate}/${runBambiRunJobId}`} />;
  }

  return (
    <>
      {dispatchLoading || isRunBambiRunInProgress ? (
        <div className="isolate flex h-[85vh] max-h-full max-w-full items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="grid sm:grid-cols-12">
          {isDispatchSidebarOpen && (
            <div className="col-span-3">
              <DispatchToolbar>
                <UnassignedTrips />
              </DispatchToolbar>
            </div>
          )}
          <div
            className={isDispatchSidebarOpen ? 'col-span-9 ' : 'col-span-12'}
          >
            <DispatchCalendar
              resources={resources}
              invalids={invalids}
              events={allEvents}
            />
          </div>
          <AssignmentBreakModal />
          <AssignTripFlow />
          <CallRequestModal />
          <ConfirmWillCallActivationModal />
          <DeleteBreakModal />
          <UnassignConfirmationModal />
          <TripRejectRequestModal props={tripRejectRequestModalProps} />
          <TripAcceptRequestModal props={tripAcceptRequestModalProps} />
          <RunBambiRunAssignmentSelectionStep />
          <MultiloadAssignmentFlow />
          <AssignmentManagementForm hideCloneButton />
          <TripTagsModal />
          <CancelTripModal />
          <CancelSuggestionsModal />
          <BulkCancelTripModal />
        </div>
      )}
    </>
  );
}
