import { FloatInput } from 'src/common/FloatInput';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldController } from 'src/common/FormField/FormFieldController';

import { serviceDetailsNameRoot } from '../ServiceDetailsFormBody';
import { oxygenLitersPerMinFieldValidate } from './oxygenLitersPerMinFieldValidate';

export function OxygenLitersPerMinField() {
  const fieldName = `${serviceDetailsNameRoot}.oxygen_liters_per_min`;
  return (
    <FormFieldController
      name={fieldName}
      rules={{
        validate: oxygenLitersPerMinFieldValidate,
      }}
    >
      {({ field, fieldState }) => {
        const { onChange, ...remainingProps } = field;
        return (
          <DefaultFormFieldLayout
            label="Oxygen Amount (Liters per minute)"
            error={fieldState.error?.message?.toString()}
            inputProps={{
              id: fieldName,
              ...field,
            }}
          >
            <FloatInput
              {...remainingProps}
              onValueChange={(values: any) => {
                onChange(values.value);
              }}
              id={fieldName}
            />
          </DefaultFormFieldLayout>
        );
      }}
    </FormFieldController>
  );
}
