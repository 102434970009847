import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';

export function isNonActivatedWillCall(
  trip:
    | DispatchTripRead
    | RunBambiRunTripRead
    | RunBambiRunReassignedTripRead
    | TripRead
): boolean {
  if ('is_non_activated_will_call' in trip) {
    return trip.is_non_activated_will_call;
  }

  return (
    !!(trip as TripRead).is_will_call &&
    !(trip as TripRead).is_will_call_activated
  );
}
