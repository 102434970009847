import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';

export function LastNameField({ disabled }: { disabled?: boolean }) {
  return (
    <FormFieldController
      name="last_name"
      rules={{ required: 'Last name is required' }}
    >
      {({ field, fieldState }) => {
        return (
          <FormField
            label="Last Name *"
            error={fieldState.error?.message?.toString()}
            type="text"
            inputProps={{
              id: 'last-name',
              placeholder: 'Last Name',
              ...field,
              disabled,
            }}
            disabled={disabled}
          />
        );
      }}
    </FormFieldController>
  );
}
