import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { SelectOption } from 'src/common/primitives/Select';

export function MileageRoundingSelectField({
  fieldPath,
}: {
  fieldPath: string;
}) {
  const mileageRoundingOptions: SelectOption[] = [
    {
      label: 'Do not round',
      value: 'none',
    },
    {
      label: 'Round up',
      value: 'up',
    },
    {
      label: 'Round down',
      value: 'down',
    },
    {
      label: 'Nearest whole number',
      value: 'nearest',
    },
  ];

  return (
    <FormFieldController name={fieldPath}>
      {({ field }) => {
        return (
          <FormField
            label="Mileage Rounding"
            type="select"
            inputProps={{
              ...field,
              id: fieldPath,
            }}
            onChange={(option) => {
              field.onChange(option.value);
            }}
            options={mileageRoundingOptions}
            value={
              field.value
                ? mileageRoundingOptions.find(
                    (option) => option.value === field.value
                  )
                : 'none'
            }
            inline
            helperText="Choose the type of mileage rounding for trips."
          />
        );
      }}
    </FormFieldController>
  );
}
