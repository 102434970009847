import { useEffect, useState } from 'react';

import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { cloneDeep, upperFirst } from 'lodash-es';
import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { VehicleCapacityConfiguration } from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField } from 'src/common/FormField';
import { FormFieldSet } from 'src/common/FormField/FormFieldSet';
import { Button } from 'src/common/primitives/Button';
import { SelectOption } from 'src/common/primitives/Select';
import { IconTooltip } from 'src/common/primitives/Tooltip';

import { assignDefaultFormValues } from './assignDefaultFormValues';

function createNewVehicleCapacityConfiguration(): VehicleCapacityConfiguration {
  return {
    ambulatory_capacity: 0,
    wheelchair_capacity: 0,
    stretcher_capacity: 0,
    wheelchair_xl_capacity: 0,
    broda_chair_capacity: 0,
    geri_chair_capacity: 0,
    equipment_wheelchair_capacity: 0,
  };
}

export function VehicleFormStep2({
  onSubmit,
  onCancel,
  children,
  loading,
  serverErrors,
}: {
  onSubmit: (values: VehicleCapacityConfiguration[]) => void;
  onCancel: () => void;
  children?: React.ReactNode;
  loading: boolean;
  serverErrors: string | null;
}) {
  const { selectedVehicle } = useSelector((state: RootState) => state.fleet);
  const [vehicleCapacityConfigurations, setVehicleCapacityConfigurations] =
    useState(assignDefaultFormValues(selectedVehicle).capacityConfigurations);

  useEffect(() => {
    if (selectedVehicle.capacity_configurations.length === 0) {
      setVehicleCapacityConfigurations([
        createNewVehicleCapacityConfiguration(),
      ]);
    } else {
      const capacityConfigs = selectedVehicle.capacity_configurations.map(
        (capacityConfig) => {
          return {
            ...capacityConfig,
          };
        }
      );
      setVehicleCapacityConfigurations(capacityConfigs);
    }
  }, [selectedVehicle]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(vehicleCapacityConfigurations);
      }}
      data-testid="vehicle-form-step-2"
    >
      {serverErrors && <FormErrorMessage>{serverErrors}</FormErrorMessage>}
      <div className="mt-4 flex gap-x-1">
        <p className="w-1/2">
          Select how many passengers can safely be in the vehicle at the same
          time, and what extra equipment can fit.{' '}
        </p>
        <IconTooltip textPosition="absolute right-0 w-[285px]">
          <span>
            <p>A vehicle with a capacity configuration of:</p>
            <ul className="list-disc pl-8">
              <li>2 ambulatory</li>
              <li>1 wheelchair</li>
            </ul>
            <p>
              can safely transport 1 person riding in a wheelchair and 2 people
              riding in normal seats. This vehicle will be available for
              wheelchair trips with or without accompanying passengers.
            </p>
            <p className="mt-4">
              The same vehicle could also be configured for a single passenger
              in a stretcher without an accompanying passenger, perhaps by
              removing the back seat. In that case, the vehicle would have a
              second capacity configuration of:
            </p>
            <ul className="list-disc pl-8">
              <li>1 stretcher</li>
            </ul>
            <p>The vehicle will then be available for stretcher trips.</p>
          </span>
        </IconTooltip>
      </div>
      <div className="mt-4 flex max-h-[60vh] min-w-[50vw] flex-col gap-6 overflow-y-auto">
        {vehicleCapacityConfigurations.map(
          (vehicleCapacityConfiguration, index) => (
            <CapacityConfigurationEditor
              key={index}
              name={'Configuration ' + (index + 1)}
              vehicleCapacityConfiguration={vehicleCapacityConfiguration}
              onDelete={() => {
                const updatedVehicleCapacityConfigs = [
                  ...vehicleCapacityConfigurations,
                ];
                updatedVehicleCapacityConfigs.splice(index, 1);
                setVehicleCapacityConfigurations(updatedVehicleCapacityConfigs);
              }}
              onCapacityChange={(key: string, value: number) => {
                const updatedVehicleCapacityConfigs = cloneDeep(
                  vehicleCapacityConfigurations
                );

                updatedVehicleCapacityConfigs[index] = {
                  ...updatedVehicleCapacityConfigs[index],
                  [key]: value,
                };

                setVehicleCapacityConfigurations(updatedVehicleCapacityConfigs);
              }}
            />
          )
        )}
      </div>
      {children}
      <div className="mt-4">
        <Button
          onClick={() => {
            setVehicleCapacityConfigurations([
              ...vehicleCapacityConfigurations,
              createNewVehicleCapacityConfiguration(),
            ]);
          }}
        >
          <PlusIcon className="mr-2 h-4 w-4 text-gray-700" /> Add another
          Configuration
        </Button>
      </div>
      <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
        <Button
          className="sm:col-span-6"
          onClick={() => {
            onCancel();
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        {/* TODO: Refactor to use useFormContext and FormSubmitButton */}
        <Button
          className="sm:col-span-6"
          type="submit"
          variant="primary"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </form>
  );
}

const capacityConfigLabels: {
  [key in keyof VehicleCapacityConfiguration]: string;
} = {
  ambulatory_capacity: 'Ambulatory',
  wheelchair_capacity: 'Wheelchair',
  stretcher_capacity: 'Stretcher',
  wheelchair_xl_capacity: 'Wheelchair XL',
  broda_chair_capacity: 'Broda Chair',
  geri_chair_capacity: 'Geri Chair',
  equipment_wheelchair_capacity: 'Empty Wheelchairs',
};

function getCapacityConfigLabel(
  key: keyof VehicleCapacityConfiguration
): string {
  return (
    capacityConfigLabels[key] ||
    upperFirst(key.split('_capacity')[0]).replace('_', ' ')
  );
}

function CapacityConfigurationEditor({
  name,
  vehicleCapacityConfiguration,
  onDelete,
  onCapacityChange,
}: {
  vehicleCapacityConfiguration: VehicleCapacityConfiguration;
  onDelete: () => void;
  name: string;
  onCapacityChange: (key: string, value: number) => void;
}) {
  return (
    <div className="flex flex-col gap-1">
      <h2 className="flex w-[50%] items-center justify-between font-bold text-gray-900">
        {name}{' '}
        <Button
          onClick={() => {
            onDelete();
          }}
          variant="ghost"
        >
          <XMarkIcon className="h-6 w-6 text-gray-900" />
        </Button>
      </h2>
      <FormFieldSet
        Fields={
          <>
            {Object.keys(vehicleCapacityConfiguration)
              .sort()
              .map((key) => (
                <ConfigurationPassengerCapacityEditor
                  key={key}
                  label={getCapacityConfigLabel(
                    key as keyof VehicleCapacityConfiguration
                  )}
                  capacity={
                    vehicleCapacityConfiguration[
                      key as keyof typeof vehicleCapacityConfiguration
                    ] || 0
                  }
                  onCapacityChange={(val) => onCapacityChange(key, val)}
                />
              ))}
          </>
        }
      />
    </div>
  );
}

// This number is meaningless to the API
const capacityOptions: SelectOption[] = new Array(15).fill(1).map((_, n) => ({
  label: `${n}`,
  value: `${n}`,
}));

function parseCapacityValue(selectedCapacity: number): SelectOption {
  return (
    capacityOptions.find(
      (option) => parseInt(option.value) === selectedCapacity
    ) || capacityOptions[0]
  );
}

function ConfigurationPassengerCapacityEditor({
  label,
  capacity,
  onCapacityChange,
}: {
  label: string;
  capacity: number;
  onCapacityChange: (capacity: number) => void;
}) {
  return (
    <>
      <FormField
        type="select"
        label={label}
        colSpan={6}
        placeholder="0"
        inputProps={{}}
        value={parseCapacityValue(capacity)}
        onChange={(option: SelectOption) => {
          onCapacityChange(parseInt(option.value));
        }}
        options={capacityOptions}
        inline
      />
      <FormField type="gap" colSpan={6} inputProps={{}} />
    </>
  );
}
