import { Popover } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { keyframes, styled } from '@stitches/react';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: 4,
  maxWidth: 300,
  padding: '10px 15px',
  fontSize: 15,
  lineHeight: 1.3,
  backgroundColor: 'white',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  userSelect: 'none',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: 'white',
});

function Content({
  children,
  ...props
}: TooltipPrimitive.TooltipContentProps & React.RefAttributes<HTMLDivElement>) {
  return (
    <TooltipPrimitive.Portal>
      <StyledContent {...props}>
        {children}
        <StyledArrow />
      </StyledContent>
    </TooltipPrimitive.Portal>
  );
}

// Exports
export const TooltipProvider = TooltipPrimitive.Provider;
export const Tooltip = TooltipPrimitive.Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = Content;

export const IconTooltip = ({
  children,
  textPosition = 'absolute w-[283px]',
}: {
  children: React.ReactNode;
  textPosition?: any;
}) => {
  return (
    <Popover className="relative h-5 w-5">
      <Popover.Button>
        <QuestionMarkCircleIcon className="h-5 w-5 text-primary-700" />
      </Popover.Button>
      <Popover.Panel
        className={`${textPosition} z-20 border border-gray-300 bg-white p-2 text-sm text-gray-500 shadow`}
      >
        {children}
      </Popover.Panel>
    </Popover>
  );
};

export interface SimpleTooltipProps {
  trigger: React.ReactNode;
  content: string | null;
  delayDuration?: number;
}

/**
 * A simple tooltip component that displays a tooltip when a trigger element is hovered over.
 *
 * Note: You may need to wrap the trigger element in a div to get the tooltip to display.
 *
 * @property trigger - The element that triggers the tooltip when hovered over
 * @property content - The text content of the tooltip
 * @property delayDuration - The delay duration in milliseconds before the tooltip appears
 */
export function SimpleTooltip({
  trigger,
  content,
  delayDuration = 200,
}: SimpleTooltipProps) {
  return (
    <TooltipProvider delayDuration={delayDuration}>
      <Tooltip>
        <TooltipTrigger asChild>{trigger}</TooltipTrigger>
        {content === null ? null : (
          <TooltipContent side="top" align="start" style={{ zIndex: 999 }}>
            {content}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
}
