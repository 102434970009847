import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DeleteAction } from 'src/common/DeleteAction';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { ConfirmModal } from 'src/common/modals/ConfirmModal';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { SelectOption } from 'src/common/primitives/Select';

import {
  setSelectedCredential,
  setSelectedCredentialTypeOption,
  setShowCredentialModal,
} from '../credential.slice';
import { CredentialCategory } from '../types';
import { assignDefaultFormValues } from './assignDefaultFormValues';
import { CredentialFormValues } from './CredentialsFormValues';
import CredentialTypeCombobox from './CredentialTypeCombobox';
import { useCredentialFormSubmit } from './useCredentialFormSubmit';
import { useMemberOptions } from './useMemberOptions';
import { useVehicleOptions } from './useVehicleOptions';

export default function CredentialForm({
  category,
}: {
  category: CredentialCategory;
}) {
  const dispatch = useAppDispatch();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const { selectedCredential, showCredentialModal } = useAppSelector(
    (state) => state.credential
  );

  const onClose = () => {
    dispatch(setShowCredentialModal(false));
    dispatch(setSelectedCredential(undefined));
    dispatch(setSelectedCredentialTypeOption(undefined));
    methods.reset();
  };

  const { onSubmit, onDelete, isSubmitting, error, setError } =
    useCredentialFormSubmit(onClose);

  const { options: memberOptions } = useMemberOptions(category);
  const { options: vehicleOptions } = useVehicleOptions(category);

  const defaultFormValues = assignDefaultFormValues(selectedCredential);
  const methods = useForm<CredentialFormValues>({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (showCredentialModal) {
      methods.reset(defaultFormValues);
    }
    return () => {
      setError('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCredentialModal]);

  return (
    <>
      <Modal
        open={showCredentialModal}
        setOpen={(val) => dispatch(setShowCredentialModal(val))}
        onClose={() => onClose()}
        data-testid="credential-modal"
      >
        <div className="w-full md:min-w-[350px]">
          <div>
            <h3 className="text-[24px] font-medium leading-6 text-gray-900">
              {selectedCredential ? 'Edit Credential' : 'Add a New Credential'}
            </h3>
            {selectedCredential ? null : (
              <p className="mt-1 text-xs text-gray-500">
                Create a new credential for a{' '}
                {category === 'Team' ? 'team member' : 'vehicle'}.
              </p>
            )}
            <p className="mt-2 text-xs text-gray-500">*Required</p>
          </div>

          <p></p>

          {error && (
            <div className="my-2">
              <FormErrorMessage>{error}</FormErrorMessage>
            </div>
          )}

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="my-4 flex">
                <CredentialTypeCombobox category={category} />
              </div>
              {category === 'Team' && (
                <div className="my-4 flex">
                  <FormFieldController
                    name="membership_id"
                    rules={{ required: 'Member is required' }}
                  >
                    {({ field, fieldState }) => {
                      return (
                        <FormField
                          label="Team Member *"
                          error={fieldState.error?.message?.toString()}
                          type="select"
                          inputProps={{
                            id: 'member-select',
                            ...field,
                          }}
                          options={memberOptions}
                          onChange={(option: SelectOption) => {
                            field.onChange(option.value);
                          }}
                          value={
                            field.value ? { value: field.value } : undefined
                          }
                        />
                      );
                    }}
                  </FormFieldController>
                </div>
              )}
              {category === 'Vehicle' && (
                <div className="my-4 flex">
                  <FormFieldController
                    name="vehicle_id"
                    rules={{ required: 'Vehicle is required' }}
                  >
                    {({ field, fieldState }) => {
                      return (
                        <FormField
                          label="Vehicle *"
                          error={fieldState.error?.message?.toString()}
                          type="select"
                          inputProps={{
                            id: 'vehicle-select',
                            ...field,
                          }}
                          options={vehicleOptions}
                          onChange={(option: SelectOption) => {
                            field.onChange(option.value);
                          }}
                          value={
                            field.value ? { value: field.value } : undefined
                          }
                        />
                      );
                    }}
                  </FormFieldController>
                </div>
              )}
              <div className="my-4 flex">
                <FormFieldController
                  name="expiration_date"
                  rules={{ required: 'Expiration date is required' }}
                >
                  {({ field, fieldState }) => {
                    return (
                      <FormField
                        label="Expiration Date *"
                        error={fieldState.error?.message?.toString()}
                        type="date"
                        inputProps={{
                          id: 'expiration-date',
                          ...field,
                        }}
                      />
                    );
                  }}
                </FormFieldController>
              </div>
              <div className="my-4 flex">
                <FormFieldController name="notes">
                  {({ field, fieldState }) => {
                    return (
                      <FormField
                        label="Notes"
                        error={fieldState.error?.message?.toString()}
                        type="textarea"
                        inputProps={{
                          id: 'notes-textarea',
                          ...field,
                        }}
                      />
                    );
                  }}
                </FormFieldController>
              </div>
              {selectedCredential && (
                <DeleteAction
                  label="Delete Credential"
                  onDelete={() => {
                    dispatch(setShowCredentialModal(false));
                    setConfirmModalOpen(true);
                  }}
                />
              )}
              <div className="mt-8 grid grid-cols-2 gap-4">
                <Button
                  onClick={() => {
                    onClose();
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="w-full flex-grow"
                  variant="primary"
                  disabled={isSubmitting}
                  dataTestId="save-credential-button"
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </Modal>
      <ConfirmModal
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
        title="Delete Credential"
        description="Are you sure you want to delete this credential?"
        onCancel={() => {
          setConfirmModalOpen(false);
          dispatch(setShowCredentialModal(true));
        }}
        onConfirm={() => {
          if (!selectedCredential?.id) return;
          onDelete(selectedCredential.id);
          setConfirmModalOpen(false);
        }}
      />
    </>
  );
}
