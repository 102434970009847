import { MbscCalendarEvent } from '@mobiscroll/react';
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RowSelectionState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { redirect } from 'react-router-dom';

import { RootState, useAppSelector } from 'src/app/store';
import {
  AssignmentBreakRead,
  AssignmentRead,
  bambiApi,
  DispatchRead,
  DispatchTripRead,
  OverrideStatusEnum,
  PatchedTripValidate,
  PatchedTripValidateRead,
  RunBambiRunResultRead,
  TripRead,
  TripsBulkValidateCreateApiResponse,
} from 'src/common/external/bambi-api/bambiApi';
import { enhancedBambiApi } from 'src/common/external/bambi-api/enhancedBambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import serverErrorFormatter from 'src/common/util/serverErrorFormatter';

import { REASSIGNMENT_PREVIEW_ID_PREFIX } from './DispatchCalendar/tripReassignmentUtils';
import { CallRequestModalInputs } from './modals/CallRequestModal';
import { getExcludedIds } from './RunBambiRunRoute/RunBambiRunReview';

export const execTripMenuAction = createAsyncThunk(
  'dispatcher/trip-menu/exec-action',
  async function execTripMenuActionImpl(
    {
      action,
      successMessage,
      errorMessage,
    }: {
      action: () => Promise<void>;
      successMessage: string;
      errorMessage: string | null;
    },
    { dispatch }
  ) {
    try {
      await action();
      dispatch(
        show({
          description: successMessage,
          type: 'success',
        })
      );
    } catch (e) {
      // TODO: provide an onError hook to allow showing errors in modals
      if (errorMessage) {
        dispatch(
          show({
            description: errorMessage,
            type: 'error',
          })
        );
      }
    }
  }
);

export const onApplyRunBambiRun = createAsyncThunk(
  'dispatcher/runBambiRun/apply',
  async function onApplyRunBambiRunImpl(
    {
      rbrJobId,
    }: {
      rbrJobId: string;
    },
    { dispatch, getState }
  ) {
    // Not sure why getState is unknown here
    // It's a valid member of the thunkAPI/payloadCreator
    // See https://redux-toolkit.js.org/api/createAsyncThunk#payloadcreator
    // @ts-ignore
    const currentState: RootState = getState() as RootState;
    const {
      dispatcher: {
        tripAssignmentsToApplyIds,
        runBambiRunResult,
        selectedDate,
      },
    } = currentState;

    dispatch(dispatcherSlice.actions.startRunBambiRunApply());
    try {
      if (!runBambiRunResult) {
        throw new Error('No Run Bambi Run Result found');
      }
      const applyPromise = dispatch(
        enhancedBambiApi.endpoints.runBambiRunApplyCreate.initiate({
          id: rbrJobId,
          runBambiRunApply: {
            excluded_trips: getExcludedIds(
              runBambiRunResult.new_trip_assignments,
              tripAssignmentsToApplyIds
            ),
          },
        })
      );
      await applyPromise;
      dispatch(
        show({
          description: 'Run Bambi Run applied successfully',
          type: 'success',
        })
      );
      redirect(`/dispatch/${selectedDate}`);
    } catch (e) {
      dispatch(
        show({
          description: 'Error applying Run Bambi Run',
          type: 'error',
        })
      );
    }
    dispatch(dispatcherSlice.actions.endRunBambiRunApply());
  }
);

export type DispatchModalKey =
  | 'assignmentBreak'
  | 'callRequest'
  | 'cancelTrip'
  | 'cancelSuggestions'
  | 'confirmTripActivation'
  | 'deleteBreak'
  | 'unassign'
  | 'acceptRequestConfirm'
  | 'rejectRequestConfirm'
  | 'bulkRejectRequestConfirm'
  | 'rbrAssignmentSelection';

export type RequestedTripIdsToReasonCodesMap = {
  [k: string]: string | null;
};

export interface IDispatchState {
  selectedDate: string;
  selectedTripId: string | number | undefined;
  dispatchResponse?: DispatchRead;
  selectedUnassignedTripIds: string[];
  isMenuActionInProgress: boolean;
  isRunBambiRunInProgress: boolean;
  isRunBambiRunApplyInProgress: boolean;
  runBambiRunJobId?: string;
  runBambiRunResult?: RunBambiRunResultRead;
  unassignedTripsSearchTerm: string;
  unassignedTripsTimeRange: {
    startTime: string | undefined;
    endTime: string | undefined;
  };
  openTripDropdownMenuId: string | null;
  runBambiRunError?: string | null;
  tripAssignmentsToApplyIds: { [k: string]: boolean };
  tripAssignmentFlow: {
    isAssignTripModalOpen: boolean;
    tripId: string;
    assignmentId: string;
    validateAssignmentResponse?: PatchedTripValidateRead | null;
    status?: OverrideStatusEnum;
  };
  bulkTripAssignmentFlow: {
    isAssignTripModalOpen: boolean;
    tripIds: string[];
    assignmentId: string;
    validateBulkAssignmentsResponse?: TripsBulkValidateCreateApiResponse | null;
  };
  multiloadAssignmentFlow: {
    // We're not necessarily creating a multi-load, so being explicit with the action
    // being taken that opened the flow rather than multi-load oriented modes like create/edit
    mode:
      | 'assign-trip'
      | 'edit-trip-multi-load'
      | 'create-assigned-trip-multi-load';
    isMultiloadAssignmentFlowOpen: boolean;
    originatingTripId: string;
    selectedTripIdsToMultiload: string[];
    assignmentIdToMultiload: string;
    potentialMultiloadTrips: string[];
    multiLoadId?: string;
  };
  modals: {
    modalToShow: DispatchModalKey | null;
    requestedTripId?: string | null;
    requestActionConfirmModalRowSelection: RowSelectionState;
    // This is a map of tripId to reason code
    // There's no enum for codes and they're per trip, so we just enforce a string
    // The api will error if the code is invalid. Codes are fetched and displayed
    // to user in a dropdown
    requestedTripIdsToRejectToReasonCodes: RequestedTripIdsToReasonCodesMap;
    assignmentId: string | null;
    assignmentBreak: AssignmentBreakRead | null;
    callRequestInputs: CallRequestModalInputs | null;
    confirmTripActivationTripId: string | null;
    selectedTrip: TripRead | null;
  };
  previewEvents?: MbscCalendarEvent[];
  isDispatchSidebarOpen: boolean;
  showToggleCalendarScrollButton: boolean;
  isEventCalendarScrollable: boolean;
  selectedAssignments: string[];
  assignmentColumnVisualState: {
    [k: string]:
      | {
          isExpanded: boolean;
          isWidthSmall: boolean;
        }
      | undefined;
  };
  assignmentEdit: {
    assignmentId: string | null;
  };
}

export const initialState: IDispatchState = {
  selectedDate: DateTime.local().toISODate(),
  selectedTripId: undefined,
  selectedUnassignedTripIds: [],
  isMenuActionInProgress: false,
  isRunBambiRunInProgress: false,
  isRunBambiRunApplyInProgress: false,
  runBambiRunJobId: undefined,
  unassignedTripsSearchTerm: '',
  unassignedTripsTimeRange: {
    startTime: undefined,
    endTime: undefined,
  },
  openTripDropdownMenuId: null,
  tripAssignmentsToApplyIds: {},
  tripAssignmentFlow: {
    isAssignTripModalOpen: false,
    tripId: '',
    assignmentId: '',
    validateAssignmentResponse: null,
  },
  bulkTripAssignmentFlow: {
    isAssignTripModalOpen: false,
    tripIds: [],
    assignmentId: '',
    validateBulkAssignmentsResponse: null,
  },
  multiloadAssignmentFlow: {
    mode: 'assign-trip',
    isMultiloadAssignmentFlowOpen: false,
    originatingTripId: '',
    selectedTripIdsToMultiload: [],
    assignmentIdToMultiload: '',
    potentialMultiloadTrips: [],
  },
  modals: {
    modalToShow: null,
    assignmentId: null,
    assignmentBreak: null,
    callRequestInputs: null,
    confirmTripActivationTripId: null,
    selectedTrip: null,
    requestedTripIdsToRejectToReasonCodes: {},
    requestActionConfirmModalRowSelection: {},
  },
  isDispatchSidebarOpen: true,
  showToggleCalendarScrollButton: true,
  isEventCalendarScrollable: false,
  selectedAssignments: [],
  assignmentColumnVisualState: {},
  assignmentEdit: {
    assignmentId: null,
  },
};

const stateKeysToResetOnDateChange = [
  'selectedTripId',
  'selectedUnassignedTripIds',
  'assignmentColumnVisualState',
];

// These are values we intend to start fresh on a date change
// For ex: If selectedUnassignedTripIds has values and then we change date then run RBR
// RBR gets a trip outside of the date range and blows up
function resetOnDateChange(state: IDispatchState) {
  stateKeysToResetOnDateChange.forEach((key) => {
    // TODO: make ts happy
    // @ts-ignore
    state[key] = initialState[key];
  });
}

export const dispatcherSlice = createSlice({
  name: 'dispatcher',
  initialState,
  reducers: {
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
      resetOnDateChange(state);
    },
    setSelectedDateToToday: (state) => {
      state.selectedDate = DateTime.local().toISODate();
      resetOnDateChange(state);
    },
    setSelectedDateToNextDay: (state) => {
      state.selectedDate = DateTime.fromISO(state.selectedDate)
        .plus({ days: 1 })
        .toISODate();
      resetOnDateChange(state);
    },
    setSelectedDateToPreviousDay: (state) => {
      state.selectedDate = DateTime.fromISO(state.selectedDate)
        .minus({ days: 1 })
        .toISODate();
      resetOnDateChange(state);
    },
    setSelectedUnassignedTripIds: (state, action) => {
      state.selectedUnassignedTripIds = action.payload;
    },
    setUnassignedTripsSearchTerm(state, action) {
      state.unassignedTripsSearchTerm = action.payload;
    },
    setUnassignedTripsTimeRange: (state, action) => {
      state.unassignedTripsTimeRange = action.payload;
    },
    setOpenTripDropdownMenuId: (state, action) => {
      state.openTripDropdownMenuId = action.payload;
    },
    runBambiRunRouteInit: (state) => {
      state.isRunBambiRunInProgress = true;
      state.runBambiRunJobId = undefined;
      state.runBambiRunError = null;
      state.isRunBambiRunApplyInProgress = false;
    },
    dispatchRouteInit: (state) => {
      state.isRunBambiRunInProgress = false;
      state.runBambiRunJobId = undefined;
      state.runBambiRunError = null;
      // We set this here because Settings.defaultZone isn't set to org timezone when initialState
      // is created
      state.selectedDate = DateTime.local().toISODate();
    },

    onSelectAllNewAssignments: (
      state,
      action: { payload: { selectedState: boolean } }
    ) => {
      Object.keys(state.tripAssignmentsToApplyIds).forEach((tripId) => {
        state.tripAssignmentsToApplyIds[tripId] = action.payload.selectedState;
      });
    },
    toggleRunBambiRunTripAssignmentSelection: (
      state,
      action: { payload: { tripId: string; selectedState: boolean } }
    ) => {
      state.tripAssignmentsToApplyIds[action.payload.tripId] =
        action.payload.selectedState;
    },
    removeRunBambiRunSuggestion: (
      state,
      action: { payload: { tripId: string } }
    ) => {
      state.tripAssignmentsToApplyIds[action.payload.tripId] = false;
    },
    onApplyRunBambiRunWithRequestedTrips: (state) => {
      state.modals.modalToShow = 'acceptRequestConfirm';
    },
    startRunBambiRunApply: (state) => {
      state.isRunBambiRunApplyInProgress = true;
    },
    endRunBambiRunApply: (state) => {
      state.isRunBambiRunApplyInProgress = false;

      // If we're successful, we navigate away so let's clean up our state
      if (!state.runBambiRunError) {
        state.runBambiRunResult = initialState.runBambiRunResult;
      }
    },
    addTempTripAssignment: (state, action) => {
      if (!state.dispatchResponse) return;
      const tripBeingReassigned = state.dispatchResponse.assigned_trips.find(
        (trip) => trip.id === action.payload.id
      );
      const isReassignment = !!tripBeingReassigned;

      state.selectedTripId = action.payload.id;
      if (isReassignment) {
        state.selectedTripId = `${REASSIGNMENT_PREVIEW_ID_PREFIX}${action.payload.id}`;
      }
      state.dispatchResponse.assigned_trips.push({
        ...action.payload,
        id: state.selectedTripId,
      });
    },
    removeTempTripAssignment: (state, action) => {
      if (!state.dispatchResponse) return;
      state.dispatchResponse.assigned_trips =
        state.dispatchResponse.assigned_trips.filter(
          (trip) => trip.id !== state.selectedTripId
        );
      state.selectedTripId = undefined;
    },
    onPreviewTripAssignmentStart: (
      state,
      action: { payload: MbscCalendarEvent[]; type: string }
    ) => {
      state.previewEvents = action.payload;
    },
    onPreviewTripAssignmentEnd: (state) => {
      state.previewEvents = undefined;
    },
    onTripAssignmentInvalid: (
      state,
      action: {
        payload: {
          tripId: string;
          assignmentId: string;
          validateAssignmentResponse: PatchedTripValidate;
          status: OverrideStatusEnum;
        };
      }
    ) => {
      state.tripAssignmentFlow = {
        isAssignTripModalOpen: true,
        ...action.payload,
      };
    },
    onBulkTripAssignmentInvalid: (
      state,
      action: {
        payload: {
          tripIds: string[];
          assignmentId: string;
          validateBulkAssignmentsResponse: TripsBulkValidateCreateApiResponse;
        };
      }
    ) => {
      state.bulkTripAssignmentFlow = {
        isAssignTripModalOpen: true,
        ...action.payload,
      };
    },
    onCancelAssignTrip: (state) => {
      if (!state.dispatchResponse) return;
      state.tripAssignmentFlow = {
        ...initialState.tripAssignmentFlow,
        isAssignTripModalOpen: false,
      };

      state.dispatchResponse.assigned_trips =
        state.dispatchResponse.assigned_trips.filter(
          (trip) => trip.id !== state.selectedTripId
        );

      state.selectedTripId = undefined;
    },
    onCancelBulkAssignTrip: (state) => {
      if (!state.dispatchResponse) return;

      state.bulkTripAssignmentFlow = {
        ...initialState.bulkTripAssignmentFlow,
        isAssignTripModalOpen: false,
      };

      state.dispatchResponse.assigned_trips =
        state.dispatchResponse.assigned_trips.filter(
          (trip) => !state.selectedUnassignedTripIds.includes(trip.id)
        );

      state.selectedUnassignedTripIds = [];
    },
    onConfirmAssignTrip: (state) => {
      state.tripAssignmentFlow = {
        ...initialState.tripAssignmentFlow,
        isAssignTripModalOpen: false,
      };
    },
    onConfirmBulkAssignTrip: (state) => {
      state.bulkTripAssignmentFlow = {
        ...initialState.bulkTripAssignmentFlow,
        isAssignTripModalOpen: false,
      };
    },
    resetSelectedTrip: (state) => {
      state.selectedTripId = undefined;
    },
    setModalAssignmentId: (state, action: PayloadAction<string>) => {
      state.modals.assignmentId = action.payload;
    },
    setModalAssignmentBreak: (
      state,
      action: PayloadAction<AssignmentBreakRead>
    ) => {
      state.modals.assignmentBreak = action.payload;
    },
    setModalCallRequestInputs: (
      state,
      action: PayloadAction<CallRequestModalInputs>
    ) => {
      state.modals.callRequestInputs = action.payload;
    },
    setModalConfirmTripActivationTripId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.modals.confirmTripActivationTripId = action.payload;
    },
    setModalSelectedTrip: (state, action: PayloadAction<TripRead | null>) => {
      state.modals.selectedTrip = action.payload;
    },
    showDispatchModal: (state, action: PayloadAction<DispatchModalKey>) => {
      state.modals.modalToShow = action.payload;
    },
    dismissDispatchModal: (state) => {
      state.modals = initialState.modals;
    },
    toggleDispatchSidebar: (state) => {
      state.isDispatchSidebarOpen = !state.isDispatchSidebarOpen;
    },
    setEventCalendarScrollable: (state, action) => {
      state.isEventCalendarScrollable = action.payload;
    },
    setShowToggleCalendarScrollButton: (state, action) => {
      state.showToggleCalendarScrollButton = action.payload;
    },
    onRequestedTripAssignment: (
      state,
      action: {
        payload: {
          tripId: string;
          assignmentId: string;
        };
      }
    ) => {
      state.modals.modalToShow = 'acceptRequestConfirm';
      state.modals.requestedTripId = action.payload.tripId;
      state.modals.assignmentId = action.payload.assignmentId;
    },
    onRejectUnassignedRequestedTrips: (
      state,
      action: {
        payload: {
          tripIdsToReject: string[];
        };
      }
    ) => {
      const tripIdsToReject = action.payload.tripIdsToReject;
      state.modals.modalToShow = 'rejectRequestConfirm';
      state.modals.requestActionConfirmModalRowSelection =
        tripIdsToReject.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {} as RowSelectionState);
      state.modals.requestedTripIdsToRejectToReasonCodes =
        tripIdsToReject.reduce((acc, curr) => {
          acc[curr] = null;
          return acc;
        }, {} as RequestedTripIdsToReasonCodesMap);
    },
    // TODO: Shame - These are also used for the all requests view
    // Noodling on a few better places for accept/reject:
    // 1. The trips module - Put all the various trip actions in one place
    // 2. The rom module - only add accept/reject here? But we're also rejecting rop and kinetik trips
    // 3. Some other module?
    // For now, just leaving here
    onSingleTripRequestRejection: (
      state,
      action: {
        payload: {
          tripId: string;
        };
      }
    ) => {
      state.modals.modalToShow = 'rejectRequestConfirm';
      state.modals.requestedTripIdsToRejectToReasonCodes[
        action.payload.tripId
      ] = null;
    },
    onSingleTripRequestAcceptance: (
      state,
      action: {
        payload: {
          tripId: string;
        };
      }
    ) => {
      state.modals.modalToShow = 'acceptRequestConfirm';
      state.modals.requestedTripId = action.payload.tripId;
    },
    onTripMenuCancelRequest: (
      state,
      action: { payload: { trip: TripRead | null } }
    ) => {
      state.modals.modalToShow = 'cancelSuggestions';
      state.modals.selectedTrip = action.payload.trip;
    },
    onRejectReasonChange: (
      state,
      action: { payload: { tripId: string; reason: string | null } }
    ) => {
      state.modals.requestedTripIdsToRejectToReasonCodes[
        action.payload.tripId
      ] = action.payload.reason;
    },
    setRequestActionConfirmModalRowSelection: (
      state,
      action: { payload: RowSelectionState }
    ) => {
      state.modals.requestActionConfirmModalRowSelection = action.payload;
    },
    onCancelRunBambiRun: (state) => {
      state.runBambiRunResult = undefined;
    },
    onBulkAssignRequestedValidTrips: (
      state,
      action: { payload: { assignmentId: string } }
    ) => {
      state.modals.modalToShow = 'acceptRequestConfirm';
      state.modals.assignmentId = action.payload.assignmentId;
    },
    onApplySelectedAssignments: (state, action: { payload: string[] }) => {
      state.selectedAssignments = action.payload;
    },
    onRunBambiRunButtonClick: (state) => {
      state.modals.modalToShow = 'rbrAssignmentSelection';
    },
    onRunBambiRunCancel: (state) => {
      state.modals = initialState.modals;
      state.runBambiRunResult = initialState.runBambiRunResult;
      state.runBambiRunError = initialState.runBambiRunError;
    },
    onMultiloadAssignmentCheckedChange: (
      state,
      action: { payload: { tripId: string; checked: boolean } }
    ) => {
      const { tripId, checked } = action.payload;
      if (checked) {
        state.multiloadAssignmentFlow.selectedTripIdsToMultiload.push(tripId);
      } else {
        state.multiloadAssignmentFlow.selectedTripIdsToMultiload =
          state.multiloadAssignmentFlow.selectedTripIdsToMultiload.filter(
            (id) => id !== tripId
          );
      }
    },
    onMultiloadAssignmentStart: (
      state,
      action: {
        payload: {
          tripId: string;
          assignmentId: string;
          potentialMultiloadTrips: string[];
        };
      }
    ) => {
      state.multiloadAssignmentFlow.mode = 'assign-trip';
      state.multiloadAssignmentFlow.originatingTripId = action.payload.tripId;
      state.multiloadAssignmentFlow.assignmentIdToMultiload =
        action.payload.assignmentId;
      state.multiloadAssignmentFlow.isMultiloadAssignmentFlowOpen = true;
      state.multiloadAssignmentFlow.potentialMultiloadTrips =
        action.payload.potentialMultiloadTrips;
      state.multiloadAssignmentFlow.selectedTripIdsToMultiload = [
        action.payload.tripId,
      ];
    },
    onMultiloadFlowCancel: (state) => {
      state.multiloadAssignmentFlow = {
        ...initialState.multiloadAssignmentFlow,
      };
    },
    onMultiloadFlowSuccess: (state) => {
      state.multiloadAssignmentFlow = {
        ...initialState.multiloadAssignmentFlow,
      };
    },
    onMultiloadAssignmentEdit: (
      state,
      action: {
        payload: {
          trip: DispatchTripRead;
          assignment: AssignmentRead;
        };
      }
    ) => {
      if (
        !state.dispatchResponse ||
        typeof action.payload.trip.multiload_id !== 'string'
      )
        return;
      state.multiloadAssignmentFlow.mode = 'edit-trip-multi-load';
      state.multiloadAssignmentFlow.originatingTripId = action.payload.trip.id;
      state.multiloadAssignmentFlow.assignmentIdToMultiload =
        action.payload.assignment.id;
      state.multiloadAssignmentFlow.isMultiloadAssignmentFlowOpen = true;
      state.multiloadAssignmentFlow.multiLoadId =
        action.payload.trip.multiload_id;

      const allTrips = [
        ...state.dispatchResponse.assigned_trips,
        ...state.dispatchResponse.unassigned_trips,
      ];

      // Pre-select all trips that are part of the multiload
      const currentMultiLoadTripIds = allTrips
        .filter(
          (trip) => trip.multiload_id === action.payload.trip.multiload_id
        )
        .map((trip) => trip.id);
      // Only show trips that are part of the multiload and pre-select them
      state.multiloadAssignmentFlow.selectedTripIdsToMultiload = [
        ...currentMultiLoadTripIds,
      ];
      state.multiloadAssignmentFlow.potentialMultiloadTrips = [
        ...currentMultiLoadTripIds,
      ];
    },
    onCreateAssignedTripMultiLoad: (
      state,
      action: {
        payload: {
          trip: DispatchTripRead;
          potentialMultiloadTrips: string[];
        };
      }
    ) => {
      if (!state.dispatchResponse) return;
      state.multiloadAssignmentFlow.mode = 'create-assigned-trip-multi-load';
      state.multiloadAssignmentFlow.originatingTripId = action.payload.trip.id;
      state.multiloadAssignmentFlow.assignmentIdToMultiload =
        action.payload.trip.assignment_id;
      state.multiloadAssignmentFlow.isMultiloadAssignmentFlowOpen = true;

      state.multiloadAssignmentFlow.selectedTripIdsToMultiload = [
        action.payload.trip.id,
      ];
      state.multiloadAssignmentFlow.potentialMultiloadTrips =
        action.payload.potentialMultiloadTrips;
    },
    toggleAssignmentColumnExpanded: (state, action: PayloadAction<string>) => {
      let existingState = state.assignmentColumnVisualState[action.payload];
      if (!existingState) {
        existingState = {
          isExpanded: false,
          isWidthSmall: false,
        };
      }
      state.assignmentColumnVisualState[action.payload] = {
        ...existingState,
        isExpanded: !existingState.isExpanded,
      };
    },
    setAssignmentColumnIsWidthSmall: (
      state,
      action: PayloadAction<{
        assignmentId: string;
        isWidthSmall: boolean;
      }>
    ) => {
      let existingState =
        state.assignmentColumnVisualState[action.payload.assignmentId];
      if (!existingState) {
        existingState = {
          isExpanded: false,
          isWidthSmall: false,
        };
      }
      state.assignmentColumnVisualState[action.payload.assignmentId] = {
        ...existingState,
        isWidthSmall: action.payload.isWidthSmall,
      };
    },
    onAssignmentEditStart: (
      state,
      action: PayloadAction<{ assignmentId: string }>
    ) => {
      state.assignmentEdit.assignmentId = action.payload.assignmentId;
    },
    onAssignmentEditCancel: (state) => {
      state.assignmentEdit = initialState.assignmentEdit;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IDispatchState>) => {
    builder.addCase(execTripMenuAction.pending, (state) => {
      state.isMenuActionInProgress = true;
    });

    builder.addCase(execTripMenuAction.fulfilled, (state) => {
      state.isMenuActionInProgress = false;
    });

    builder.addCase(execTripMenuAction.rejected, (state) => {
      state.isMenuActionInProgress = false;
    });

    builder.addMatcher(
      bambiApi.endpoints.dispatchRetrieve.matchFulfilled,
      (state, action) => {
        state.dispatchResponse = action.payload;
        state.previewEvents = undefined;
      }
    );

    builder.addMatcher(
      bambiApi.endpoints.runBambiRunCreate.matchPending,
      (state) => {
        state.isRunBambiRunInProgress = true;
        state.runBambiRunJobId = undefined;
        state.runBambiRunError = null;
      }
    );

    builder.addMatcher(
      bambiApi.endpoints.runBambiRunCreate.matchRejected,
      (state, action) => {
        state.isRunBambiRunInProgress = false;
        state.runBambiRunError = serverErrorFormatter(action.payload);
      }
    );

    builder.addMatcher(
      bambiApi.endpoints.runBambiRunCreate.matchFulfilled,
      (state, action) => {
        // We don't set loading to false because we will poll this id
        state.runBambiRunJobId = action.payload.id;
        state.isRunBambiRunInProgress = true;
      }
    );

    builder.addMatcher(
      bambiApi.endpoints.runBambiRunRetrieve.matchPending,
      (state) => {
        // Not really necessary, but for completeness ensure this is true here
        state.isRunBambiRunInProgress = true;
        state.runBambiRunError = null;
      }
    );

    builder.addMatcher(
      bambiApi.endpoints.runBambiRunRetrieve.matchRejected,
      (state, action) => {
        // We can/should ignore these errors from rtk-query because they occur when
        // dupe requests are made most often. It's an internal error and doesn't necessarily mean that there was
        // an http error
        if (action.error.name !== 'ConditionError') {
          state.isRunBambiRunInProgress = false;
          state.runBambiRunError = 'Error fetching Run Bambi Run task status';
        }
      }
    );

    builder.addMatcher(
      bambiApi.endpoints.runBambiRunRetrieve.matchFulfilled,
      (state, action) => {
        switch (action.payload.state) {
          case 'STARTED':
          case 'PENDING':
            return;
          case 'FAILURE':
            state.isRunBambiRunInProgress = false;
            // RBR returns an empty object on 'FAILURE' state
            // Isolating this so FE only deals with undefined or RunBambiRunResultRead
            state.runBambiRunResult = {
              new_trip_assignments: [],
              trip_reassignments: [],
              assignments: [],
              trips_not_assigned: [],
              existing_trip_assignments: [],
            };
            state.runBambiRunError =
              'Run Bambi Run failed to complete. Please try again by clicking Cancel and re-running.';
            return;
        }
        state.runBambiRunResult = action.payload.result;
        state.isRunBambiRunInProgress = false;
        // We want to select all new assignments by default
        state.runBambiRunResult.new_trip_assignments.forEach((trip) => {
          state.tripAssignmentsToApplyIds[trip.id] = true;
        });
        // Annnnnd reassignments also
        state.runBambiRunResult.trip_reassignments.forEach((trip) => {
          state.tripAssignmentsToApplyIds[trip.id] = true;
        });
      }
    );

    // "Temporary" fix for assigning a requested trip that needs a validation override
    // If the user clicks quickly, the trip potentially will appear in both the unassigned and
    // calendar views until the next dispatch poll
    builder.addMatcher(
      bambiApi.endpoints.tripsAssignPartialUpdate.matchFulfilled,
      (state, action) => {
        if (!state.dispatchResponse) return;
        // The types aren't much help here
        // Types say meta doesn't exist on payload, but it does
        // @ts-ignore
        if (action.payload.meta?.arg?.originalArgs) {
          // @ts-ignore
          const originalArgs = action.payload.meta.arg.originalArgs;
          const tripId = originalArgs.id;
          const assignmentId = originalArgs.patchedTripAssign?.assignment_id;
          const trip = state.dispatchResponse.unassigned_trips.find(
            (trip) => trip.id === tripId
          );
          if (trip) {
            trip.assignment_id = assignmentId;
            state.dispatchResponse.assigned_trips.push(trip);
            state.dispatchResponse.unassigned_trips =
              state.dispatchResponse.unassigned_trips.filter(
                (trip) => trip.id !== tripId
              );
            state.previewEvents = undefined;
          }
        }
      }
    );
  },
});

export const {
  setSelectedUnassignedTripIds,
  setUnassignedTripsSearchTerm,
  setUnassignedTripsTimeRange,
  setOpenTripDropdownMenuId,
  addTempTripAssignment,
  removeTempTripAssignment,
  onTripAssignmentInvalid,
  onBulkTripAssignmentInvalid,
  onRequestedTripAssignment,
  onCancelAssignTrip,
  onCancelBulkAssignTrip,
  onConfirmAssignTrip,
  onConfirmBulkAssignTrip,
  resetSelectedTrip,
  setModalAssignmentBreak,
  setModalAssignmentId,
  setModalSelectedTrip,
  setModalCallRequestInputs,
  onPreviewTripAssignmentStart,
  onPreviewTripAssignmentEnd,
  toggleDispatchSidebar,
  showDispatchModal,
  dismissDispatchModal,
  setShowToggleCalendarScrollButton,
  setEventCalendarScrollable,
  setModalConfirmTripActivationTripId,
  onSingleTripRequestRejection: onTripMenuRejectRequest,
  onSingleTripRequestAcceptance: onTripMenuAcceptRequest,
  onTripMenuCancelRequest,
  onRejectReasonChange,
  onBulkAssignRequestedValidTrips,
  onApplySelectedAssignments,
  onRunBambiRunButtonClick,
} = dispatcherSlice.actions;

export const useDispatchModalToShow = () => {
  return useAppSelector((state) => state.dispatcher.modals.modalToShow);
};

export const selectUnassignedTrips = (state: RootState) =>
  state.dispatcher.dispatchResponse?.unassigned_trips;

export const selectSelectedUnassignedTripIds = (state: RootState) =>
  state.dispatcher.selectedUnassignedTripIds;
