import { MbscCalendarEventData } from '@mobiscroll/react';

import { AssignmentEvent } from './eventRenderers/AssignmentEvent';
import { TripCalendarEvent } from './TripCalendarEvent';
import BreakCalendarEvent from './TripCalendarEvent/BreakCalendarEvent';
import { EventWaitTime } from './TripCalendarEvent/EventWaitTime';

export function DispatchCalendarEvent({
  event,
}: {
  event: MbscCalendarEventData;
}) {
  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 p-0">
      {/* 
              TODO: Move the rest of the renderers to ./eventRenders/ and lift non TripCalendarEvent stuff out
              of that module.

              Also, need to think about typing here. Right now we're just duck typing the event.original
            */}
      {event.original?.assignmentEvent && (
        <AssignmentEvent
          variant={event.original.assignmentEvent.type}
          time={
            typeof event.original.start === 'string' ? event.original.start : ''
          }
        />
      )}
      {event.original?.trip && (
        <TripCalendarEvent
          trip={event.original.trip}
          type={event.original.type}
        />
      )}
      {event.original?.assignmentBreak && (
        <BreakCalendarEvent assignmentBreak={event.original.assignmentBreak} />
      )}
      {event.original?.waitTime && <EventWaitTime />}
    </div>
  );
}
