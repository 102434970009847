import { useState } from 'react';

import { FormFieldDefault } from 'src/common/FormField/FormFieldDefault';
import { FormFieldLocationTypeahead } from 'src/common/FormField/FormFieldLocationTypeahead';

import { BillingAddress } from './types';
import { parsePlaceAddress } from './utils';

interface BillingAddressFormProps {
  className?: string;
  onChange: (address: BillingAddress) => void;
  addressLookupPlaceholder?: string;
  value?: BillingAddress;
}

export function BillingAddressForm({
  className,
  onChange,
  addressLookupPlaceholder,
  value,
}: BillingAddressFormProps) {
  const [address, setAddress] = useState<BillingAddress>({
    city: '',
    country: '',
    zip: '',
    state: '',
    street: '',
    street2: '',
    ...value,
  });

  return (
    <div className={className} data-testid="billing-address-form">
      <FormFieldLocationTypeahead
        label="Address"
        inputProps={{
          id: 'billing-address-form-typeahead',
        }}
        onChange={(e) => {
          const address = parsePlaceAddress(e.label);
          setAddress(address);
          onChange(address);
        }}
        placeholder={addressLookupPlaceholder}
      />
      <FormFieldDefault
        type="text"
        label="Zip Code *"
        className="mt-2"
        inputProps={{
          id: 'billing-address-form-zip',
          required: true,
          value: address.zip,
          onChange: (e) => {
            setAddress({ ...address, zip: e.target.value });
            onChange({ ...address, zip: e.target.value });
          },
        }}
        value={address.zip}
      />
    </div>
  );
}
