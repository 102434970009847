import { FormError } from './FormError';
import {
  HelperText,
  HelperTextPopover,
  HelperTextPopoverProps,
  getHelperTextProps,
} from './FormFieldHelperText';
import { FormLabel } from './FormLabel';

type DefaultFormFieldLayoutProps = {
  inputProps: React.InputHTMLAttributes<HTMLInputElement> | undefined;
  label?: string | React.ReactNode;
  error?: string;
  inline?: boolean;
  children: React.ReactNode;
  className?: string;
  helperText?: HelperText;
};

export function DefaultFormFieldLayout({
  inputProps,
  label,
  error,
  inline,
  children,
  className,
  helperText,
}: DefaultFormFieldLayoutProps) {
  const { inlineHelperText, popoverProps } = getHelperTextProps(helperText);
  const LabelElement = () => (
    <FormLabel htmlFor={inputProps?.id}>{label}</FormLabel>
  );

  return (
    <div
      className={`${
        inline ? 'grid grid-cols-6 items-center gap-2' : 'flex flex-col'
      }${className ? ' ' + className : ''}`}
    >
      <ConditionalLayout
        inline={inline}
        LabelElement={LabelElement}
        popoverProps={popoverProps}
        inlineHelperText={inlineHelperText}
        error={error}
      >
        {children}
      </ConditionalLayout>
    </div>
  );
}

interface ConditionalLayoutProps {
  LabelElement: () => JSX.Element;
  PopoverElement?: () => JSX.Element;
  children: React.ReactNode;
  inline: boolean | undefined;
  error: string | undefined;
  inlineHelperText: string | undefined;
  popoverProps: HelperTextPopoverProps | undefined;
}

/**
 * This takes into account the `inline` and `popoverProps` and
 * renders the element props in the appropriate places.
 */
const ConditionalLayout = (props: ConditionalLayoutProps) => {
  const {
    children,
    inline,
    error,
    LabelElement,
    popoverProps,
    inlineHelperText,
  } = props;
  const { labelPopoverProps, inputPopoverProps } =
    mapPositionToShownPopoverProps(popoverProps);

  // Extracting this allows reuse whether inline or not inline
  // and with or without a popover (4 cases).
  const LabelWithPopover = () => {
    return labelPopoverProps ? (
      <div className="align-center flex justify-start gap-2">
        <LabelElement />
        <HelperTextPopover {...labelPopoverProps} />
      </div>
    ) : (
      <LabelElement />
    );
  };

  return (
    <>
      {inline ? (
        <div className="col-span-2">
          <LabelWithPopover />
        </div>
      ) : (
        <LabelWithPopover />
      )}
      <div className={inline ? 'col-span-4' : undefined}>
        <div className="mt-1">
          <div className="flex items-center justify-between gap-2">
            <div className="flex-grow">{children}</div>
            {inputPopoverProps && <HelperTextPopover {...inputPopoverProps} />}
          </div>
        </div>
        {inlineHelperText && (
          <aside className="mt-1 text-xs text-gray-600">
            {inlineHelperText}
          </aside>
        )}
        {error && <FormError error={error} />}
      </div>
    </>
  );
};

/**
 * This takes into account the `popoverProps.iconPosition` and
 * returns the props if an element should be shown at that location.
 *
 * This may seem unnecessary but it helps with passing the props
 * conditionally to different places within the JSX.
 */
const mapPositionToShownPopoverProps = (
  popoverProps: HelperTextPopoverProps | undefined
) => {
  if (!popoverProps || !popoverProps.iconPosition) {
    return {};
  }
  const { iconPosition } = popoverProps;

  return {
    labelPopoverProps:
      iconPosition === 'label-right' ? popoverProps : undefined,
    inputPopoverProps:
      iconPosition === 'input-right' ? popoverProps : undefined,
  };
};
