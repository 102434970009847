import { ContextFormFieldNumber } from 'src/common/FormField/v2/context/components';

import { SettingsRowHeader } from './components/SettingsRowHeader';

export function MobileAppSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Mobile App Settings"
        description="Organization defaults for the driver mobile app."
      />

      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <ContextFormFieldNumber
            // Not a typo: This is a maximum from the user's perspective
            label="Maximum Upcoming Trips Shown to Drivers"
            fieldPath="minimum_upcoming_trips_returned"
            placeholder="Maximum trips"
          />
        </div>
      </div>
    </div>
  );
}
