import { useContext, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { usePayersListQuery } from 'src/common/external/bambi-api/bambiApi';
import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { useAuth } from 'src/features/auth/useAuth';

import { pricingNameRoot } from '../Pricing/pricingNameRoot';
import { DynamicFieldNameRootContext } from './DynamicFieldNameRootContext';

export function ExternalTripId() {
  const fieldNameRoot = useContext(DynamicFieldNameRootContext);
  const auth = useAuth();
  const { data: payers } = usePayersListQuery({
    'Organization-ID': auth.currentOrganizationId || '',
  });

  const [payerCategory, setPayerCategory] = useState<string | undefined>();

  const { watch, setValue } = useFormContext();
  const payer = watch(`${pricingNameRoot}.payer`);

  useEffect(() => {
    const payerCategory = payers?.results.find(
      (result) => result.id === payer
    )?.payer_type;

    if (payerCategory) {
      setPayerCategory(payerCategory);

      //clear the value on updates
      if (payerCategory !== 'broker') {
        setValue(`${fieldNameRoot}.external_trip_id`, '');
      }
    }
  }, [fieldNameRoot, payer, payers?.results, setValue]);

  return (
    <>
      {payerCategory === 'broker' && (
        <>
          <div className="col-span-12 flex flex-col">
            <FormFieldController name={`${fieldNameRoot}.external_trip_id`}>
              {({ field, fieldState }) => {
                return (
                  <FormField
                    type="text"
                    label="External Trip ID"
                    error={fieldState.error?.message?.toString()}
                    inputProps={{
                      id: `${fieldNameRoot}.external_trip_id`,
                      ...field,
                      placeholder: 'Trip ID',
                    }}
                  />
                );
              }}
            </FormFieldController>
          </div>
        </>
      )}
    </>
  );
}
