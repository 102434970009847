import { useFormContext } from 'react-hook-form';

import { states } from 'src/common/data/states';
import { FormField } from 'src/common/FormField';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldContainer } from 'src/common/FormField/FormFieldContainer';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { FormFieldSet } from 'src/common/FormField/FormFieldSet';
import { Button } from 'src/common/primitives/Button';
import { Select, SelectOption } from 'src/common/primitives/Select';

import { useNonDriverMembers } from './useNonDriverMembers';

export function DriverProfileFormStep1({
  onNextClick,
  onCancel,
  children,
}: {
  onNextClick: () => void;
  onCancel: () => void;
  children: React.ReactNode;
}) {
  const { members } = useNonDriverMembers();
  const form = useFormContext();

  const onNavigateToStep2 = async () => {
    const isValid = await form.trigger();
    if (isValid) {
      onNextClick();
    }
  };
  const getSelectedOption = (val: string): SelectOption[] => {
    const member = members.find((member) => member.id === val);
    if (member) {
      return [
        {
          value: member.id,
          label: `${member.first_name} ${member.last_name}`,
        },
      ];
    }

    const { member_id, first_name, last_name } = form.getValues();
    return [
      {
        value: member_id || '',
        label: `${first_name} ${last_name}`,
      },
    ];
  };

  return (
    <div className="mt-4 flex flex-col gap-4">
      <FormFieldController
        name="member_id"
        rules={{ required: 'Driver is required' }}
      >
        {({ field, fieldState }) => {
          return (
            <FormFieldContainer colSpan={12}>
              <DefaultFormFieldLayout
                label="Select a driver"
                error={fieldState.error?.message?.toString()}
                inputProps={{ id: 'member_id' }}
              >
                <Select
                  placeholder="Select a member to add as a driver"
                  options={
                    field.value
                      ? getSelectedOption(field.value)
                      : members.map((member) => ({
                          value: member.id,
                          label: `${member.first_name} ${member.last_name}`,
                        }))
                  }
                  disabled={!!form.getValues().id}
                  value={field.value ? field.value : undefined}
                  onChange={(value?: SelectOption) => {
                    const member = members.find(
                      (member) => member.id === value?.value
                    );

                    form.setValue('first_name', member?.first_name || '');
                    form.setValue('last_name', member?.last_name || '');
                    field.onChange(value?.value);
                  }}
                  error={fieldState.error?.message?.toString()}
                />
              </DefaultFormFieldLayout>
            </FormFieldContainer>
          );
        }}
      </FormFieldController>
      <FormFieldSet
        legend="License Information"
        Fields={
          <>
            <FormFieldController
              name="license_number"
              rules={{ required: 'License Number is required' }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="License Number"
                    error={fieldState.error?.message?.toString()}
                    type="text"
                    inputProps={{
                      id: 'license-number',
                      placeholder: 'e.g. 123 455 678',
                      ...field,
                    }}
                  />
                );
              }}
            </FormFieldController>
            <FormFieldController
              name="license_dob"
              rules={{ required: 'Date of Birth is required' }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Driver Date of Birth"
                    error={fieldState.error?.message?.toString()}
                    type="date"
                    inputProps={{
                      ...field,
                      id: 'date-of-birth',
                      placeholder: 'Select a date',
                    }}
                  />
                );
              }}
            </FormFieldController>
            <FormFieldController
              name="license_state"
              rules={{ required: 'Issued State is required' }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormFieldContainer colSpan={6}>
                    <DefaultFormFieldLayout
                      label="Issued State"
                      error={fieldState.error?.message?.toString()}
                      inputProps={{ id: 'issued-state' }}
                    >
                      <Select
                        placeholder="Select State"
                        options={states}
                        {...field}
                        onChange={(value?: SelectOption) => {
                          field.onChange(value?.value);
                        }}
                        error={fieldState.error?.message?.toString()}
                      />
                    </DefaultFormFieldLayout>
                  </FormFieldContainer>
                );
              }}
            </FormFieldController>
            <FormFieldController
              name="license_expiration_date"
              rules={{ required: 'Expiration Date is required' }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Expiration Date"
                    error={fieldState.error?.message?.toString()}
                    type="date"
                    inputProps={{
                      ...field,
                      id: 'expiration-date',
                      placeholder: 'Select a date',
                    }}
                  />
                );
              }}
            </FormFieldController>
            <FormFieldController name="can_be_scheduled">
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Is this driver active?"
                    error={fieldState.error?.message?.toString()}
                    type="switch"
                    inputProps={{
                      id: 'can_be_scheduled',
                      onChange: (value) => {
                        field.onChange(value);
                      },
                      value: '',
                      checked: field.value,
                    }}
                  />
                );
              }}
            </FormFieldController>
          </>
        }
      />

      {children}
      <div className="grid grid-cols-2 gap-2">
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-full flex-grow"
          variant="primary"
          onClick={onNavigateToStep2}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
