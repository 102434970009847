import { useEffect, useState } from 'react';

import { PencilIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { Button } from 'src/common/primitives/Button';
import { Combobox } from 'src/common/primitives/ComboBox';
import { SelectOption } from 'src/common/primitives/Select';

import {
  setNewCredentialTypeName,
  setSelectedCredentialType,
  setShowCredentialModal,
  setShowCredentialTypeModal,
} from '../credential.slice';
import { CredentialCategory } from '../types';
import { useCredentialTypeOptions } from './useCredentialTypeOptions';

export default function CredentialTypeCombobox({
  category,
}: {
  category: CredentialCategory;
}) {
  const dispatch = useAppDispatch();
  const form = useFormContext();
  const [value, setValue] = useState<SelectOption | undefined>(undefined);

  const { selectedCredentialTypeOption } = useAppSelector(
    (state) => state.credential
  );
  const { options, credentialTypes, setQuery, query } =
    useCredentialTypeOptions(category);

  const onCredentialTypeChange = (selectedCredentialType: SelectOption) => {
    if (selectedCredentialType.value === 'add-credential-type') {
      dispatch(setNewCredentialTypeName(query));
      dispatch(setShowCredentialModal(false));
      dispatch(setShowCredentialTypeModal(true));
      return;
    }
    setValue(selectedCredentialType);
  };

  useEffect(() => {
    const credentialTypeId = form.getValues('credential_type_id');
    if (credentialTypeId) {
      const selectedOption = options.find(
        (option) => option.value === credentialTypeId
      );
      setValue(selectedOption);
    }

    if (selectedCredentialTypeOption) {
      setValue(selectedCredentialTypeOption);
      form.setValue('credential_type_id', selectedCredentialTypeOption.value);
    }
  }, [form, options, selectedCredentialTypeOption]);

  const onEditCredentialType = () => {
    const selectedCredentialType = credentialTypes?.results.find(
      (type) => type.id === value?.value
    );

    if (!selectedCredentialType) {
      return;
    }

    dispatch(setSelectedCredentialType(selectedCredentialType));
    dispatch(setShowCredentialModal(false));
    dispatch(setShowCredentialTypeModal(true));
  };

  return (
    <FormFieldController
      name="credential_type_id"
      rules={{ required: 'Credential type is required' }}
    >
      {({ field, fieldState }) => {
        return (
          <div className="z-20 w-full">
            <DefaultFormFieldLayout
              inputProps={{
                id: 'credential-type',
              }}
              label="Credential Type *"
              error={fieldState.error?.message?.toString()}
            >
              <div className="z-20 grid grid-cols-8 gap-x-1">
                <div className={field.value ? 'col-span-7' : 'col-span-8'}>
                  <Combobox
                    value={value}
                    placeholder="Search types or add a new one here"
                    error={fieldState.error?.message?.toString()}
                    dataTestId="credential-type-combobox"
                    options={options}
                    onChange={(option: SelectOption) => {
                      onCredentialTypeChange(option);
                      field.onChange(option.value);
                    }}
                    onQueryChange={(val) => setQuery(val)}
                  />
                </div>

                {field.value && (
                  <Button
                    dataTestId="edit-credential-type-button"
                    variant="ghost"
                    onClick={onEditCredentialType}
                  >
                    <PencilIcon className="h-5 w-5 hover:text-mint" />
                  </Button>
                )}
              </div>
            </DefaultFormFieldLayout>
          </div>
        );
      }}
    </FormFieldController>
  );
}
