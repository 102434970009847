import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import DateRangeBadge from 'src/common/date/DateRangeBadge';
import { FilterGroupRead } from 'src/common/external/bambi-api/bambiApi';
import { FilterGroup } from 'src/common/FilterGroup/FilterGroup';
import { useInitializeFilterGroup } from 'src/common/FilterGroup/useInitializeFilterGroup';
import { Button } from 'src/common/primitives/Button';
import { FilterPopoverTrigger } from 'src/common/primitives/FilterPopover/FilterPopoverTrigger';
import { RadioGroupFilterPopover } from 'src/common/primitives/FilterPopover/RadioGroupFilterPopover';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { useRestoreFiltersFromStorage } from '../finder/useRestoreFiltersFromStorage';
import {
  clearFilters,
  dateRangeStorageKey,
  driverStorageKey,
  initializeFilters,
  InvoicedTripsValue,
  payersStorageKey,
  priceStorageKey,
  setFilterInvoicedTrips,
  setFilterPrice,
  setSelectedDateRange,
  setSelectedDrivers,
  setSelectedFilterGroup,
  setSelectedPayers,
  setSelectedStatuses,
  setSelectedTags,
  statusesStorageKey,
  tagsStorageKey,
} from '../trip.slice';

export function TripManagementFilters() {
  const dispatch = useDispatch();

  // TODO break out into individual selectors, or reselect
  const {
    selectedDateRange,
    selectedPayers,
    selectedStatuses,
    selectedTags,
    selectedDrivers,
    uploadInProgress,
    filterVehicles,
    filterPrice,
  } = useAppSelector((state) => state.trip);

  const filterInvoicedTrips = useAppSelector(
    (state) => state.trip.filterInvoicedTrips
  );

  // Initialize filters from URL
  useEffect(() => {
    dispatch(initializeFilters());
  }, [dispatch]);

  const handleInitializeFilterGroup = useCallback(
    (group: FilterGroupRead) => {
      dispatch(setSelectedFilterGroup(group));
    },
    [dispatch]
  );

  // Initialize filter groups from URL
  useInitializeFilterGroup(handleInitializeFilterGroup);

  // Transfer stored filters into state
  useRestoreFiltersFromStorage([
    [payersStorageKey, setSelectedPayers],
    [statusesStorageKey, setSelectedStatuses],
    [tagsStorageKey, setSelectedTags],
    [dateRangeStorageKey, setSelectedDateRange],
    [driverStorageKey, setSelectedDrivers],
    [priceStorageKey, setFilterPrice],
  ]);

  const handleClearClick = () => {
    dispatch(clearFilters());
  };

  const isFilteringInvoicedTrips =
    filterInvoicedTrips !== InvoicedTripsValue.ALL_TRIPS;

  const showClearFiltersButton = !!(
    selectedPayers.length > 0 ||
    selectedStatuses.length > 0 ||
    selectedTags.length > 0 ||
    selectedDrivers.length > 0 ||
    selectedDateRange?.from ||
    isFilteringInvoicedTrips ||
    filterVehicles.length > 0 ||
    filterPrice?.min ||
    filterPrice?.max
  );

  return (
    <div className="flex items-center gap-2 pt-4">
      <FilterGroup
        filterset="TripFilter"
        filters={makeFilterGroupFilters({
          rangeStart: selectedDateRange?.from,
          rangeEnd: selectedDateRange?.to,
          payers: selectedPayers,
          statuses: selectedStatuses,
          tags: selectedTags,
          hasInvoice: filterInvoicedTrips,
        })}
        onSelectFilterGroup={(group) => {
          dispatch(clearFilters());
          dispatch(setSelectedFilterGroup(group));
        }}
      />
      {selectedDateRange?.from && selectedDateRange.to && (
        <div>
          <DateRangeBadge
            from={selectedDateRange.from}
            to={selectedDateRange.to}
            onClear={() => {
              dispatch(setSelectedDateRange(undefined));
            }}
          />
        </div>
      )}
      <RadioGroupFilterPopover
        triggerElement={
          <FilterPopoverTrigger
            label="Invoice"
            showCountBadge={false}
            count={isFilteringInvoicedTrips ? 1 : 0}
          />
        }
        value={filterInvoicedTrips ?? InvoicedTripsValue.ALL_TRIPS}
        onChange={(value) => {
          dispatch(setFilterInvoicedTrips(value));
        }}
        options={[
          {
            label: 'All Trips',
            value: InvoicedTripsValue.ALL_TRIPS,
          },
          {
            label: 'Trips with Invoice',
            value: InvoicedTripsValue.INVOICED_TRIPS,
          },
          {
            label: 'Trips without Invoice',
            value: InvoicedTripsValue.NOT_INVOICED_TRIPS,
          },
        ]}
      />

      {showClearFiltersButton && (
        <Button onClick={handleClearClick} variant="anchor">
          clear filters
        </Button>
      )}
      {uploadInProgress && (
        <div className="ml-12 flex items-center justify-center text-center">
          <LoadingIndicator />
          <span className="ml-4">Trip Upload in Progress..</span>
        </div>
      )}
    </div>
  );
}

interface MakeFilterGroupFiltersProps {
  rangeStart?: string;
  rangeEnd?: string;
  payers?: string[];
  statuses?: string[];
  tags?: string[];
  hasInvoice?: InvoicedTripsValue;
}

/**
 * Creates a set of filters which match the API filters
 */
function makeFilterGroupFilters({
  rangeStart,
  rangeEnd,
  payers,
  statuses,
  tags,
  hasInvoice,
}: MakeFilterGroupFiltersProps) {
  return {
    range_start: rangeStart,
    range_end: rangeEnd,
    payer__in: payers,
    status__in: statuses,
    tag__in: tags,
    has_invoice: hasInvoice,
  };
}
