import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';

export function EmailAddressField({ disabled }: { disabled?: boolean }) {
  return (
    <FormFieldController
      name="email"
      rules={{
        required: 'Email address is required',
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'Invalid email address (Ex: me@example.com)',
        },
      }}
    >
      {({ field, fieldState }) => {
        return (
          <FormField
            label="Email address *"
            error={fieldState.error?.message?.toString()}
            type="email"
            inputProps={{
              id: 'email',
              placeholder: 'Email address',
              ...field,
              disabled,
            }}
          />
        );
      }}
    </FormFieldController>
  );
}
