import { useMemo, useState } from 'react';

import {
  PaginationState,
  Updater,
  functionalUpdate,
} from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'src/app/store';
import {
  usePricingPricingSchemesListQuery,
  PricingPricingSchemesListApiArg,
} from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';

export function usePricingManagementViewModel() {
  const { selectedPayers, pricingSearchTerm } = useAppSelector(
    (state) => state.pricing
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old) => functionalUpdate(paginationUpdater, old));
    pricingSchemesRefetch();
  };

  const {
    data: pricingSchemes,
    isLoading: isPricingPricingSchemesLoading,
    refetch: pricingSchemesRefetch,
  } = usePricingPricingSchemesListQuery({
    page: pageIndex + 1,
    search: pricingSearchTerm,
    payerIn: selectedPayers,
    ordering: useSearchParams()[0].get(
      'ordering'
    ) as PricingPricingSchemesListApiArg['ordering'],
  });

  return {
    pricingSchemes,
    isPricingPricingSchemesLoading,
    pagination,
    handlePaginationChange,
  };
}
