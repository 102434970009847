import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import { useTripsBulkCancelCreateMutation } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import {
  removeSelectedTripRow,
  setShowBulkCancelTripModal,
  tripSlice,
} from 'src/features/trip/trip.slice';

import { bulkCancelTripModalColummns } from './columns';

export function BulkCancelTripModal() {
  const dispatch = useAppDispatch();
  const selectedTripReasons = useAppSelector(
    (state) => state.trip.bulkCancelModal.selectedTripReasons
  );

  const open = useAppSelector((state) => state.trip.bulkCancelModal.isOpen);
  const tripsToCancel = useAppSelector(
    (state) => state.trip.bulkCancelModal.tripsToCancel
  );

  const setModalOpen = useCallback(
    (open: boolean) => {
      dispatch(setShowBulkCancelTripModal(open));
    },
    [dispatch]
  );

  const [cancelTrips] = useTripsBulkCancelCreateMutation({});

  const handleClose = useCallback(() => {
    setModalOpen(false);
    dispatch(tripSlice.actions.onBulkCancelTripsDone());
  }, [dispatch, setModalOpen]);

  const handleCancelTrips = useCallback(async () => {
    try {
      const { trips_canceled, trips_not_canceled } = await cancelTrips({
        bulkTripCancel: {
          trip_ids: tripsToCancel.map((trip) => trip.id),
          canceled_reason: 'other',
        },
      }).unwrap();

      // Clear cancelled trips from state
      trips_canceled.forEach((id) => {
        dispatch(removeSelectedTripRow(id));
      });

      const areAllTripsCanceled =
        trips_canceled.length === tripsToCancel.length &&
        trips_not_canceled.length === 0;

      if (areAllTripsCanceled) {
        dispatch(
          show({
            type: 'success',
            title: `Cancelled (${trips_canceled.length}) trips`,
          })
        );
        handleClose();
      }
      // Some cancelled
      else if (trips_canceled.length > 0) {
        dispatch(
          show({
            type: 'warning',
            title: `Cancelled (${trips_canceled.length}) trips`,
            description: `Unable to cancel (${trips_not_canceled.length}) trips, please try again`,
          })
        );
        handleClose();
      }
      // None cancelled
      else {
        dispatch(
          show({
            type: 'error',
            title: `Unable to cancel trips`,
          })
        );
      }
    } catch (e) {
      dispatch(
        show({
          type: 'error',
          title: `Unexpected error while cancelling trips`,
          description: formatServerError(e),
        })
      );
    }
  }, [cancelTrips, tripsToCancel, dispatch, handleClose]);

  return (
    <Modal
      open={!!open}
      setOpen={setModalOpen}
      contentClassnames="max-w-6xl"
      onClose={handleClose}
    >
      <h1 className="text-center text-2xl">Confirm Trip Cancel</h1>
      <div className="flex flex-col gap-3 py-4">
        <div className="text-sm" data-testid="count-of-trips-to-cancel">
          The following <strong>({tripsToCancel.length})</strong> trips will be
          cancelled:
        </div>
        <DataGrid
          tableId="bulk-cancel"
          containerClassName="max-h-[50vh]"
          data={tripsToCancel}
          columns={bulkCancelTripModalColummns}
          includePagination={false}
          dataTestId="cancel-trips-table"
        />
      </div>
      <div className="grid grid-cols-2 gap-4 pt-6">
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={
            Object.keys(selectedTripReasons).length !== tripsToCancel.length
          }
          variant="primary"
          onClick={handleCancelTrips}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
}
