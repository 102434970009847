import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { getTripDropoffAddress } from 'src/common/external/bambi-api/wrappers/trip/getTripDropoffAddress';
import { getTripDropoffLatLng } from 'src/common/external/bambi-api/wrappers/trip/getTripDropoffLatLng';
import { getTripPickupLatLng } from 'src/common/external/bambi-api/wrappers/trip/getTripPickupLatLng';
import {
  formatHereMapsAddress,
  HereMapsAddressLink,
} from 'src/common/HereMapsAddressLink';
import { Skeleton } from 'src/common/Skeleton';

import { formatSimpleTime } from '../formatSimpleTime';
import { LocationContact } from './LocationContact';

interface TripDetailsDropoffProps {
  isTripDataLoading: boolean;
  tripData?: TripRead;
  trip:
    | DispatchTripRead
    | RunBambiRunTripRead
    | RunBambiRunReassignedTripRead
    | TripRead;
}
export function TripDetailsDropoff({
  isTripDataLoading,
  trip,
  tripData,
}: TripDetailsDropoffProps) {
  const tripPickupLatLng = getTripPickupLatLng(trip);
  const tripDropoffLatLng = getTripDropoffLatLng(trip);
  return (
    <div className="flex flex-col gap-1 py-2">
      <h4 className="text-sm font-bold">
        Dropoff
        {'scheduled_dropoff_at' in trip && (
          <> - {formatSimpleTime(trip.scheduled_dropoff_at)}</>
        )}
      </h4>
      <div>
        <HereMapsAddressLink
          className="text-sm font-medium text-gray-500 hover:underline"
          addresses={[
            {
              latitude: tripPickupLatLng.lat,
              longitude: tripPickupLatLng.long,
            },
            {
              latitude: tripDropoffLatLng.lat,
              longitude: tripDropoffLatLng.long,
            },
          ]}
        >
          {formatHereMapsAddress(getTripDropoffAddress(trip))}
        </HereMapsAddressLink>
      </div>
      {isTripDataLoading ? (
        <Skeleton />
      ) : (
        <LocationContact tripLocation={tripData?.dropoff} />
      )}
    </div>
  );
}
