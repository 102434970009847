import { useMemo } from 'react';

import { useAppSelector } from 'src/app/store';
import { DispatchRead } from 'src/common/external/bambi-api/bambiApi';

import { createClockInEventsFromAssignments } from './createClockInEventsFromAssignments';
import { createClockOutEventsFromAssignments } from './createClockOutEventsFromAssignments';
import { createEventsFromBreaks } from './createEventsFromBreaks';
import { createEventsFromTrips } from './createEventsFromTrips';
import { createEventsFromTripWaitTimes } from './createEventsFromTripWaitTimes';
import { createInvalidsFromAssignments } from './createInvalidsFromAssignments';
import { createResourcesFromAssignments } from './createResourcesFromAssignments';

export function useDispatchEventResources(dispatchResponse?: DispatchRead) {
  const selectedAssignments = useAppSelector(
    (state) => state.dispatcher.selectedAssignments
  );
  const resources = useMemo(() => {
    let assignments = dispatchResponse?.assignments
      ? createResourcesFromAssignments(dispatchResponse.assignments)
      : [];

    if (selectedAssignments.length) {
      assignments = assignments.filter((resource) =>
        selectedAssignments.includes(resource.id as string)
      );
    }

    return assignments;
  }, [dispatchResponse?.assignments, selectedAssignments]);

  const assignedTripEvents = useMemo(() => {
    return dispatchResponse?.assigned_trips
      ? createEventsFromTrips(dispatchResponse.assigned_trips)
      : [];
  }, [dispatchResponse?.assigned_trips]);

  const breakEvents = useMemo(() => {
    return dispatchResponse?.assignments
      ? createEventsFromBreaks(dispatchResponse.assignments)
      : [];
  }, [dispatchResponse?.assignments]);

  const waitTimeEvents = useMemo(() => {
    return dispatchResponse?.assigned_trips
      ? createEventsFromTripWaitTimes(dispatchResponse.assigned_trips)
      : [];
  }, [dispatchResponse?.assigned_trips]);

  const invalids = useMemo(() => {
    return dispatchResponse?.assignments
      ? createInvalidsFromAssignments(dispatchResponse.assignments)
      : [];
  }, [dispatchResponse?.assignments]);

  const clockInEvents = useMemo(() => {
    return dispatchResponse?.assignments
      ? createClockInEventsFromAssignments(dispatchResponse.assignments)
      : [];
  }, [dispatchResponse?.assignments]);

  const clockOutEvents = useMemo(() => {
    return dispatchResponse?.assignments
      ? createClockOutEventsFromAssignments(dispatchResponse.assignments)
      : [];
  }, [dispatchResponse?.assignments]);

  const events = useMemo(
    () => [
      ...assignedTripEvents,
      ...breakEvents,
      ...waitTimeEvents,
      ...clockInEvents,
      ...clockOutEvents,
    ],
    [
      assignedTripEvents,
      breakEvents,
      clockInEvents,
      clockOutEvents,
      waitTimeEvents,
    ]
  );

  return {
    resources,
    invalids,
    events,
  };
}
