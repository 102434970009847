import { useContext } from 'react';

import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline';

import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { DynamicFieldNameRootContext } from 'src/features/add-trip/TripDetails/DynamicFieldNameRootContext';

export default function TripInputPriceLockField() {
  const fieldNameRoot = useContext(DynamicFieldNameRootContext);

  return (
    <FormFieldController name={`${fieldNameRoot}.is_price_locked`}>
      {({ field }) => {
        const togglePriceLock = () => {
          field.onChange(!field.value);
        };

        return (
          <div>
            {field.value ? (
              <LockClosedIcon
                title="Price is locked. Click to unlock."
                onClick={togglePriceLock}
                className="h-5 w-5 cursor-pointer"
                data-testid="lock-closed-icon"
              />
            ) : (
              <LockOpenIcon
                title="Price is unlocked. Click to lock."
                onClick={togglePriceLock}
                className="h-5 w-5 cursor-pointer"
                data-testid="lock-open-icon"
              />
            )}
          </div>
        );
      }}
    </FormFieldController>
  );
}
