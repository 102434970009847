import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';

import { useAppDispatch } from 'src/app/store';
import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import {
  InvoiceRead,
  useBillingBillingSettingsRetrieveQuery,
  useOrganizationSettingsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { formatAddress } from 'src/common/formatAddress';
import { Button, ButtonProps } from 'src/common/primitives/Button';
import { Well } from 'src/common/primitives/Well/Well';
import { useAuth } from 'src/features/auth/useAuth';

import {
  InvoiceFormContextProvider,
  InvoiceNestedLineItemWithId,
} from '../forms/InvoiceFormContext';
import { InvoiceLineItemContainer } from '../forms/InvoiceLineItemContainer';
import { setHydratedInvoice } from '../invoice.slice';
import { PaymentsSection } from '../management/PaymentsSection';
import { useGroupLineItems } from '../useGroupLineItems';
import { HydratedInvoice, useHydratedInvoice } from '../useHydratedInvoice';
import { useInvoiceTotal } from '../useInvoiceTotal';
import {
  PrintableInvoiceContextProvider,
  usePrintableInvoiceContext,
} from './PrintableInvoiceContext';

const noop = function () {};

interface PrintableInvoiceProps {
  invoice: InvoiceRead;
}

export const PrintableInvoice = forwardRef<
  HTMLDivElement,
  PrintableInvoiceProps
>(({ invoice }, ref) => {
  const { setHasLineItems, setHasPaymentMethods, setHasSettings } =
    usePrintableInvoiceContext();

  const { currentOrganizationName } = useAuth();

  const { data: orgSettings } = useOrganizationSettingsRetrieveQuery({});
  const { data: billingSettings } = useBillingBillingSettingsRetrieveQuery({});

  const billingAddress =
    orgSettings?.billing_address || orgSettings?.headquarters_location?.address;

  const { invoiceTotal, invoiceTotalDue } = useInvoiceTotal(invoice);

  const { data: hydratedInvoice, isLoading: isLoadingHydratedInvoice } =
    useHydratedInvoice(invoice);

  // Setup form and hydrated line items
  const form = useForm<HydratedInvoice>({
    defaultValues: {
      date_issued: invoice.date_issued,
      notes: invoice.notes,
      number: invoice.number,
      line_items: hydratedInvoice?.line_items ?? [],
    },
  });

  const dispatch = useAppDispatch();

  useEffect(
    () => {
      if (!isLoadingHydratedInvoice) {
        form.setValue('line_items', hydratedInvoice?.line_items ?? []);
        dispatch(setHydratedInvoice(hydratedInvoice));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hydratedInvoice, isLoadingHydratedInvoice]
  );

  const lineItemsByTrip = useGroupLineItems(hydratedInvoice?.line_items ?? []);

  const findLineItemIndex = useCallback(
    (lineItem: InvoiceNestedLineItemWithId): number => {
      const result =
        hydratedInvoice?.line_items.findIndex(
          (item) => item.id === lineItem.id
        ) ?? -1;

      return result;
    },
    [hydratedInvoice]
  );

  useEffect(() => {
    if (!isLoadingHydratedInvoice && hydratedInvoice) {
      setHasLineItems(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingHydratedInvoice, hydratedInvoice]);

  useEffect(() => {
    if (!invoice.payments?.length) {
      setHasPaymentMethods(true);
    } else {
      setHasPaymentMethods(false);
    }
  }, [setHasPaymentMethods, invoice.payments]);

  useEffect(() => {
    if (billingSettings) {
      setHasSettings(true);
    }
  }, [billingSettings, setHasSettings]);

  return (
    <div
      className="flex flex-col gap-4 p-4 pt-8 print:p-2 screen:hidden"
      ref={ref}
    >
      <div className="mb-4 flex flex-row justify-between">
        <div>
          <h3 className="mt-2 text-[20px] font-medium leading-6 text-gray-900">
            Invoice Number: {invoice.number}
          </h3>
          {DateTime.now().toLocaleString()}
        </div>
        <div>
          {orgSettings?.logo ? (
            <img
              data-testid="organization-logo"
              src={orgSettings.logo}
              width={200}
              alt={`Logo for ${currentOrganizationName}`}
              style={{
                maxHeight: '100px',
              }}
            />
          ) : null}
          <h3 className="text-[24px] font-medium leading-6 text-gray-900">
            {currentOrganizationName}
          </h3>
          <div
            className="text-medium mt-2"
            data-testid="org-billing-contact-phone"
          >
            {billingSettings?.phone_number}
          </div>
          <div
            className="text-medium mb-2"
            data-testid="org-billing-contact-email"
          >
            {billingSettings?.email}
          </div>
          <div className="text-medium mb-4" data-testid="org-billing-address">
            {formatAddress(billingAddress ?? '', 'gap-0', false)}
          </div>
          {orgSettings?.tax_id ? (
            <div className="text-medium mt-2" data-testid="org-tax-id">
              Tax ID: {orgSettings.tax_id}
            </div>
          ) : null}
        </div>
      </div>
      <FormProvider {...form}>
        <InvoiceFormContextProvider findLineItemIndex={findLineItemIndex}>
          {Object.values(lineItemsByTrip).map((group) => {
            return (
              <InvoiceLineItemContainer
                key={group[0].trip.id}
                lineItems={group as InvoiceNestedLineItemWithId[]}
                onAddLineItem={noop}
                onDeleteLineItem={noop}
              />
            );
          })}
        </InvoiceFormContextProvider>
      </FormProvider>
      <div className="mt-4 flex flex-row items-center justify-end gap-8">
        {invoice.line_items.length ? (
          <>
            <div>Total:</div>
            <div className="text-xl">{defaultCurrencyFormat(invoiceTotal)}</div>
          </>
        ) : null}
      </div>

      <Well>
        <strong>Notes</strong>
        <p>{invoice.notes}</p>
      </Well>

      {invoice.payments?.length ? (
        <PaymentsSection
          payments={invoice.payments}
          invoiceTotalDue={invoiceTotalDue}
        />
      ) : null}
    </div>
  );
});

interface PrintableInvoiceTriggerProps extends ButtonProps {
  invoice: InvoiceRead;
}

function PrintableInvoiceTrigger(props: PrintableInvoiceTriggerProps) {
  const componentRef = useRef(null);

  const { isReadyToPrint } = usePrintableInvoiceContext();
  const [canPrint, setCanPrint] = useState(false);
  const [renderingPrintable, setRenderingPrintable] = useState(false);

  const promiseResolveRef = useRef<() => void | null>(null);

  // Setup deferred resolve to trigger once loading has completed
  useEffect(() => {
    if (isReadyToPrint() && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyToPrint, promiseResolveRef.current]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice Number ${props.invoice.number}`,
    onBeforeGetContent: () => {
      // Render printable area
      setCanPrint(true);
      // Indicate to user that rendering is occuring
      setRenderingPrintable(true);
      return new Promise((resolve) => {
        // @ts-ignore not read-only
        promiseResolveRef.current = resolve;
      }).finally(() => {
        // Remove loading indicator, print window should pop next
        setRenderingPrintable(false);
      });
    },
    // Bubble print from printing iframe to parent
    onBeforePrint: () => {
      window.onbeforeprint?.(new Event('onbeforeprint'));
    },
  });

  return (
    <>
      <div ref={componentRef}>
        {canPrint ? <PrintableInvoice invoice={props.invoice} /> : null}
      </div>
      <Button {...props} onClick={handlePrint} loading={renderingPrintable} />
    </>
  );
}

function PrintableInvoiceTriggerWithContext(
  props: PrintableInvoiceTriggerProps
) {
  return (
    <PrintableInvoiceContextProvider>
      <PrintableInvoiceTrigger {...props} />
    </PrintableInvoiceContextProvider>
  );
}

export { PrintableInvoiceTriggerWithContext as PrintableInvoiceTrigger };
