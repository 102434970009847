import {
  ContextFormFieldCheckbox,
  ContextFormFieldTime,
} from 'src/common/FormField/v2/context/components';

import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function TransportationSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Transportation"
        description="Organization defaults for transportation."
      />

      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-4 md:col-span-3">
        <div>
          <ContextFormFieldTime
            label="Transport Start Time"
            fieldPath="standard_transport_time_start"
          />
        </div>
        <div>
          <ContextFormFieldTime
            label="Transport End Time"
            fieldPath="standard_transport_time_end"
          />
        </div>
        <div className="flex items-center">
          <ContextFormFieldCheckbox
            label="Avoid Toll Roads"
            fieldPath="should_avoid_toll_roads"
          />
        </div>
        <div className="flex items-center">
          <ContextFormFieldCheckbox
            label="Avoid Ferries"
            fieldPath="should_avoid_ferries"
          />
        </div>
      </div>
    </div>
  );
}
