import { Fragment, useCallback, useEffect, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { TruckIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { DateTime } from 'luxon';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import { DispatchAssignmentRead } from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';
import { CheckBox } from 'src/common/primitives/CheckBox';
import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';
import { IndeterminateCheckbox } from 'src/common/primitives/IndeterminateCheckbox';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useClientSideSearch } from 'src/common/util/useClientSideSearch';

import { dispatcherSlice } from '../dispatcher.slice';
import { useRunBambiRun } from '../DispatchRoute/UnassignedTrips/useRunBambiRun';

const columnHelper = createColumnHelper<DispatchAssignmentRead>();
const assignmentSelectionColumns = [
  columnHelper.display({
    id: 'selector',
    header({ table }) {
      return (
        <IndeterminateCheckbox
          onChange={(val) => table.toggleAllRowsSelected()}
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
        />
      );
    },
    cell({ row }) {
      return (
        <CheckBox
          checked={row.getIsSelected()}
          onCheckedChange={() => row.toggleSelected()}
          id={`select-duplicate-control-${row.original.id}`}
        />
      );
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    id: 'driver',
    header: 'Driver',
    cell({ row }) {
      return (
        <div>
          {row.original.driver.first_name} {row.original.driver.last_name}
        </div>
      );
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    id: 'vehicle',
    header: 'Vehicle',
    cell({ row }) {
      return <div>{row.original.vehicle.nickname}</div>;
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    id: 'startTime',
    header: 'Start',
    cell({ row }) {
      return (
        <div>
          {DateTime.fromISO(row.original.start).toLocaleString(
            DateTime.DATETIME_SHORT
          )}
        </div>
      );
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
  columnHelper.display({
    id: 'endTime',
    header: 'End',
    cell({ row }) {
      return (
        <div>
          {DateTime.fromISO(row.original.end).toLocaleString(
            DateTime.DATETIME_SHORT
          )}
        </div>
      );
    },
    meta: {
      enableHeaderMenu: false,
    },
  }),
];

export function RunBambiRunAssignmentSelectionStep() {
  const runBambiRunError = useAppSelector(
    (state) => state.dispatcher.runBambiRunError
  );
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const dispatch = useAppDispatch();
  const modalToShow = useAppSelector(
    (state) => state.dispatcher.modals.modalToShow
  );
  const selectedUnassignedTripIds = useAppSelector(
    (state) => state.dispatcher.selectedUnassignedTripIds
  );
  const assignments = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.assignments
  );
  const {
    data: searchedAssignments,
    handleSearchTermChange,
    searchTerm,
  } = useClientSideSearch<DispatchAssignmentRead>(assignments || []);

  useEffect(() => {
    setRowSelection({});
  }, [modalToShow]);

  const runBambiRun = useRunBambiRun();

  const handleRunBambiRun = useCallback(async () => {
    const response = await runBambiRun({
      tripIds: selectedUnassignedTripIds,
      // Selected assignment ids
      assignments: Object.keys(rowSelection)
        .map((rowIndex) => {
          const rowId = assignments?.[parseInt(rowIndex)].id;
          return rowId || '';
        })
        .filter((assignmentId) => assignmentId),
    });
    if (!('error' in response)) {
      dispatch(dispatcherSlice.actions.dismissDispatchModal());
    }
  }, [
    runBambiRun,
    selectedUnassignedTripIds,
    rowSelection,
    assignments,
    dispatch,
  ]);
  return (
    <Transition.Root
      show={modalToShow === 'rbrAssignmentSelection'}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative"
        onClose={() => {
          dispatch(dispatcherSlice.actions.onRunBambiRunCancel());
        }}
      >
        <div className="fixed inset-0 z-20 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-700 sm:duration-900"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-700 sm:duration-900"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto relative flex h-full min-w-[30vw] flex-col gap-3 bg-white p-4 shadow-xl">
                <h2 className="text-xl font-bold">Assignment Selection</h2>
                <p className="text-sm text-gray-500">
                  Select the assignments you want Run Bambi Run to use.
                </p>
                {!!runBambiRunError && (
                  <div
                    className="w-full text-left"
                    data-testid="run-bambi-run-error"
                  >
                    <FormErrorMessage>{runBambiRunError}</FormErrorMessage>
                  </div>
                )}
                <div className="flex justify-between gap-4">
                  <SearchTypeahead
                    placeholder="Search assignments"
                    onChange={handleSearchTermChange}
                    value={searchTerm}
                  />
                </div>
                <DataGrid
                  containerClassName="flex-grow"
                  tableId="assignment-selection-table"
                  columns={assignmentSelectionColumns}
                  data={searchedAssignments}
                  totalCount={searchedAssignments.length}
                  emptyState={
                    <EmptyState
                      entityName="assignments"
                      Icon={
                        <TruckIcon className="mx-auto h-6 w-auto text-gray-700" />
                      }
                      Title="No Assignments Found"
                      description="Try a different search term or add more assignments."
                    />
                  }
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  // We don't paginate dispatch assignments, yet.
                  includePagination={false}
                  dataTestId="assignment-selection-table"
                />
                <div className="flex justify-end gap-4">
                  <Button
                    onClick={() => {
                      dispatch(dispatcherSlice.actions.onRunBambiRunCancel());
                    }}
                    variant="secondary"
                    dataTestId="run-bambi-run-cancel-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleRunBambiRun}
                    variant="primary"
                    dataTestId="run-bambi-run-start-button"
                  >
                    Run Bambi Run{' '}
                    {Object.keys(rowSelection).length > 0 &&
                    Object.keys(rowSelection).length !== assignments?.length
                      ? `with ${Object.keys(rowSelection).length} assignment(s)`
                      : 'with all assignments'}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
