import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  useTripsCorrelatedListQuery,
  useTripsRelatedListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Modal } from 'src/common/primitives/Modal';
import {
  dismissDispatchModal,
  dispatcherSlice,
  setModalSelectedTrip,
} from 'src/features/dispatch/dispatcher.slice';
import { TripListItem } from 'src/features/dispatch/trips/TripListItem';
import { tripSlice } from 'src/features/trip/trip.slice';

// So, this component is a little strange because it's not just the CancelSuggestionsModal.
// Because we have to fetch data on if there are other trips to cancel
// we show a loading state while we check if there are related or correlated trips.
// If there are no related or correlated trips, we show the normal cancel modal.
// If there are related or correlated trips, we keep showing this modal.
export default function CancelSuggestionsModal() {
  const dispatch = useAppDispatch();
  const isOpen =
    useAppSelector((state) => state.dispatcher.modals.modalToShow) ===
    'cancelSuggestions';

  const closeModal = () => {
    dispatch(setModalSelectedTrip(null));
    dispatch(dismissDispatchModal());
  };

  return (
    <Modal
      open={isOpen}
      setOpen={(val) => {
        if (!val) {
          closeModal();
        }
      }}
      contentClassnames="max-w-2xl"
    >
      <CancelSuggestionsModalContent closeModal={closeModal} />
    </Modal>
  );
}

function CancelSuggestionsModalContent({
  closeModal,
}: {
  closeModal: () => void;
}) {
  const dispatch = useAppDispatch();
  const selectedTrip = useAppSelector(
    (state) => state.dispatcher.modals.selectedTrip
  );
  const [selectedTripIds, setSelectedTripIds] = useState<string[]>([]);
  const correlatedTripsRequestState = useTripsCorrelatedListQuery(
    {
      id: selectedTrip?.id || '',
    },
    {
      skip: !selectedTrip?.id,
    }
  );
  const relatedTripsRequestState = useTripsRelatedListQuery(
    {
      id: selectedTrip?.id || '',
    },
    {
      skip: !selectedTrip?.id,
    }
  );

  const isDataLoading =
    correlatedTripsRequestState.isLoading || relatedTripsRequestState.isLoading;
  const hasSuggestions =
    !!correlatedTripsRequestState.data?.results.length ||
    !!relatedTripsRequestState.data?.results.length;
  useEffect(() => {
    if (isDataLoading) return;

    if (!hasSuggestions) {
      dispatch(dispatcherSlice.actions.showDispatchModal('cancelTrip'));
    }
  }, [isDataLoading, hasSuggestions, dispatch]);

  if (
    correlatedTripsRequestState.isLoading ||
    relatedTripsRequestState.isLoading
  ) {
    return (
      <div className="flex w-full min-w-[20vw] justify-center">
        <LoadingIndicator />
      </div>
    );
  }

  if (!selectedTrip) {
    return <div>Unable to find this trip.</div>;
  }

  return (
    <>
      {!!correlatedTripsRequestState.data?.results.length && (
        <>
          <h2 className="py-4 text-center text-2xl">
            Do you want to cancel the remaining legs of this trip?
          </h2>
          <ul className="flex flex-col gap-y-3">
            {correlatedTripsRequestState.data.results.map((trip) => (
              <li key={trip.id}>
                <TripListItem
                  trip={trip}
                  checked={selectedTripIds.includes(trip.id)}
                  onCheckedChange={(newChecked) => {
                    if (newChecked === true) {
                      setSelectedTripIds([...selectedTripIds, trip.id]);
                    } else {
                      setSelectedTripIds(
                        selectedTripIds.filter((id) => id !== trip.id)
                      );
                    }
                  }}
                />
              </li>
            ))}
          </ul>
        </>
      )}
      {!!relatedTripsRequestState.data?.results.length && (
        <>
          <h2 className="py-4 text-center text-2xl">
            This passenger also has these related trips for{' '}
            {DateTime.fromISO(selectedTrip.scheduled_pickup_at).toFormat(
              'MMMM d'
            )}
          </h2>
          <ul className="flex flex-col gap-y-3">
            {relatedTripsRequestState.data.results.map((trip) => (
              <li key={trip.id}>
                <TripListItem
                  trip={trip}
                  checked={selectedTripIds.includes(trip.id)}
                  onCheckedChange={(newChecked) => {
                    if (newChecked === true) {
                      setSelectedTripIds([...selectedTripIds, trip.id]);
                    } else {
                      setSelectedTripIds(
                        selectedTripIds.filter((id) => id !== trip.id)
                      );
                    }
                  }}
                />
              </li>
            ))}
          </ul>
        </>
      )}
      <div className="grid grid-cols-2 gap-4 pt-6">
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          variant="primary"
          onClick={() => {
            if (selectedTripIds.length < 1) {
              dispatch(dispatcherSlice.actions.showDispatchModal('cancelTrip'));
              return;
            }

            closeModal();
            const mergedTrips = [
              ...(correlatedTripsRequestState.data?.results || []),
              ...(relatedTripsRequestState.data?.results || []),
            ];
            const tripsToCancel = mergedTrips.filter((t) =>
              selectedTripIds.includes(t.id)
            );
            dispatch(
              tripSlice.actions.onBulkCancelTripsStart([
                selectedTrip,
                ...tripsToCancel,
              ])
            );
          }}
        >
          {selectedTripIds.length < 1
            ? 'Only cancel this trip'
            : 'Cancel selected trips'}
        </Button>
      </div>
    </>
  );
}
