import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch } from 'src/app/store';
import PayersFilter from 'src/common/connectedFilters/PayersFilter';

interface PayersColumnFilterProps {
  open: boolean;
  onClose: () => void;
  selected: string[] | undefined;
  setSelectedAction: ActionCreatorWithPayload<string[], string>;
}

export function PayersColumnFilter({
  open,
  onClose,
  selected,
  setSelectedAction,
}: PayersColumnFilterProps) {
  const dispatch = useAppDispatch();

  return (
    <PayersFilter
      open={open}
      selected={selected ?? []}
      triggerElement={<div />}
      onApply={(payers) => {
        dispatch(setSelectedAction(payers));
      }}
      onClose={onClose}
    />
  );
}
