import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { FormFieldSwitch } from 'src/common/FormField/FormFieldSwitch';

export function PassengerPayerLinkSwitch({ disabled }: { disabled?: boolean }) {
  return (
    <FormFieldController name="should_link_passenger_to_payer">
      {({ field }) => {
        return (
          <FormFieldSwitch
            label="Link Passenger to Payer"
            inputProps={{
              value: field.value,
              onChange: field.onChange,
              id: 'should-link-passenger-to-payer-input',
              disabled: disabled,
            }}
            helperText="If checked, the passenger will be available to facility users with access to this payer."
          />
        );
      }}
    </FormFieldController>
  );
}
