import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
  TripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { Popover } from 'src/common/primitives/Popover';

import { RunBambiRunCalendarEventType } from '../../common/RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES';
import { TripDetails } from './TripDetails';

export type TripDetailsPopoverProps = {
  trip:
    | DispatchTripRead
    | RunBambiRunTripRead
    | RunBambiRunReassignedTripRead
    | TripRead;
  type?: RunBambiRunCalendarEventType;
  children: React.ReactNode;
};

export const TripDetailsPopover = ({
  trip,
  type,
  children,
}: TripDetailsPopoverProps) => (
  <Popover
    trigger={children}
    content={<TripDetails trip={trip} type={type} />}
  />
);
