import { FormFieldControllerRulesProps } from 'src/common/FormField/FormFieldController';
import { MultiSelectContextField } from 'src/common/FormField/MultiSelectContextField';
import { observedHolidays } from 'src/common/observedHolidays';
import { SelectOption } from 'src/common/primitives/Select';

export function ObservedHolidaysField({
  fieldPath,
  rules,
  disabled,
  inline,
}: {
  fieldPath: string;
  rules?: FormFieldControllerRulesProps;
  disabled?: boolean;
  inline?: boolean;
}) {
  const observedHolidaysOptions: SelectOption[] = observedHolidays.map(
    (type) => ({
      label: type,
      value: type,
    })
  );

  return (
    <MultiSelectContextField
      options={observedHolidaysOptions}
      fieldPath={fieldPath}
      label="Observed Holidays"
      disabled={disabled}
      rules={rules}
      inline={inline}
      helperText="Select the holidays to which this pricing scheme will apply."
    />
  );
}
