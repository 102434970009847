import { MbscCalendarEvent } from '@mobiscroll/react';
import { DateTime } from 'luxon';

import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';

import { getCalendarEventStartAndEndTime } from './getCalendarEventStartAndEndTime';
import { getTripCalendarTheme } from './TripCalendarEvent/getTripCalendarTheme';

export function createEventsFromTrips(
  trips:
    | DispatchTripRead[]
    | RunBambiRunTripRead[]
    | RunBambiRunReassignedTripRead[]
): MbscCalendarEvent[] {
  return (
    trips
      .map((trip) => {
        const tripEventTheme = getTripCalendarTheme(trip);
        const resource =
          'assignment_id' in trip ? trip.assignment_id : trip.assignment.id;

        const { start, end } = getCalendarEventStartAndEndTime(trip);

        return {
          id: trip.id,
          start,
          end,
          title: trip.passenger.full_name,
          resource,
          trip,
          bufferBefore:
            'travel_time_minutes' in trip &&
            typeof trip.travel_time_minutes === 'number'
              ? trip.travel_time_minutes
              : undefined,
          // This will be used for buffer color. Because we're using renderScheduleEvent
          // it has no effect on the actual event color, which is handled by the renderScheduleEvent handler
          // Technically, we only show a buffer if the trip is an active status which is always green
          // but just in case we change that in the future, we'll use the theme here
          // If we need different colors that border, let's add a
          // bufferColor to the theme object
          // Mobiscroll sets an opacity on the buffer element, so we may need darker colors
          // The Active border color looks good though
          color: tripEventTheme.border,
        };
      })
      // We do our own presorting and then assign an order member to each event to override mobiscroll's default sorting
      // Mobiscroll also uses bufferBefore in sorting, so events with buffers that occur before another trip's start time will
      // appear first even though they have the same or later start time
      .sort((a, b) => {
        const aStartOnlyHoursMinutes = DateTime.fromISO(a.start).set({
          second: 0,
          millisecond: 0,
        });
        const bStartOnlyHoursMinutes = DateTime.fromISO(b.start).set({
          second: 0,
          millisecond: 0,
        });

        if (
          aStartOnlyHoursMinutes.toMillis() !==
          bStartOnlyHoursMinutes.toMillis()
        ) {
          return aStartOnlyHoursMinutes < bStartOnlyHoursMinutes ? -1 : 1;
        }

        // Do the same with end time
        const aEndOnlyHoursMinutes = DateTime.fromISO(a.end).set({
          second: 0,
          millisecond: 0,
        });
        const bEndOnlyHoursMinutes = DateTime.fromISO(b.end).set({
          second: 0,
          millisecond: 0,
        });
        if (
          aEndOnlyHoursMinutes.toMillis() !== bEndOnlyHoursMinutes.toMillis()
        ) {
          return aEndOnlyHoursMinutes < bEndOnlyHoursMinutes ? -1 : 1;
        }

        // If the start and end times are the same, sort by passenger name as mobiscroll does today
        return a.title.localeCompare(b.title);
      })
      .map((event, index) => ({ ...event, order: index }))
  );
}
