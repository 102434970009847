import { useEffect } from 'react';

import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { PaginatedPassengerAddressListRead } from 'src/common/external/bambi-api/bambiApi';

interface useSetPickupAddressProps {
  recentAddressResponse: PaginatedPassengerAddressListRead | undefined;
  index: number;
  setValue: UseFormSetValue<FieldValues>;
  trips: any;
}

export default function useSetPickupAddress({
  recentAddressResponse,
  index,
  setValue,
  trips,
}: useSetPickupAddressProps) {
  useEffect(() => {
    if (recentAddressResponse?.results.length) {
      const primaryAddress = recentAddressResponse.results.find(
        (result) => result.is_primary_address
      );

      const currentTrip = trips[index];
      if (
        primaryAddress &&
        // Don't override an existing pickup address
        !currentTrip.pickupLocation.address.value &&
        // Assume they don't want to go in a circle
        currentTrip.dropoffLocation.address.value !==
          primaryAddress.address.location?.place_id
      ) {
        setValue(`trips.${index}.pickupLocation.address`, {
          label: primaryAddress.address.location?.label,
          value: primaryAddress.address.location?.place_id,
        });
        setValue(
          `trips.${index}.pickupLocation.address_details`,
          primaryAddress.address_details
        );
        setValue(
          `trips.${index}.pickupLocation.driver_notes`,
          primaryAddress.address_notes
        );
      }
    }
  }, [recentAddressResponse, trips, index, setValue]);
}
