import { CheckedState } from '@radix-ui/react-checkbox';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DispatchTripRead } from 'src/common/external/bambi-api/bambiApi';

import { useDispatchRepository } from '../../common/useDispatchRepository';
import { setSelectedUnassignedTripIds } from '../../dispatcher.slice';
import { TripListItem } from '../../trips/TripListItem';

type UnassignedTripsListSectionProps = {
  searchedUnassignedTrips: DispatchTripRead[];
};

export function UnassignedTripsListSection({
  searchedUnassignedTrips,
}: UnassignedTripsListSectionProps) {
  const dispatch = useAppDispatch();
  const selectedUnassignedTripIds = useAppSelector(
    (state) => state.dispatcher.selectedUnassignedTripIds
  );
  const isMenuActionInProgress = useAppSelector(
    (state) => state.dispatcher.isMenuActionInProgress
  );
  const readState = useDispatchRepository();
  const handleCheckedChange = (trip: DispatchTripRead, val: CheckedState) => {
    if (val) {
      dispatch(
        setSelectedUnassignedTripIds([...selectedUnassignedTripIds, trip.id])
      );
    } else {
      dispatch(
        setSelectedUnassignedTripIds(
          selectedUnassignedTripIds.filter((id) => id !== trip.id)
        )
      );
    }
  };

  return (
    <div className="max-h-[75vh] flex-grow overflow-y-auto">
      {searchedUnassignedTrips.length ? (
        <ul
          className="flex flex-col gap-y-3"
          // This attempts to ensure we re-render without making any user facing changes
          // Mostly an experiment to see if this fixes the unassigned list and calendar
          // getting out of sync which no one has been able to recreate
          // A deeper refactor of how we manage this data is needed
          // It probably needs to be lifted to the store
          // See https://bambihealth.atlassian.net/browse/BAM-793 for more context
          data-action-in-progress={String(
            isMenuActionInProgress || readState.isFetching
          )}
        >
          {searchedUnassignedTrips.map((unassignedTrip) => (
            <li key={unassignedTrip.id}>
              <TripListItem
                trip={unassignedTrip}
                checked={selectedUnassignedTripIds.includes(unassignedTrip.id)}
                onCheckedChange={(newChecked) => {
                  handleCheckedChange(unassignedTrip, newChecked);
                }}
              />
            </li>
          ))}
        </ul>
      ) : (
        <div
          className="flex h-full items-center justify-center text-center text-gray-500"
          data-testid="unassigned-trips-empty-message"
        >
          There are no unassigned trips <br /> for Bambi to run :)
        </div>
      )}
    </div>
  );
}
